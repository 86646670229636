import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonService } from '../../../services/common.service';

@Component({
  selector: 'mec-social-external-link',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './social-external-link.component.html',
  styleUrls: ['./social-external-link.component.scss'],
})
export class SocialExternalLinkComponent {
  @Input() data: any;

  constructor(private _commonService: CommonService) {}

  public async ngOnInit(): Promise<void> {
    if (this.data) {
    }
  }

  public goToPage(url: string): void {
    this._commonService.navigate([url], null, true);
  }
}
