import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ModalOptions } from '../../../common/models/modal/modal-options';
import { ModalComponent } from '../../common/modal/modal.component';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProfileService } from '../../../services/profile.service';
import { ProfileEntity } from '../../../common/models/profile/profile-entity';
import { ApiProfileV3Service } from '../../../services/api/api-profile-v3.service';
import { SocialNetWorkService } from '../../../services/social-network.service';
import { SocialNetwork } from '../../../common/models/social-network/social-network';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { MatMenuModule } from '@angular/material/menu';
import { SocialNetWorkThread } from '../../../common/models/social-network/social-network-thread';
import { FormsModule } from '@angular/forms';
import { ToastService } from '../../../services/toast.service';
import { SocialPublicationCreateBodyComponent } from '../social-publication-create-body/social-publication-create-body.component';
import { ApiSocialNetworksV1Service } from '../../../services/api/api-social-networks-V1.service';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'mec-social-publication-create-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    FontAwesomeModule,
    TranslateModule,
    SkeletonDirective,
    MatMenuModule,
    FormsModule,
    SocialPublicationCreateBodyComponent,
  ],
  providers: [ProfileService, ApiProfileV3Service, ApiSocialNetworksV1Service],
  templateUrl: './social-publication-create-modal.component.html',
  styleUrls: ['./social-publication-create-modal.component.scss'],
})
export class SocialPublicationCreateModalComponent implements OnInit {
  public initalInputType: 'link' | 'image-video' | 'file';
  public modalOptions: ModalOptions;
  public profile: ProfileEntity;
  public avatarUrl: string;

  public arrowIcon = faCaretDown;

  public socialNetWorks: SocialNetwork[];
  public socialNetWorkSelected: SocialNetwork;
  public isLoading: boolean;
  public isSaving: boolean;

  public socialThread: SocialNetWorkThread = new SocialNetWorkThread();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      profileId: string;
      socialNetWorkInstaceId?: string;
      initalInputType?: 'link' | 'image-video' | 'file';
      socialThreadToRepost?: SocialNetWorkThread;
    },
    public matDialogRef: MatDialogRef<SocialPublicationCreateModalComponent>,
    private _translateService: TranslateService,
    private _profileService: ProfileService,
    private _toastService: ToastService,
    private _socialNetWorkService: SocialNetWorkService,
    private _apiSocialNetworkV1Service: ApiSocialNetworksV1Service
  ) {
    this.modalOptions = {
      closeable: true,
      hideHeader: true,
      showSecondaryNegativeButton: false,
      showSecondaryPositiveButton: false,
      showNegativeButton: false,
      showPositiveButton: true,
      positiveButtonLabel: this._translateService.instant('NEW_POST'),
    };
  }

  public closeModal(reloadPublications: boolean): void {
    this.matDialogRef.close(reloadPublications);
  }

  public async ngOnInit(): Promise<void> {
    this.avatarUrl = CommonHelper.getAvatarImage(this.data.profileId);

    this.isLoading = true;
    this.profile = await this._profileService.getProfile();
    await this.getSocialNetWorks();
    this.isLoading = false;
  }

  public async getSocialNetWorks(): Promise<void> {
    this.socialNetWorks = await this._socialNetWorkService.getSocialNetworks();

    if (this.data.socialNetWorkInstaceId) {
      const socialNetWorkResult =
        await this._socialNetWorkService.getSocialNetworkByInstanceId(
          this.data.socialNetWorkInstaceId
        );

      this.setSocialNetwork(socialNetWorkResult);
    } else {
      this.setSocialNetwork(this.socialNetWorks[0]);
    }
  }

  public setSocialNetwork(socialNetWork: SocialNetwork): void {
    this.socialNetWorkSelected = socialNetWork;
  }

  public async savePublication(): Promise<void> {
    this.isSaving = true;

    try {
      if (this.data.socialThreadToRepost) {
        await this._apiSocialNetworkV1Service.savePublicationRepost(
          this.socialThread,
          this.data.socialThreadToRepost.token,
          this.socialNetWorkSelected?.instanceId
        );
      } else {
        await this._apiSocialNetworkV1Service.savePublication(
          this.socialThread,
          this.socialNetWorkSelected?.instanceId
        );
      }

      this._toastService.showSuccess();
      this.matDialogRef.close(true);

      this.closeModal(true);
    } catch (error) {
      this._toastService.showError(error);
    }

    this.isSaving = true;
  }
}
