import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileService } from '../../../services/profile.service';
import { ProfileEntity } from '../../../common/models/profile/profile-entity';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { CommonService } from '../../../services/common.service';
import { environment } from '../../../environments/environment';
import { i18nService } from '../../../services/i18n.service';
import { Routes } from '../../../common/constants/routes.constants';
import { ToastService } from '../../../services/toast.service';
import {
  faArrowRightFromBracket,
  faChevronRight,
  faUser,
  faCircleUser,
} from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mec-profile-widget',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
  templateUrl: './profile-widget.component.html',
  styleUrls: ['./profile-widget.component.scss'],
})
export class ProfileWidgetComponent implements OnInit, OnDestroy {
  public accountUrl: string;
  public imageAvatarUrl: string;
  public profile: ProfileEntity;

  public accountIcon = faUser;
  public arrowIcon = faChevronRight;
  public logoutIcon = faArrowRightFromBracket;
  public userIcon = faCircleUser;

  public loginSubscription: Subscription;

  constructor(
    public _commonService: CommonService,
    private _authService: AuthenticationService,
    private _i18nService: i18nService,
    private _profileService: ProfileService,
    private _toastService: ToastService
  ) {
    this.loginSubscription = this._authService.onLoggedInOut.subscribe(() => {
      this._handleInitialization();
    });
  }

  public ngOnDestroy(): void {
    this.loginSubscription?.unsubscribe();
  }

  public async ngOnInit(): Promise<void> {
    this._handleInitialization();
  }

  public async _handleInitialization(): Promise<void> {
    if (!this._authService.isLogged) {
      return;
    }

    this.profile = await this._profileService.getProfile();
    this.imageAvatarUrl = CommonHelper.getAvatarImage(this.profile.id);

    const socialUrl = environment.social;
    const language = this._i18nService.language;
    const profileId = this.profile.id;

    this.accountUrl = `${socialUrl}/${language}/profiles/${profileId}`;
  }

  public openAccountUrl(): void {
    // window.open(this.accountUrl, '_blank');
    this._commonService.windowOpen(this.accountUrl, '_blank');
  }

  public goToPage(path: string): void {
    this._commonService.navigate([path]);
  }

  public logout(): void {
    const logoutResult = this._authService.logOut(Routes.home.fullPath);
    if (logoutResult) {
      this._toastService.showSuccess('AUT.FEEDBACK.OPEN.LOGOUT.200.SUCCESS');
    }
  }
}
