<h2 (click)="goToPage()" class="course-card-list__title">
  {{ 'COURSES' | translate }}
</h2>
<ng-container *ngIf="!isLoadingCourses || coursesList.courses.length">
  <div
    class="course-card-list__list-items"
    [loadMore]="coursesList.pagination.loadMore"
    [isLoadingMore]="coursesList.pagination.isLoadingMore"
    (medInfiniteScroll)="getCourses()"
  >
    <mec-course-card
      [course]="course"
      (refreshCoursesList)="getCourses(true)"
      *ngFor="let course of coursesList.courses"
    ></mec-course-card>
  </div>
</ng-container>

<!-- Loader Skeleton start -->

<ng-container *ngIf="isLoadingCourses">
  <div class="course-card-list__list-items">
    <div
      *ngFor="let item of [0, 1, 2]"
      [medSkeletonLoading]="true"
      style="height: 300px; width: 100%"
    ></div>
  </div>
</ng-container>
<!-- Loader Skeleton end -->
