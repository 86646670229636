import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AudioItem } from '../common/models/event/audio-item';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  public playerUpdateSubject = new Subject<AudioItem>();
}
