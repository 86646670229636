import { Permission } from '../permission/permission';
import { UserPresetEntity } from './user-preset-entity';

export declare class RoleEntity {
  public administrator: boolean;
  public createdAt: Date;
  public evaluator: boolean;
  public grade: number;
  public id: number;
  public name: string;
  public permissions: Permission[];
  public scholar: boolean;
  public updatedAt: Date;
  public userPresets: UserPresetEntity[];
}
