import {
  DOCUMENT,
  Location,
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { i18nService } from './i18n.service';
import { SettingsService } from './settings.service';
import { Hub } from './abstract/hub.abstract';
import { Subject } from 'rxjs';
import {
  IconName,
  IconPrefix,
  IconProp,
} from '@fortawesome/fontawesome-svg-core';
import { PlatformService } from './platform.service';
import { InstanceService } from './instance.service';
import { CommonHelper } from '../common/helpers/common.helper';
import { SEOService } from './seo.service';
import { HubInstance } from '../common/models/hub/hub-instance';
import { GTMService } from './google-tag-manager.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public onIsMobileChange = new Subject<void>();
  private _init: Promise<void>;
  public isMobile = true;
  public nextRoute: string;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private _platformId: object,
    private _activatedRoute: ActivatedRoute,
    private _gtmService: GTMService,
    private _hubService: Hub,
    private _i18nService: i18nService,
    private _instanceService: InstanceService,
    private _location: Location,
    private _platformService: PlatformService,
    private _router: Router,
    private _seoService: SEOService,
    private _settingsService: SettingsService
  ) {
    this.verifyIfIsMobile();
  }

  public async downloadFileFromUrl(
    url: string,
    fileName: string
  ): Promise<void> {
    const data = await fetch(url);
    const blob = await data.blob();
    const objectUrl = URL.createObjectURL(blob);

    const link = this._document.createElement('a');

    link.setAttribute('href', objectUrl);
    link.setAttribute('download', fileName);
    link.style.display = 'none';

    this._document.body.appendChild(link);

    link.click();

    this._document.body.removeChild(link);
  }

  public getIcon(value: string, iconPrefixValue: IconPrefix = 'fas'): IconProp {
    const valueArray = value.split('-');

    let iconPrefix = valueArray[0] as IconPrefix;
    valueArray.shift();
    const iconName = valueArray.join('-') as IconName;

    if (iconPrefixValue) {
      iconPrefix = iconPrefixValue;
    }

    return [iconPrefix, iconName] as unknown as [IconPrefix, IconName];
  }

  public init(): Promise<void> {
    if (this._init) {
      return this._init;
    }
    return this._init;
  }

  public get isPlatformBrowser() {
    return isPlatformBrowser(this._platformId);
  }

  public get isPlatformServer() {
    return isPlatformServer(this._platformId);
  }

  public windowOpen(
    url?: string | URL,
    target?: string,
    features?: string
  ): void {
    if (!this.isPlatformBrowser) return;

    window.open(url, target, features);
  }

  public navigate(
    pathArray: string[],
    extras?: NavigationExtras,
    openNewTab?: boolean
  ): void {
    if (this._platformService.platform === 'mobile') {
      this._router.navigate(pathArray, extras);
      return;
    }

    // mleon(): todo normalize with GOOGLE_ANALYTICS constants
    this._gtmService.tagEvent({
      event_action: 'click',
      event_category: 'navigation',
      event_label: 'navigate',
      value: pathArray.join('/'),
      navigationExtras: JSON.stringify(extras),
      openNewTab: openNewTab ? openNewTab.toString() : 'false',
    });

    if (!pathArray?.length && pathArray[0] === undefined) {
      return;
    }

    const basePath = this.getBasePath();

    if (!openNewTab) {
      this._router.navigate([basePath, ...pathArray], extras);
      return;
    }

    let link = `${pathArray}`;

    const isExternalUrl = pathArray[0].indexOf('http') > -1;
    if (!isExternalUrl) {
      link = `${basePath}/${pathArray[0]}`;
    }

    this._router.navigate([]).then(() => {
      this.windowOpen(link, '_blank');
    });
  }

  public navigateByUrl(
    path: string,
    extras?: NavigationExtras,
    openNewTab?: boolean
  ): void {
    // mleon(): todo normalize with GOOGLE_ANALYTICS constants
    this._gtmService.tagEvent({
      event_action: 'click',
      event_category: 'navigation',
      event_label: 'navigate by url',
      value: path,
      navigationExtras: JSON.stringify(extras),
      openNewTab: openNewTab ? openNewTab.toString() : 'false',
    });

    if (!openNewTab) {
      this._router.navigateByUrl(path, extras);
      return;
    }

    this._router.navigate([]).then(() => {
      this.windowOpen(path, '_blank');
    });
  }

  public getBasePath(region?: string, language?: string): string {
    const basePath = this._hubService.basePath();
    const basePathParts = basePath ? basePath.split('/') : [];

    const regionPath = region || this._i18nService.region;
    const languagePath = language || this._i18nService.language;

    let output = `${regionPath}/${languagePath}`;

    if (basePathParts.length === 3) {
      output = `${this._hubService.hub()}/${regionPath}/${languagePath}`;
    }

    return output;
  }

  public setNextRoute(nextRoute: string): void {
    this.nextRoute = nextRoute;
  }

  public async goToNextRoute(): Promise<void> {
    if (!this.nextRoute) {
      await this.redirectDefaultRoute();
      return;
    }

    this.navigate([this.nextRoute]);
  }

  public async redirectDefaultRoute(): Promise<void> {
    const queryParams = this._activatedRoute.snapshot.queryParams;
    const routeUrlToRedirect = queryParams['routeUrlToRedirect']?.split('/');
    const routeUrlToRedirectString = queryParams['routeUrlToRedirect'];

    const navigateByUrl =
      routeUrlToRedirectString?.indexOf(this.getBasePath()) > -1;

    const extrasQueryParams = Object.keys(queryParams)
      .filter((key) => key !== 'routeUrlToRedirect')
      .reduce((obj: any, key: string) => {
        obj[key] = queryParams[key];
        return obj;
      }, {});

    const extras: NavigationExtras = {
      queryParams: {
        ...extrasQueryParams,
      },
    };

    if (routeUrlToRedirectString && navigateByUrl) {
      this.navigateByUrl(routeUrlToRedirectString);
      return;
    }

    if (routeUrlToRedirect) {
      this.navigate(routeUrlToRedirect, extras);
      return;
    }

    const defaultLoggedState = await this._settingsService.getPermissionByName(
      'DEFAULT_LOGGED_STATE'
    );
    this.navigate([defaultLoggedState.value.toLowerCase()]);
  }

  public async handleBaseUrl(
    hub: string,
    region?: string,
    language?: string
  ): Promise<void> {
    if (region && language) {
      this._i18nService.setLanguageAndRegion(language, region, hub);
      return;
    }

    const basePathParts = this._hubService.basePath().split('/');
    let pathParts = this._location.path().split('/');

    pathParts = pathParts.filter((path) => path && path !== '');

    let languagePosition = 1;

    if (this.isPlatformBrowser) {
      if (basePathParts.length === 3) {
        languagePosition = 2;
      }
    } else {
      languagePosition = 1;
    }

    language = pathParts[languagePosition];
    region = pathParts[languagePosition - 1];
    this._i18nService.setLanguageAndRegion(language, region, hub);
  }

  public verifyIfIsMobile(): void {
    let width;

    const previousMobileSize = this.isMobile;

    if (this.isPlatformBrowser) {
      width = window.innerWidth;
    } else {
      // mleon(): assumes mobile first for Server Side Rendering
      width = 480;
    }

    this.isMobile = width < 1024;

    if (previousMobileSize !== this.isMobile) {
      this.onIsMobileChange.next();
    }
  }

  public setSEOConfiguration(hubInstance: HubInstance): void {
    const faviconProp = this._hubService.getHubConfigurationByProp('favicon');
    const imageProp = this._hubService.getHubConfigurationByProp('coverImage');

    const favicon = faviconProp
      ? `app/hubs/hub-${this._hubService.hub()}/assets/${faviconProp}`
      : CommonHelper.getFileUrl(hubInstance.faviconImageHash);
    const image = imageProp
      ? `app/hubs/hub-${this._hubService.hub()}/assets/${imageProp}`
      : CommonHelper.getFileUrl(hubInstance.defaultImageHash);

    this._seoService.setSiteName(hubInstance?.content?.title);
    this._seoService.setFavicon(favicon);
    this._seoService.updateSEO(
      hubInstance?.content?.title,
      false,
      hubInstance?.content?.description,
      image
    );
  }

  public async setTheme(): Promise<void> {
    const themeResult = await this._instanceService.getTheme();

    const elementStyle = this._document.documentElement;

    elementStyle.style.setProperty(
      '--ion-color-primary',
      themeResult.styleSettings['COLOR_PRIMARY']
    );
    elementStyle.style.setProperty(
      '--ion-color-secondary',
      themeResult.styleSettings['COLOR_SECONDARY']
    );

    elementStyle.style.setProperty(
      '--mep-color-primary',
      themeResult.styleSettings['COLOR_PRIMARY']
    );

    elementStyle.style.setProperty(
      '--mep-color-secondary',
      themeResult.styleSettings['COLOR_SECONDARY']
    );
    //set theme color to SurveyJS
    elementStyle.style.setProperty(
      '--sjs-primary-backcolor',
      themeResult.styleSettings['COLOR_PRIMARY']
    );

    if (this.isPlatformBrowser) {
      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        elementStyle.style.setProperty(
          '--mep-color-primary',
          themeResult.styleSettings['COLOR_SECONDARY']
        );

        elementStyle.style.setProperty(
          '--ion-color-primary',
          themeResult.styleSettings['COLOR_SECONDARY']
        );
      }
    }
  }
}
