<div
  (click)="goToProfile(); $event.stopPropagation()"
  class="participants-item card"
>
  <img src="{{ avatarUrl }}" class="avatar" />
  <div class="participants-item__container-info">
    <h2 class="participants-item__name">{{ participant?.fullName }}</h2>
    <p class="participants-item__bio line-clamp-2">
      {{ participant?.biography }}
    </p>
  </div>
  <div class="participants-item__follow-button">
    <button
      (click)="followParticipant(); $event.stopPropagation()"
      *ngIf="!participant?.following"
      class="btn btn--sm btn--outline"
    >
      <span>
        {{ 'SOCIALNETWORK_PARTICIPANT_FOLLOW' | translate }}
      </span>
    </button>
    <button
      (click)="unfollowParticipant(); $event.stopPropagation()"
      *ngIf="participant?.following"
      class="btn btn--sm"
    >
      <span>
        {{ 'SOCIALNETWORK_PARTICIPANT_FOLLOWING' | translate }}
      </span>
    </button>
  </div>
</div>
