import { DirectionOptions } from '../../enums/common/direction-options';

export class PaginationOptions {
  public areTagsExclusive?: boolean;
  public direction?: DirectionOptions;
  public fields?: Array<string>;
  public isAfterFirstLoad?: boolean;
  public isLoadingMore?: boolean;
  public limit: number;
  public loadMore?: boolean;
  public maxPage?: number;
  public orderBy?: string;
  public orderField?: string;
  public page?: number;
  public path?: string;
  public types?: string;
}
