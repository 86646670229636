<div [ngClass]="{ 'hide-element': hideHeaderAndFooter }">
  <ng-template #headerTemplate></ng-template>
</div>

<div class="app" [ngClass]="{ 'page-content': !hideSideBar }">
  <div class="app__sidebar" *ngIf="!_commonService.isMobile && !hideSideBar">
    <ng-template #sideBarTemplate></ng-template>
  </div>
  <div class="app__content">
    <router-outlet class="app__router-outlet"></router-outlet>
  </div>
</div>

<ng-template #cookieBarTemplate></ng-template>

<div [ngClass]="{ 'hide-element': hideHeaderAndFooter }">
  <ng-template #footerTemplate></ng-template>
</div>
