export declare class UserPresetEntity {
  public id: number;

  public administrator: boolean;
  public customNetworks: boolean;
  public evaluator: boolean;
  public inviteThisPresetOnly: boolean;
  public name: string;
  public scholar: boolean;
}
