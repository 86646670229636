import { SocialNetworkPrivacy } from '../../enums/social-network/social-network-privacy';
import { SocialNetworkStatus } from '../../enums/social-network/social-network-status';
import { ContentEntity } from '../common/content-entity';
import { Course } from '../course/courses';
import { HubEntity } from '../hub/hub-entity';
import { Tag } from '../tags/tag';
import { Role } from '../user/role';

export class SocialNetwork {
  public id: number;
  public content: ContentEntity;
  public country: string;
  public courses: Course[];
  public hub: HubEntity;
  public instanceId: string;
  public privacy: SocialNetworkPrivacy;
  public savedItem: boolean;
  public status: SocialNetworkStatus;
  public tags: Tag[];
  public totalOfInvitedParticipants: number;
  public totalOfParticipants: number;
  public roles: Role[];
}
