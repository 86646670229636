import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EvaluationService {
  public evaluationSubject = new Subject();

  public updateEvaluation(): void {
    this.evaluationSubject.next(true);
  }
}
