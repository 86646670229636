<div
  *ngIf="!loadingSteps"
  [ngClass]="{ 'steps-course--is-side-bar': isSideBar }"
  class="steps-course"
>
  <ng-content></ng-content>

  <ul class="steps-course__first-step">
    <ng-container
      *ngTemplateOutlet="recursiveStepsList; context: { list: stepsCourse }"
    ></ng-container>
  </ul>

  <ng-template #recursiveStepsList let-list="list">
    <li *ngFor="let item of list">
      <div
        [ngClass]="{ 
        'steps-course__item-step--selected': course?.currentStep?.id === item.id,
        'steps-course__item-step--disabled': !item.unlocked,
      }"
        (click)="goToStep(item)"
        class="steps-course__item-step"
        lines="none"
        detail="true"
      >
        <div class="steps-course__left-side">
          <fa-icon
            [ngClass]="{ 'steps-course__left-icon--readed': item.hasRead }"
            *ngIf="
              item.type === submissionTypes.EXAM ||
              item.type === submissionTypes.MULTIPLE_CHOICE ||
              item.type === submissionTypes.SURVEY
            "
            class="steps-course__check-icon"
            [icon]="examIcon"
          ></fa-icon>
          <fa-icon
            [ngClass]="{ 'steps-course__left-icon--readed': item.hasRead }"
            *ngIf="item.type === submissionTypes.CONTENT"
            class="steps-course__check-icon"
            [icon]="contentIcon"
          ></fa-icon>
          <fa-icon
            [ngClass]="{ 'steps-course__left-icon--readed': item.hasRead }"
            *ngIf="item.type === submissionTypes.CERTIFICATE"
            class="steps-course__check-icon"
            [icon]="certificateIcon"
          ></fa-icon>
          <h3 class="steps-course__text">{{ item?.content?.title }}</h3>
        </div>
        <fa-icon class="steps-course__arrow-icon" [icon]="arrowIcon"></fa-icon>
      </div>
      <ul *ngIf="item.children?.length" class="steps-course--sub-list">
        <ng-container
          *ngTemplateOutlet="
            recursiveStepsList;
            context: { list: item.children }
          "
        ></ng-container>
      </ul>
    </li>
  </ng-template>
</div>

<!-- Loader Skeleton start -->
<div *ngIf="loadingSteps">
  <div>
    <div [medSkeletonLoading]="true" style="height: 48px"></div>
    <div
      [medSkeletonLoading]="true"
      *ngFor="let item of [0, 1, 2, 3]"
      style="height: 48px; margin-top: 12px; margin-left: 60px"
    ></div>
    <div
      [medSkeletonLoading]="true"
      style="height: 48px; margin-top: 12px"
    ></div>
  </div>
</div>
<!-- Loader Skeleton end -->
