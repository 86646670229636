export class PaginationHelper {
  public static handlePagination<T>(
    currentItems: T[],
    itemsToAdd: T[],
    reset?: boolean
  ): T[] {
    if (reset) {
      currentItems = itemsToAdd;
      return currentItems;
    }

    currentItems = [...currentItems, ...itemsToAdd];
    return currentItems;
  }

  public static newHandlePagination<T>(
    entityList: any,
    listName: string,
    itemsToAdd: T[]
  ): any {
    const currentItems = entityList[listName] || [];

    entityList[listName] = [...currentItems, ...itemsToAdd];

    if (!currentItems.length) {
      entityList.pagination.isLoading = true;
    } else {
      entityList.pagination.isLoadingMore = true;
    }

    entityList.pagination.page++;
    entityList.pagination.isAfterFirstLoad = true;
    entityList.pagination.loadMore =
      itemsToAdd.length >= entityList.pagination.limit;

    if (
      entityList.pagination.maxPage != null &&
      entityList.pagination.page > entityList.pagination.maxPage
    ) {
      entityList.pagination.loadMore = false;
    }

    return entityList;
  }
}
