import { WebinarTemplateType } from '../../enums/event/webinar-template-type';
import { TemplateWebinarObject } from '../event/template-webinar-object';
import { FileEntity } from '../files/file-entity';

export declare class ContentEntity {
  public additionalInformation?: string;
  public answer?: string;
  public content?: string; // HTML
  public description?: string;
  public endsAt?: Date;
  public excerpt?: string;
  public fileHash?: string;
  public fileId?: number;
  public footerContent?: string;
  public image?: number;
  public imageHash?: string;
  public images?: FileEntity[];
  public label?: string;
  public name?: string;
  public notes?: string;
  public program?: string;
  public publishedAt?: Date;
  public question?: string;
  public signature?: number;
  public signer?: string;
  public signerCompany?: string;
  public signerPosition?: string;
  public startsAt?: Date;
  public subtitle?: string;
  public support?: string;
  public template?: string; // HTML
  public templateObject?: TemplateWebinarObject;
  public templateType?: WebinarTemplateType;
  public title?: string;
}
