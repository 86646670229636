import { Injectable } from "@angular/core";
import { SettingsEntity } from "../common";
import { ApiCourseSettingsService } from "./api/api-course-settings.service";

@Injectable({
  providedIn: 'root'
})
export class CourseSettingsService {

  constructor(
    private _apiCoursesSettingsService: ApiCourseSettingsService,
  ) {

  }

  public getSettings(courseSlug: string): Promise<SettingsEntity[]> {
    return new Promise<SettingsEntity[]>(async (resolve, reject) => {
      const settings = await this._apiCoursesSettingsService.getCourseSettings(courseSlug);
      resolve(settings);
    })
  }

  public async getPermissionByName(settingType: string, courseSlug: string): Promise<SettingsEntity> {
    const settings = await this.getSettings(courseSlug);
    const settingByName = settings.find(setting => setting.type === settingType);
    return settingByName;
  }
}