import { EventStatus } from '../../enums/event/event-status';
import { VideoSrc } from '../common/video/video-src';
import { AudioSession } from './audio-session';

export class SessionVideoOptions {
  public title: string;
  public audios?: AudioSession;
  public isDisabled?: boolean;
  public eventStatus?: EventStatus;
  public eventLiveUrl?: VideoSrc;
  public eventLiveIsMultilanguage?: boolean;
}
