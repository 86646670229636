<div class="profile-widget">
  <div class="profile-widget__avatar-container">
    <img class="profile-widget__avatar-image" [src]="imageAvatarUrl" />
    <h2>{{ profile?.fullName }}</h2>
  </div>
  <div class="profile-widget__list">
    <!-- <div (click)="openAccountUrl()" class="profile-widget__item">
      <div class="profile-widget__item-label-container">
        <fa-icon
          class="profile-widget__item-icon"
          [icon]="accountIcon"
        ></fa-icon>
        <span class="profile-widget__item-label">
          {{ 'AUT.ACCOUNT.NAME' | translate }}
        </span>
      </div>
      <fa-icon class="profile-widget__arrow-icon" [icon]="arrowIcon"></fa-icon>
    </div> -->
    <div (click)="goToPage('profile')" class="profile-widget__item">
      <div class="profile-widget__item-label-container">
        <fa-icon class="profile-widget__item-icon" [icon]="userIcon"></fa-icon>
        <span class="profile-widget__item-label">
          {{ 'PROFILE' | translate }}
        </span>
      </div>

      <fa-icon class="profile-widget__arrow-icon" [icon]="arrowIcon"></fa-icon>
    </div>
    <div (click)="logout()" class="profile-widget__item">
      <div class="profile-widget__item-label-container">
        <fa-icon
          class="profile-widget__item-icon"
          [icon]="logoutIcon"
        ></fa-icon>
        <span class="profile-widget__item-label">
          {{ 'LOG_OUT' | translate }}
        </span>
      </div>

      <fa-icon class="profile-widget__arrow-icon" [icon]="arrowIcon"></fa-icon>
    </div>
  </div>
</div>
