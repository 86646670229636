import { ProfileEntity } from '../profile/profile-entity';
import { ContentEntity } from '../common/content-entity';
import { CourseEntity } from '../course/course-entity';
import { CpdConfigs } from '../cpd/cpd-configs';
import { HubEntity } from '../hub/hub-entity';
import { Language } from '../common/language/language';
import { ArticleStatus } from '../../enums/article/article-status';
import { ArticleType } from '../../enums/article/article-type';
import { ArticleVisibility } from '../../enums/article/article-visibility';
import { Tag } from '../tags/tag';

export declare class Article {
  public id: number;
  public slug: string;

  //  specific
  public author: ProfileEntity;
  public course?: CourseEntity;
  public traincloud?: HubEntity;

  // Media
  public defaultImage?: number;
  public defaultImageHash?: string;

  // Content
  public content: ContentEntity;
  public cpdConfigs: CpdConfigs[];
  public tags: Tag[];

  // Regionalization
  public languages?: Language[];
  public originLanguage?: Language;

  // Metadata
  public savedItem?: boolean;
  public status: ArticleStatus;
  public type: ArticleType;
  public visibility?: ArticleVisibility;

  // Dates
  public createdAt: Date;
  public publishedAt: Date;
  public updatedAt: Date;
}
