<mec-modal
  [options]="modalOptions"
  [disableActions]="isLoading || isSaving"
  (positiveClick)="savePublication()"
  (closeClick)="matDialogRef.close(false)"
>
  <div class="publication-create-modal">
    <button [matMenuTriggerFor]="menu" class="social-select btn btn--no-bg">
      <img [src]="avatarUrl" class="avatar social-select__avatar" />
      <div class="social-select__container-select">
        <div *ngIf="!isLoading" class="social-select__container-name">
          <h5 class="social-select__name">{{ this.profile?.fullName }}</h5>
          <div class="social-select__select-social">
            <small> {{ 'SOCIALNETWORK_PUBLISH_IN' | translate }} </small>
            <small class="social-select__social-name">
              {{ socialNetWorkSelected?.content?.name }}
            </small>
          </div>
        </div>
        <!-- Loader Skeleton start -->
        <div *ngIf="isLoading">
          <h4
            style="width: 150px; height: 20px"
            [medSkeletonLoading]="true"
          ></h4>
          <p
            style="margin-top: 4px; width: 190px; height: 20px"
            [medSkeletonLoading]="true"
          ></p>
        </div>
        <!-- Loader Skeleton end -->
        <fa-icon lass="social-select__icon-select" [icon]="arrowIcon"></fa-icon>
      </div>
    </button>
    <mat-menu #menu="matMenu">
      <button
        (click)="setSocialNetwork(socialNetwork)"
        *ngFor="let socialNetwork of socialNetWorks"
        mat-menu-item
      >
        {{ socialNetwork.content.name }}
      </button>
    </mat-menu>

    <mec-social-publication-create-body
      [socialThread]="socialThread"
      [socialNetWorkId]="socialNetWorkSelected?.instanceId"
      [socialThreadToRepost]="data?.socialThreadToRepost"
    ></mec-social-publication-create-body>
  </div>
</mec-modal>
