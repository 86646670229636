import { ContentEntity } from '../common/content-entity';
import { ScoreSystemType } from '../course/score-system-type';
import { SettingsEntity } from '../settings/settings-entity';
import { MenuData } from '../side-bar/menu-data';
import { MenuItem } from '../side-bar/menu-item';
import { Tag } from '../tags/tag';
import { UserRole } from '../user/user-role';

export class HubInstance {
  public id: number;
  public slug: string;
  public administrator: boolean;
  public defaultImageInverted: number;
  public defaultImageInvertedHash: string;
  public defaultImageHash: string;
  public webBigSizeImageHash: string;
  public mobileBigSizeImageHash: string;
  public faviconImageHash: string;
  public online: boolean;
  public legalName: string;
  public skipDashboard: boolean;
  public trackNavigation: boolean;
  public hasContents: string[];
  public showFeaturesOnDashboard: boolean;
  public disableLibrary: boolean;
  public disableNews: boolean;
  public disableEvents: boolean;
  public savedItem: boolean;
  public role: UserRole;
  public content: ContentEntity;

  public subscriptionId: number;
  public name: string;
  public tags: Tag[];

  public activated?: boolean;
  public scoreSystemType?: ScoreSystemType;
  public settings?: SettingsEntity[];

  public menus?: MenuItem[];
}
