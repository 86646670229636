import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiChainService } from '../../../services/api/api-chain.service';
import { ProgramType } from '../../../common/enums/care-monitor/program-type';
import {
  Program,
  ProgramList,
} from '../../../common/models/care-monitor/program';
import { ToastService } from '../../../services/toast.service';
import { ProgramsItemComponent } from '../programs-item/programs-item.component';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { InfiniteScrollDirective } from '../../../directives/infinite-scroll.directive';
import { PaginationHelper } from '../../../common/helpers/pagination.helper';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'mec-programs-list',
  standalone: true,
  imports: [
    CommonModule,
    ProgramsItemComponent,
    SkeletonDirective,
    InfiniteScrollDirective,
    TranslateModule,
  ],
  providers: [ApiChainService],
  templateUrl: './programs-list.component.html',
  styleUrls: ['./programs-list.component.scss'],
})
export class ProgramsListComponent implements OnChanges {
  @Input() programType: ProgramType;
  @Input() tenantToken: string;
  @Output() activeProgramsCountChange = new EventEmitter<number>();

  public programList: ProgramList = new ProgramList();
  public isLoading: boolean = true;

  constructor(
    private _apiChainService: ApiChainService,
    private _toastService: ToastService
  ) {}

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.tenantToken) {
      //TODO
      //TODO
      //TODO
      //todo remove
      this.tenantToken = undefined;

      this.getProgramsList();
    }
  }

  public async getProgramsList(reset?: boolean): Promise<void> {
    switch (this.programType) {
      case ProgramType.POLICY:
        await this._getPolicies();
        break;
      case ProgramType.FINISHED:
        await this._getFinishedPrograms(reset);
        break;
      default:
        await this._getActivePrograms();
    }
  }

  private async _getActivePrograms(): Promise<void> {
    this.isLoading = true;

    try {
      const executions = await this._apiChainService.getExecutionsPending(
        this.tenantToken
      );
      this.programList.programs = executions.filter(
        (e) => !e.metadata?.group.includes('POLICY')
      );
    } catch (error) {
      this._toastService.showError(error);
    }
    this.isLoading = false;
  }

  private async _getPolicies(): Promise<void> {
    this.isLoading = true;

    try {
      const executions = await this._apiChainService.getExecutionsPending(
        this.tenantToken
      );
      this.programList.programs = executions.filter((e) =>
        e.metadata?.group.includes('POLICY')
      );

      this.activeProgramsCountChange.emit(this.programList.programs.length);
    } catch (error) {
      this._toastService.showError(error);
    }
    this.isLoading = false;
  }

  private async _getFinishedPrograms(reset?: boolean): Promise<void> {
    this.isLoading = true;
    this.programList.pagination.isLoadingMore = true;

    if (reset) {
      this.programList = new ProgramList();
    }

    try {
      const itemsToAdd = await this._apiChainService.getExecutionsCompleted(
        this.tenantToken,
        this.programList.pagination
      );

      this.programList = PaginationHelper.newHandlePagination<Program>(
        this.programList,
        'programs',
        itemsToAdd
      );
    } catch (error) {
      this._toastService.showError(error);
    }

    this.programList.pagination.isLoadingMore = false;
    this.isLoading = false;
  }
}
