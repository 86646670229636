import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import {
  faCertificate,
  faCircleNotch,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';

import { ApiCoursesV1Service } from '../../../../services/api/api-courses-v1.service';
import { ApiCoursesV2Service } from './../../../../services/api/api-courses-v2.service';
import { AuthenticationService } from './../../../../services/authentication.service';
import { CdnImages } from './../../../../pipes/cdn-images.pipe';
import { CertificateEntity } from '../../../../common/models/common/certificate/certificate-entity';
import { CourseStepCertificate } from './../../../../common/models/course/course-step-certificate';
import { DynamicHtmlModule } from '../../../common/dynamic-html/dynamic-html.module';
import { ToastService } from './../../../../services/toast.service';

@Component({
  selector: 'mec-step-certificate',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslateModule,
    CdnImages,
    DynamicHtmlModule,
  ],
  providers: [ApiCoursesV1Service],
  templateUrl: './step-certificate.component.html',
  styleUrls: ['./step-certificate.component.scss'],
})
export class StepCertificateComponent implements OnInit {
  @Input() step: CourseStepCertificate;
  @Input() certificate: CertificateEntity;
  @Input() courseId: number;
  @Input() courseSlug: string;

  public accessToken: string;
  public isAdministrator = false;
  public isEvaluator = false;
  public isLoading = false;

  public iconCertificate = faCertificate;
  public iconCircleNotch = faCircleNotch;
  public iconDownload = faDownload;

  private _timeoutId: ReturnType<typeof setTimeout>;

  constructor(
    private _authService: AuthenticationService,
    private _apiCoursesV2Service: ApiCoursesV2Service,
    private _toastService: ToastService
  ) {
    this.accessToken = this._authService.getToken();
  }

  public async ngOnInit() {
    this._getCertificate();
  }

  private async _getCertificate(isPooling?: boolean) {
    if (this.isAdministrator || this.isEvaluator) return;

    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
    }

    if (isPooling) {
      this._timeoutId = setTimeout(
        () => this._doGetCertificate(isPooling),
        500
      );
    } else {
      this._doGetCertificate();
    }
  }

  private async _doGetCertificate(isPooling?: boolean) {
    this.isLoading = true;

    try {
      const certificate = await this._apiCoursesV2Service.getCertificateByStep(
        this.step.id,
        this.courseId || this.courseSlug
      );

      if (isPooling && !certificate.fileHash) {
        return this._getCertificate(isPooling);
      }

      this.certificate = certificate;
    } catch (_doGetCertificateError) {
      console.error(_doGetCertificateError);
    }

    this.isLoading = false;
  }

  public async issueCertificate() {
    if (this.isAdministrator || this.isEvaluator) return;

    this.isLoading = true;

    try {
      await this._apiCoursesV2Service.issueCertificateForStep(
        this.step.id,
        this.courseId || this.courseSlug
      );
      this._toastService.showSuccess('FEEDBACK_YOUR_CERTIFICATE_WAS_ISSUED');
      this._getCertificate(true);
    } catch (issueCertificateError) {
      console.error(issueCertificateError);
      this._toastService.showError('FEEDBACK_FAIL_TO_ISSUE_CERTIFICATE');
    }

    this.isLoading = false;
  }
}
