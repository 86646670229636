import { IconProp } from '@fortawesome/fontawesome-svg-core';

export class EventBackgroundSession {
  public title?: string;
  public description?: string;
  public buttonText?: string;
  public buttonIcon?: string;
  public buttonVideoHash?: string;
  public buttonVideoId?: number;
  public buttonIconProp?: IconProp;
  public buttonDisabled?: boolean;
  public content?: string; //html
}
