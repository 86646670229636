import { SettingsEntity } from '../settings/settings-entity';
import { CustomHubComponent } from './custom-hub-component';

export class CustomHubConfiguration {
  public slug: string;
  public favicon: string;
  public coverImage: string;
  public customStyle?: boolean;
  public blockedSideBarPages?: string[];
  public components: { [key: string]: CustomHubComponent };
  public settings: SettingsEntity[];

  constructor(
    slug: string,
    favicon?: string | null,
    coverImage?: string | null
  ) {
    this.slug = slug;
    this.favicon = favicon;
    this.coverImage = coverImage;
    this.components = {};
  }
}
