import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ApiFormCarryService extends ApiBaseService {
  protected override apiBaseUrl = environment.formCarryUrl;
  protected override routePath = '';

  constructor(private _httpClient: HttpClient) {
    super(_httpClient);
  }

  public sendContact(
    contactSubmission: {
      [key: string]: string;
    }[],
    formId: string
  ): Promise<void> {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    const path = `${formId}`;

    return super.post(path, contactSubmission, { headers });
  }
}
