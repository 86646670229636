import { FormGroup } from '@angular/forms';
import { CustomFormType } from '../../enums/forms/custom-form-type';
import { CustomFormField } from './custom-form-field';
import { CustomFormAnswer } from './custom-form-answer';

export class CustomForm {
  public active: boolean;
  public createdAt: Date;
  public form: FormGroup;
  public hub: number;
  public id: string;
  public name: string;
  public required: boolean;
  public type: CustomFormType;

  public answers?: CustomFormAnswer[];
  public fields?: CustomFormField[];
  public originId?: string;
}
