import {
  HttpClient,
  HttpEventType,
  HttpHeaderResponse,
  HttpHeaders,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { MepFile } from './abstract/mep-file.abstract';
import { FileEntity } from '../common/models/files/file-entity';

@Injectable({
  providedIn: 'root',
})
export class FileWebService implements MepFile {
  constructor(private _httpClient: HttpClient) {}

  public async downloadFileFromUrl(
    url: string,
    fileName: string
  ): Promise<void> {
    const data = await fetch(url);
    const blob = await data.blob();
    const objectUrl = URL.createObjectURL(blob);

    const link = document.createElement('a');

    link.setAttribute('href', objectUrl);
    link.setAttribute('download', fileName);
    link.style.display = 'none';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }

  public upload(
    file: FileEntity,
    headerTags?: { [key: string]: string }[],
    completed?: (response: HttpResponse<any> | HttpHeaderResponse) => void,
    progressChanged?: (progress: HttpProgressEvent) => void
  ): Subscription {
    const headers = new HttpHeaders({
      'Content-Type': file.mimeType,
    });

    if (headerTags?.length > 0) {
      Object.keys(headerTags).forEach((key, index) => {
        const value = headerTags[index][key].toString();
        headers.append(key, value);
      });
    }

    const request = new HttpRequest(
      'PUT',
      file.uploadSignature?.url,
      file.blob,
      {
        headers,
        reportProgress: progressChanged ? true : false,
      }
    );

    const requestResult = this._httpClient
      .request(request)
      .subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          progressChanged && progressChanged(event as HttpProgressEvent);
        } else if (
          event instanceof HttpResponse ||
          event instanceof HttpHeaderResponse
        ) {
          completed && completed(event);
          requestResult.unsubscribe();
        }
      });

    return requestResult;
  }
}
