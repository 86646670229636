import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faDatabase,
  faFile,
  faDownload,
  faPenToSquare,
  faCircleInfo,
  faBookOpen,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ApiFilesV2Service } from '../../../services/api/api-files-v2.service';
import { ApiLibraryService } from '../../../services/api/api-library.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { CommonConstant } from '../../../common/constants/common.constants';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { CommonService } from '../../../services/common.service';
import { CpdService } from '../../../services/cpd.service';
import { FileEntity } from '../../../common/models/files/file-entity';
import { FileTypes } from '../../../common/enums/file/file-types';
import { LibraryItem } from '../../../common/models/library/library-item';
import { MepFile } from '../../../services/abstract/mep-file.abstract';
import { ModalComponent } from '../../common/modal/modal.component';
import { ModalOptions } from '../../../common/models/modal/modal-options';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'mec-library-edit-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    SkeletonDirective,
    MatProgressSpinnerModule,
  ],
  templateUrl: './library-edit-modal.component.html',
  styleUrls: ['./library-edit-modal.component.scss'],
})
export class LibraryEditModalComponent implements OnInit {
  public static readonly MODAL_WIDTH = 500;

  public editFileForm: FormGroup;
  public fileId: number;
  public fileTypes = FileTypes;
  public imageDefaultWidth = CommonConstant.imageDefaultWidth;
  public imageFormat = CommonConstant.imageFormat;
  public libraryFileId: number;
  public libraryFileInfo: FileEntity;
  public libraryItem: LibraryItem;
  public modalOptions: ModalOptions;

  public hiddenImage: boolean;
  public isDownloading: boolean;
  public isLoading: boolean;
  public isLoadingImage = true;
  public isLogged: boolean;
  public isSaving: boolean;

  public editableMode: boolean;
  public isEditableMode = true;

  public databaseIcon = faDatabase;
  public documentIcon = faFile;
  public downloadIcon = faDownload;
  public editIcon = faPenToSquare;
  public infoIcon = faCircleInfo;
  public readIcon = faBookOpen;

  public cpdTimeout: any;

  constructor(
    public _commonService: CommonService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id: number;
      fileId: number;
      isEditableMode: boolean;
    },
    public matDialogRef: MatDialogRef<LibraryEditModalComponent>,
    private _apiFilesV2Service: ApiFilesV2Service,
    private _apiLibraryService: ApiLibraryService,
    private _authService: AuthenticationService,
    private _cpdService: CpdService,
    private _formBuilder: FormBuilder,
    private _mepFile: MepFile,
    private _toastService: ToastService,
    private _translateService: TranslateService
  ) {
    this.isLogged = this._authService.isLogged;

    this.libraryFileId = this.data['id'];
    this.fileId = this.data['fileId'];
    this.isEditableMode = this.data['isEditableMode'] === false ? false : true;

    this.modalOptions = {
      title: this.isEditableMode
        ? this._translateService.instant('EDIT_FILE')
        : this._translateService.instant('FILES_VIEW_FILE'),
      closeable: true,
      positiveButtonLabel: 'Save',
      showPositiveButton: true,
      showNegativeButton: false,
      showSecondaryNegativeButton: false,
      showSecondaryPositiveButton: false,
      hideFooter: !this.isEditableMode,
    };
  }

  private _createCpdSurvey() {
    if (!this._commonService.isPlatformBrowser || !this.isLogged) return;
    const { cpdConfigs, ownContentType, id } = this.libraryItem;
    this._cpdService.createSurvey(cpdConfigs, ownContentType, id);
  }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;

    if (this.libraryFileId) {
      await this._getLibraryFileById();
    } else {
      await this._getFileById(this.fileId);
    }

    this.isLoading = false;
  }

  private async _getLibraryFileById(): Promise<void> {
    try {
      this.libraryItem = await this._apiLibraryService.getLibraryFileById(
        this.libraryFileId
      );

      this.editFileForm = this._formBuilder.group({
        title: [this.libraryItem?.content?.title],
      });

      await this._getFileById(this.libraryItem.fileId);
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  private async _getFileById(fileId: number): Promise<void> {
    try {
      this.libraryFileInfo = await this._apiFilesV2Service.getFileById(fileId);
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  public async saveLibraryItem(): Promise<void> {
    this.isSaving = true;

    const editedForm = this.editFileForm.value;
    this.libraryItem.content.title = editedForm.title;

    try {
      await this._apiLibraryService.saveLibraryItem(this.libraryItem);
      this._toastService.showSuccess();
      this.closeModal(true);
    } catch (error) {
      this._toastService.showError(error);
    }

    this.isSaving = false;
  }

  public closeModal(save: boolean): void {
    this.matDialogRef.close(save);
  }

  public editMode(): void {
    this.editableMode = !this.editableMode;
  }

  public downloadItem(hash: string) {
    this._commonService.downloadFileFromUrl(
      CommonHelper.getFileUrl(hash, {
        access_token: this._authService.getToken(),
        download: true,
      }),
      this.libraryItem?.content?.title
    );

    this._createCpdSurvey();
  }

  public async downloadImage(
    url: string,
    fileNameAndExtension: string
  ): Promise<void> {
    this.isDownloading = true;
    try {
      await this._mepFile.downloadFileFromUrl(url, fileNameAndExtension);
    } catch (error) {
      this._toastService.showError(error);
    }

    if (this._commonService.isPlatformBrowser) {
      setTimeout(() => {
        this.isDownloading = false;
      }, 500);
    } else {
      this.isDownloading = false;
    }
  }
}
