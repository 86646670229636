import { SafeUrl } from '@angular/platform-browser';
import { FilePrivacy } from '../../enums/file/file-privacy';
import { FileStatus } from '../../enums/file/file-status';
import { FileTypes } from '../../enums/file/file-types';
import { FileEntity } from '../files/file-entity';
import { UploadSignature } from '../library/file/upload-signature';

export class SocialAttachment {
  public type?: FileTypes;
  public id?: number;
  public file?: FileEntity;
  public createdAt?: Date;
  public filename: string;
  public extension: string;
  public length: number;
  public mimeType: string;
  public removable?: boolean;
  public shareable?: boolean;
  public path?: string;
  public url?: string;
  public isOwner?: boolean;
  public fileId?: number;
  public status?: FileStatus;
  public privacy?: FilePrivacy;
  public fileHash?: string;
  public typeText?: string;
  public isTypeImage: boolean;
  public imageBlob?: SafeUrl;
  public selected?: boolean;
  public isLoading?: boolean;
  public blob?: Blob;
  public uploadMessage?: string;
  public uploadSignature?: UploadSignature;
}
