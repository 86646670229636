import { FileEntity } from '../files/file-entity';
import { HubEntity } from '../hub/hub-entity';

export declare class CpdConfigs {
  public id: number;

  //  specific
  public campus: HubEntity;

  // CPD Type
  public type: string; // todo(mleon): implement menu 'GENERAL'
  public file?: FileEntity; // todo(mleon): adjust to be required if type is 'FILE'

  // Care Monitor specific
  public program: string;
  public programName: string;
  public tenant: string;

  // CPD specific
  public certificateName: string;
  public credits: number;
  public partNumber: string;
}
