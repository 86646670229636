import { DirectionOptions } from '../../enums/common/direction-options';
import { PaginationOptions } from '../common/pagination-options';
import { SocialNetworkReaction } from './social-network-reaction';

export class SocialNetworkReactionList {
  public reactions: SocialNetworkReaction[];
  public pagination: PaginationOptions = {
    direction: DirectionOptions.desc,
    limit: 10,
    page: 0,
  };
}
