import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../environments/environment';
import { i18nService } from './i18n.service';
import { Hub } from './abstract/hub.abstract';
import { Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { CustomHubConfiguration } from '../common/models/hub/custom-hub-configuration';

@Injectable({
  providedIn: 'root',
})
export class HubMobileService implements Hub {
  private _basePath: string;
  private _hub: string;
  private _hubHeaders: any;
  private _hubConfiguration: CustomHubConfiguration;
  public onHubChange = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private _i18nService: i18nService
  ) {
    this._hub;
  }

  public setFixedHub(hub: string): void {
    this._hub = hub;

    this.onHubChange.next();
  }

  public setHub(): void {
    if (this._hub) {
      return;
    }

    this._hub = this._getUrlHub() || 'hub-meplis';

    this.onHubChange.next();
  }

  public host(): string {
    return [
      'https://',
      environment.name === 'DEVELOPMENT' ? 'dev-' : '',
      this._hub,
      '.meplis.com',
    ].join('');
  }

  public hub(): string {
    this.setHub();
    return this._hub;
  }

  public getComponents() {
    return this._hubConfiguration?.components;
  }

  public setHubConfiguration(configuration: CustomHubConfiguration) {
    this._hubConfiguration = configuration;
  }

  public getHubConfigurationByProp(prop: any): any {
    return this._hubConfiguration
      ? this._hubConfiguration[prop as keyof CustomHubConfiguration]
      : null;
  }

  public basePath() {
    return this._basePath;
  }

  public setBasePath(basePath: string) {
    this._basePath = basePath;
  }

  public _getUrlHub(): string {
    if (!isPlatformBrowser(this.platformId)) {
      return '';
    }

    const basePathParts = this._basePath ? this._basePath.split('/') : [];
    const currentUrl = location.pathname.replace('/', '');
    const currentUrlArray = currentUrl.split('/');

    let hub = '';

    if (basePathParts.length === 3) {
      hub = currentUrlArray[0];
    } else {
      if (environment.name === 'PRODUCTION') {
        const hostName = location.hostname;
        const urlWithoutSeo = hostName.replace('seo-', '');
        const hubProArray = urlWithoutSeo.split('.');
        hub = hubProArray[0];
      } else if (environment.name === 'DEVELOPMENT') {
        const urlWithoutDev = location.hostname.replace('dev-', '');
        const urlWithoutSeo = urlWithoutDev.replace('seo-', '');
        const hubDevArray = urlWithoutSeo.split('.');
        hub = hubDevArray[0];
      }
    }

    return hub;
  }

  public hubHeaders(): any {
    if (!this._hubHeaders) {
      this.setHubHeaders();
    }
    return this._hubHeaders;
  }

  public setHubHeaders(): void {
    this._hubHeaders = {
      'x-mepl-campus': this._hub,
      'x-Mepl-Language': this._i18nService.language,
      'x-Mepl-Region-Slug': this._i18nService.region,
    };
  }

  public getSetting(type: string): string | null {
    return (
      this._hubConfiguration.settings.find((setting) => setting.type === type)
        ?.value || null
    );
  }
}
