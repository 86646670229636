import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { MentionsListComponent } from '../components/common/mentions-list/mentions-list.component';
import { ProfileEntity } from '../common/models/profile/profile-entity';

@Directive({
  selector: '[mecMention]',
  standalone: true,
})
export class MentionDirective implements OnChanges {
  @Input() mentionItemsList: ProfileEntity[];
  @Input() mentionsBody: string = '';
  @Output() mentionsBodyChange = new EventEmitter<string>();
  @Input() isLoading: boolean;
  @Output() searchChange = new EventEmitter<string>();

  public mentionsList: MentionsListComponent;
  public textSearch: string = '';
  public searchMode: boolean;

  public mentionsArray: { name: string; nameWithId: string }[] = [];

  public ignoredChars = [8, 9, 16, 27, 37, 38, 39, 40, 229];

  constructor(
    private _elementRef: ElementRef,
    private _viewContainerRef: ViewContainerRef
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      this.mentionsList &&
      (changes['mentionItemsList'] || changes['isLoading'])
    ) {
      this.mentionsList.itemsList = this.mentionItemsList;
      this.mentionsList.isLoading = this.isLoading;
    }
  }

  public addMentionsList(): void {
    if (this.mentionsList == null) {
      const componentRef = this._viewContainerRef.createComponent(
        MentionsListComponent
      );
      this.mentionsList = componentRef.instance;
      this.mentionsList.itemsList = this.mentionItemsList;

      this.mentionsList['itemSelected'].subscribe((item) => {
        this._selectItem(item);
      });

      this.mentionsList.position(this._elementRef.nativeElement);
    } else {
      this.mentionsList.position(this._elementRef.nativeElement);
      this.mentionsList.hidden = false;
    }
  }

  private _selectItem(item: ProfileEntity): void {
    const textInput = this._elementRef.nativeElement.value;
    const textInputTransformed = textInput.replace(
      `@${this.textSearch}`,
      `@${item.fullName}`
    );

    this.mentionsArray.push({
      name: `@${item.fullName}`,
      nameWithId: `@[${item.fullName}:${item.id}]`,
    });

    this.loadMentionsOnText(textInputTransformed);

    this._elementRef.nativeElement.value = textInputTransformed;

    this.textSearch = '';
    this.mentionsList.hidden = true;
    this.searchMode = false;
  }

  private loadMentionsOnText(textInput: string): void {
    let bodyText = textInput;

    this.mentionsArray.forEach((mention) => {
      bodyText = bodyText.replace(mention.name, mention.nameWithId);
    });

    this.mentionsBodyChange.emit(bodyText);
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (event.key === '@') {
      this.searchMode = true;
    } else if (
      this.textSearch?.length &&
      (event.key === 'Delete' || event.key === 'Backspace')
    ) {
      //remove last character when press backspace or delete
      this.textSearch = this.textSearch.substring(
        0,
        this.textSearch.length - 1
      );

      if (this.textSearch.length <= 0) {
        this.searchMode = false;
        if (this.mentionsList) {
          this.mentionsList.hidden = true;
        }
      }
    } else if (this.searchMode && !this.ignoredChars.includes(event.keyCode)) {
      this.textSearch += event.key;
      this.searchChange.emit(this.textSearch);

      this.addMentionsList();
    }
  }

  @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
    let textInput = this._elementRef.nativeElement.value;
    this.loadMentionsOnText(textInput);
  }
}
