<mec-modal [options]="modalOptions" (closeClick)="matDialogRef.close(false)">
  <div class="reactions-list">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab *ngFor="let group of groupedReactions">
        <ng-template mat-tab-label>
          <fa-icon
            class="reactions-list__icon-tab"
            [ngClass]="{
            'reactions-list__icon-tab--like':
            reactionsInfo.reactions[group[0]].name === reactionsTypes.LIKE,
            'reactions-list__icon-tab--love':
                reactionsInfo.reactions[group[0]].name === reactionsTypes.LOVE,
            'reactions-list__icon-tab--genius':
                reactionsInfo.reactions[group[0]].name === reactionsTypes.GENIUS,
            'reactions-list__icon-tab--support':
                reactionsInfo.reactions[group[0]].name === reactionsTypes.SUPPORT,
            'reactions-list__icon-tab--funny':
                reactionsInfo.reactions[group[0]].name === reactionsTypes.FUNNY,
            }"
            [icon]="reactionsInfo.reactions[group[0]].icon"
          ></fa-icon>
          <span> {{ group[1].length }}</span>
        </ng-template>
        <div class="reactions-list__participants">
          <ng-container *ngFor="let reaction of group[1]">
            <div
              (click)="goToParticipant(reaction?.participant?.profileId)"
              class="reactions-list__participant"
            >
              <img
                class="reactions-list__avatar avatar"
                src="{{ getAvatarUrl(reaction?.participant?.profileId) }}"
              />
              <div class="reactions-list__participant-info">
                <h4>
                  {{ reaction?.participant?.firstname }}
                  {{ reaction?.participant?.lastname }}
                </h4>
                <span> {{ reaction?.participant?.biography }} </span>
              </div>
              <button class="btn btn--sm btn--icon-only btn--no-bg">
                <fa-icon [icon]="arrowIcon"></fa-icon>
              </button>
            </div>
            <div class="reactions-list__separator"></div>
          </ng-container>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</mec-modal>
