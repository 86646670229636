<div class="social-comments-list">
  <ng-container *ngFor="let comment of comments.threads">
    <mec-social-comment-item
      (socialThreadDeleteChange)="deleteThread(comment)"
      [comment]="comment"
    ></mec-social-comment-item>
  </ng-container>
  <button
    (click)="getCommments()"
    *ngIf="comments.pagination.isAfterFirstLoad && comments.pagination.loadMore"
    class="btn btn--sm btn--no-bg"
  >
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <ng-container *ngIf="!isLoading">
      <span>{{ 'LOAD_MORE' | translate }}</span>
      <span>{{ 'SOCIALNETWORK_COMMENTS' | translate }}</span>
    </ng-container>
  </button>
</div>
<!-- Loader Skeleton start -->
<ng-container *ngIf="isLoading">
  <div class="social-publication-list">
    <div
      *ngFor="let item of [0, 1, 2]"
      [medSkeletonLoading]="true"
      style="height: 70px; width: 100%; margin-bottom: 24px"
    ></div>
  </div>
</ng-container>
<!-- Loader Skeleton end -->
