import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonService } from '../../services/common.service';
import {
  faArrowRight,
  faClipboard,
  faPenSquare,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'mec-how-to-join',
  standalone: true,
  imports: [CommonModule, TranslateModule, FontAwesomeModule],
  templateUrl: './how-to-join.component.html',
  styleUrls: ['./how-to-join.component.scss'],
})
export class HowToJoinComponent {
  iconArrowRight = faArrowRight;
  iconCreateAccount = faClipboard;
  iconStart = faPenSquare;
  iconSubscription = faUserCircle;

  constructor(public _commonService: CommonService) {}

  public goToPage(path: string): void {
    this._commonService.navigate([path]);
  }
}
