<ng-container *ngIf="!disableItems">
  <h2 *ngIf="title" (click)="goToPage()" class="events-card-list__title">
    {{ title | translate }}
  </h2>
  <div
    [loadMore]="searchResultsList.pagination.loadMore"
    [isLoadingMore]="searchResultsList.pagination.isLoadingMore"
    (medInfiniteScroll)="getEvents()"
    class="events-card-list__list-items"
  >
    <mec-search-card
      [searchResult]="searchResult"
      *ngFor="let searchResult of searchResultsList.searchResult"
    ></mec-search-card>

    <h2
      class="abbott-cards-list__no-results"
      *ngIf="!isLoadingEvents && !searchResultsList?.searchResult?.length"
    >
      {{ 'NO_RESULTS_FOUND' | translate }}
    </h2>

    <!-- Loader Skeleton start -->
    <ng-container *ngIf="isLoadingEvents">
      <div
        *ngFor="let item of [0, 1, 2]"
        [medSkeletonLoading]="true"
        style="height: 300px; width: 100%"
      ></div>
    </ng-container>
    <!-- Loader Skeleton end -->
  </div>
</ng-container>
