import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import {
  faCertificate,
  faCheck,
  faDownload,
  faEllipsisVertical,
  faTag,
} from '@fortawesome/free-solid-svg-icons';

import { AuthenticationService } from '../../../services/authentication.service';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { CommonService } from '../../../services/common.service';
import { DropdownMenuItem } from '../../../common/models/common/dropdown-menu/dropdown-menu-item';
import { DropdownMenuNames } from '../../../common/enums/common/dropdown-menu/dropdown-menu-names';
import { Hub } from '../../../services/abstract/hub.abstract';
import { SavedItemsService } from '../../../services/saved-items.service';
import { SearchResult } from '../../../common/models/common/search/search-result';
import { environment } from '../../../environments/environment';
import { CpdService } from '../../../services/cpd.service';

@Component({
  selector: 'mec-dropdown-menu',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    MatMenuModule,
    TranslateModule,
  ],
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent implements OnChanges, OnInit {
  @Input() public disableDropdownMenu?: boolean;
  @Input() public searchResult: SearchResult;

  @Output() public itemClick = new EventEmitter<DropdownMenuItem>();

  public iconEllipsisVertical = faEllipsisVertical;

  public isLogged: boolean;
  public hasMenuItems = false;
  public menuItems: DropdownMenuItem[] = [];

  constructor(
    private _authService: AuthenticationService,
    private _commonService: CommonService,
    private _cpdService: CpdService,
    private _hubService: Hub,
    private _savedItemsService: SavedItemsService
  ) {
    this.isLogged = this._authService.isLogged;
  }

  private _createCpdSurvey() {
    if (!this._commonService.isPlatformBrowser || !this.isLogged) return;
    const { cpdConfigs, ownContentType, id } = this.searchResult;
    this._cpdService.createSurvey(cpdConfigs, ownContentType, id);
  }

  public ngOnInit() {
    this.disableDropdownMenu =
      this.disableDropdownMenu === undefined ? false : this.disableDropdownMenu;
  }

  public ngOnChanges(/* changes: SimpleChanges */): void {
    this.updateMenuItems();
  }

  public menuItemsClick(item: DropdownMenuItem) {
    this.itemClick.emit(item);

    if (this.disableDropdownMenu) return;

    switch (item.name) {
      case DropdownMenuNames.FILES_DOWNLOAD:
        this.downloadFile();
        break;

      case DropdownMenuNames.CPD_DOWNLOAD:
        this.downloadCertificate();
        break;

      case DropdownMenuNames.SAVED_ITEMS_ADD:
      case DropdownMenuNames.SAVED_ITEMS_REMOVE:
      default:
        this.toggleSavedItem();
        break;
    }
  }

  private generateMenuItems(): DropdownMenuItem[] {
    if (!this._authService.isLogged || !this.searchResult) return [];
    try {
      return [
        {
          icon: faDownload,
          name: DropdownMenuNames.FILES_DOWNLOAD,
          showItem: !!this.getDownloadFileUrl(),
        },
        {
          icon: faCertificate,
          name: DropdownMenuNames.CPD_DOWNLOAD,
          showItem: !!this.searchResult.cpdCredit,
        },
        {
          icon: faCheck,
          name: DropdownMenuNames.SAVED_ITEMS_REMOVE,
          showItem: this.searchResult.savedItem,
        },
        {
          icon: faTag,
          name: DropdownMenuNames.SAVED_ITEMS_ADD,
          showItem: !this.searchResult.savedItem,
        },
      ].filter((item) => item.showItem);
    } catch (errorMenu) {
      console.log(errorMenu);
      return [];
    }
  }

  private getDownloadCertificateUrl(): string {
    if (!this.searchResult.cpdCredit) return '';

    const output = new URL(
      [
        environment.campusV2,
        'hubs',
        this._hubService.hub(),
        'cpd-certificate',
        this.searchResult.cpdCredit.token,
        'download',
      ].join('/')
    );

    output.searchParams.append('access_token', this._authService.getToken());

    return output.toString();
  }

  private getDownloadFileUrl(): string {
    let output = this.searchResult.url;
    if (!output) {
      output = this.searchResult.content.fileHash || this.searchResult.fileHash;
    }
    if (output) {
      output = CommonHelper.getFileUrl(output, {
        access_token: this._authService.getToken(),
        download: true,
      });
    } else {
      return '';
    }
    return output;
  }

  private updateMenuItems() {
    this.menuItems = this.generateMenuItems();
    this.hasMenuItems = this.menuItems.length > 0;
  }

  public downloadFile() {
    const url = this.getDownloadFileUrl();
    if (!url) return;

    this._commonService.downloadFileFromUrl(
      url,
      this.searchResult?.content?.title
    );

    this._createCpdSurvey();
  }

  public downloadCertificate() {
    const url = this.getDownloadCertificateUrl();
    if (!url) return;

    this._commonService.navigateByUrl(url, null, true);
  }

  public async toggleSavedItem() {
    this.searchResult = await this._savedItemsService.toggleSavedItem(
      this.searchResult
    );
    this.updateMenuItems();
  }
}
