
export enum StepType {
  UPLOAD = 'UPLOAD',
  OPEN_TEXT = 'OPEN_TEXT',
  OTHER = 'OTHER',
  CONTENT = "CONTENT",
  CERTIFICATE = "CERTIFICATE",
  EXAM = "EXAM",
  SURVEY = "SURVEY",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
}