import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';

@Component({
  selector: 'mec-program-output',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './program-output.component.html',
  styleUrls: ['./program-output.component.scss'],
})
export class ProgramOutputComponent {
  @Input() public programNode: ProgramNode;

  constructor() {}
}
