import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments';
import { ApiBaseService } from './api-base.service';
import { LibraryItem, PaginationOptions } from '../../common';
import { Hub } from '../abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class ApiLibraryService extends ApiBaseService {
  protected apiBaseUrl = environment.campusV2;
  protected routePath = `hubs/${this._hubService.hub()}`; // : string;

  constructor(private _hubService: Hub, private _httpClient: HttpClient) {
    super(_httpClient);

    // this._hubService.onHubChange.subscribe(() => {
    //   this.routePath = `hubs/${this._hubService.hub()}`;
    // });
  }

  public getLibraryFiles(
    paginationOptions: PaginationOptions
  ): Promise<LibraryItem[]> {
    let path = `/libraries?direction=${paginationOptions.direction}&limit=${paginationOptions.limit}&page=${paginationOptions.page}`;

    if (paginationOptions.path) {
      path = `${path}&path=${paginationOptions.path}`;
    }

    return super.get<LibraryItem[]>(path);
  }

  public confirmLibraryFiles(
    libraryItemToConfirm: LibraryItem[],
    folderPath: string
  ): Promise<LibraryItem[]> {
    let path = `/libraries?direction=desc&limit=10&orderby=type,id`;

    const getParams = new HttpParams();
    getParams.set('direction', 'desc');
    getParams.set('limit', '10');
    getParams.set('orderby', 'type,id');

    if (folderPath) {
      path = `${path}&path=${folderPath}`;
    }

    const postParams = libraryItemToConfirm;

    return super.post<LibraryItem[]>(path, postParams, { params: getParams });
  }

  public getLibraryFolderBySlug(slug: string): Promise<LibraryItem> {
    const path = `/libraries-folder?slug=${slug}`;
    return super.get<LibraryItem>(path);
  }

  public getLibraryFileById(libraryFileId: number): Promise<LibraryItem> {
    const path = `/libraries/${libraryFileId}`;
    return super.get<LibraryItem>(path);
  }

  public saveLibraryItem(libraryItem: LibraryItem): Promise<void> {
    const path = `/libraries/${libraryItem.id}`;
    return super.put<void>(path, libraryItem);
  }

  public deleteLibraryItem(id: number): Promise<void> {
    const path = `/libraries/${id}`;
    return super.delete<void>(path);
  }
}
