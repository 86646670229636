<ng-container *ngIf="!disableCpd">
  <div class="cpd-management-card-list">
    <div
      class="cpd-management-card-list__list-items"
      [loadMore]="cpdManagementList?.pagination.loadMore"
      [isLoadingMore]="cpdManagementList.pagination.isLoadingMore"
      (medInfiniteScroll)="getCpdManagementList()"
    >
      <mec-search-card
        [searchResult]="cpdManagement"
        *ngFor="let cpdManagement of cpdManagementList?.cpdManagement"
      ></mec-search-card>
      <!-- Loader Skeleton start -->
      <ng-container *ngIf="cpdManagementList?.pagination?.isLoadingMore">
        <div
          *ngFor="let item of [0, 1, 2]"
          [medSkeletonLoading]="true"
          style="height: 300px; width: 100%"
        ></div>
      </ng-container>
      <!-- Loader Skeleton end -->
    </div>
    <h2
      class="cpd-management-card-list__no-results"
      *ngIf="!isLoading && !cpdManagementList?.cpdManagement?.length"
    >
      {{ 'NO_RESULTS_FOUND' | translate }}
    </h2>
  </div>
</ng-container>
