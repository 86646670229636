export class ContentCourse {
    public title: string;
    public content: string;
    public subtitle: string;
    public description?: string;
    public expectedTimeToComplete?: string;
    public expectedTimeToCompleteTimeUnit?: string;
    public footerContent?: string;
    public goals?: string;
    public name?: string;
    public nonGoals?: string;
    public support?: string;
    public targetAudience?: string;
}