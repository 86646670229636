<div class="rules">
  <h2>{{ 'EX_STEP_UNLOCK_TITLE' | translate }}</h2>
  <p>{{ 'EX_STEP_UNLOCK_DESCRIPTION' | translate }}</p>

  <div class="rules__container">
    <div class="rules__list" *ngFor="let rule of rules">
      <p>
        <span>{{ 'IF' | translate }}</span>
        <strong> {{ rule.type | translate }} </strong>
        <span>{{ 'IS' | translate }}</span>
        <strong> {{ rule.operator | translate }} </strong>
        <strong> {{ rule.expectedValue }} </strong>
      </p>

      <div class="rules__retake-container" *ngIf="rule.retake">
        <p>
          <span>{{ 'EX_STEP_RETAKE_1' | translate }}</span
          >&nbsp;
          <strong>
            <span> {{ rule.operator | translate }} </span>
            <span> {{ rule.expectedValue }} </span>
          </strong>
          <span>{{ 'EX_STEP_RETAKE_3' | translate }}</span
          >&nbsp;
          <strong>
            <span>{{ 'EX_STEP_RETAKE_4' | translate }}</span
            >&nbsp;
          </strong>
          <span>{{ 'EX_STEP_RETAKE_5' | translate }}</span>
        </p>
      </div>

      <div class="rules__retake-container" *ngIf="!rule.retake">
        <span>{{ 'UNLOCKS' | translate }}</span>
        <div class="rules__retake-list">
          <div
            class="rules__retake-item"
            *ngFor="let lockedStep of rule.lockedSteps"
          >
            <fa-icon [icon]="checkIcon"></fa-icon>
            <span> {{ lockedStep.content.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
