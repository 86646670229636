import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventParticipant } from '../../../common/models/common/event-participant';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { CommonService } from '../../../services/common.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { EventService } from '../../../services/event.service';
import { AudioItem } from '../../../common/models/event/audio-item';

@Component({
  selector: 'mec-event-participants-item',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
  templateUrl: './event-participants-item.component.html',
  styleUrls: ['./event-participants-item.component.scss'],
})
export class EventPresentationsItemComponent implements OnInit {
  @Input() public participant: EventParticipant;

  public playIcon: IconProp = this._commonService.getIcon('fa-circle-play');

  constructor(
    private _commonService: CommonService,
    private _eventService: EventService
  ) {}

  public ngOnInit(): void {}

  public updateVideoPlayer(videoUrl: string, currentTime: number = 0): void {
    let audioItem: AudioItem = {
      videoUrl,
      currentTime,
    };

    this._eventService.playerUpdateSubject.next(audioItem);
  }
}
