import { CustomFormFieldType } from '../../enums/forms/custom-form-field-type';
import { CustomFormFieldCondition } from './custom-form-field-condition';
import { CustomFormFieldOption } from './custom-form-field-options';

export class CustomFormField {
  public label: string;
  public name: string;
  public value: string;
  public owner: boolean;
  public placeholder: string;
  public required: boolean;
  public type: CustomFormFieldType;
  public isValidField: boolean;
  public conditions?: CustomFormFieldCondition[];
  public options?: CustomFormFieldOption[];
}
