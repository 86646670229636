import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { CommonService } from '../../../../services/common.service';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'mec-step-exam-score-info',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
  templateUrl: './step-exam-score-info.component.html',
  styleUrls: ['./step-exam-score-info.component.scss'],
})
export class StepExamScoreInfoComponent {
  @Input() public score: number;
  @Output() public actionClick = new EventEmitter<void>();

  public iconInfo = faCircleInfo;

  constructor(public _commonService: CommonService) {}

  public clickLink(): void {
    this.actionClick.emit();
  }
}
