import { Injectable } from '@angular/core';
import { Step, Course } from '../common';
import { InstanceService } from './instance.service';
import * as moment from 'moment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  constructor(
    private _instanceService: InstanceService,
    private _authService: AuthenticationService
  ) {}

  public async hasShowInvitedOnly(course: Course): Promise<boolean> {
    return (
      course.invitedOnly &&
      !course.invitationDate &&
      !(await this.isManager(course))
    );
  }

  public async hasShowGoToSubscribe(course: Course): Promise<boolean> {
    if (!this._authService.isLogged) {
      return false;
    }

    const hasShowGoToClassroom = await this.hasShowGoToClassroom(course);
    const hasShowInvitedOnly = await this.hasShowInvitedOnly(course);

    return !hasShowGoToClassroom && !hasShowInvitedOnly;
  }

  public isExpired(course: Course): boolean {
    let isExpired = false;

    if (
      course.timeLimitCountdown === 'INVITATION_DATE' &&
      course.type !== 'SELF_PACED'
    ) {
      const now = moment(new Date());
      const invitationDate = course.invitationDate;
      const timeInDays = parseInt(course.timeLimitDurationInDays);
      const diference = moment.duration(now.diff(invitationDate));
      const days = diference.asDays();
      isExpired = days > timeInDays;
    }

    return isExpired;
  }

  public async hasShowGoToClassroom(course: Course) {
    const isManager = await this.isManager(course);
    const isScholar = await this.isScholar(course);

    const expired = isScholar && this.isExpired(course);
    const result = isManager || !expired;

    if (isManager) {
      return true;
    }

    if (isScholar && !expired) {
      return true;
    }

    return false;
  }

  public async isManager(course: Course): Promise<boolean> {
    const role = await this._instanceService.getRole();
    return course.evaluator || role?.administrator;
  }

  public async isScholar(course: Course): Promise<boolean> {
    const role = await this._instanceService.getRole();
    return course.scholar;
  }

  public flatArrayStep(step: Step): Step[] {
    const stepsFlat: Step[] = [];

    step.children.forEach((stepChildren) => {
      let childrens: Step[] = [];

      if (step.children.length) {
        childrens = stepChildren.children;
      }

      const childrenOfChildren = this.flatArrayStep(stepChildren);
      childrens = [stepChildren, ...childrenOfChildren];

      stepsFlat.push(...childrens);
    });
    return stepsFlat;
  }

  public orderFlatSteps(stepsCourse: Step[]): Step[] {
    const flatSteps: Step[] = [];

    const childrenSteps = this.orderChildrenSteps(stepsCourse);

    childrenSteps.forEach((currentStep) => {
      const flattenChildren = this.flatArrayStep(currentStep);

      flatSteps.push(currentStep, ...flattenChildren);
    });

    return flatSteps;
  }

  public orderChildrenSteps(stepsCourse: Step[]): Step[] {
    let steps: Step[] = [];
    const stepsIdsToRemoveFirstLevel: number[] = [];

    stepsCourse.forEach((currentStep) => {
      const stepsByParentId = stepsCourse.filter(
        (step) => step.parentId == currentStep.id
      );
      currentStep.children = stepsByParentId;

      const idsToRemoveFirstLevel = stepsByParentId.map((step) => step.id);
      stepsIdsToRemoveFirstLevel.push(...idsToRemoveFirstLevel);

      steps.push(currentStep);
    });

    steps = steps.filter(
      (step) => stepsIdsToRemoveFirstLevel.indexOf(step.id) === -1
    );

    return steps;
  }
}
