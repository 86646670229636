export class TagContent {
  public imageBackgroundHash: string;
  public title: string;
  public isDarkLabel?: boolean;
  public contentA: string;
  public listLink: string;
  public listA: string;
  public listB: string;
  public listC: string;
  public listD: string;
  public listE: string;
  public icon: string;
  public image: string;
}
