import {
  HttpResponse,
  HttpHeaderResponse,
  HttpProgressEvent,
} from '@angular/common/http';
import { Subscription } from 'rxjs';
import { FileEntity } from '../../common/models/files/file-entity';

export abstract class MepFile {
  public abstract downloadFileFromUrl(
    url: string,
    fileName: string
  ): Promise<void>;

  public abstract upload(
    file: FileEntity,
    headerTags?: { [key: string]: string }[],
    completed?: (response: HttpResponse<any> | HttpHeaderResponse) => void,
    progressChanged?: (progress: HttpProgressEvent) => void
  ): Subscription;
}
