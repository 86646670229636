import { MenuItem } from './menu-item';

export class MenuData {
  public name?: string;
  public login?: string;
  public email?: string;
  public backgroundImage?: string;
  public imageAvatarUrl?: string;
  public menuItems: MenuItem[] = [];
}
