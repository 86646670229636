<div class="program-step-container">
  <div class="program-step-container__items">
    <div *ngFor="let item of programStep; let i = index" #node1>
      <ng-container *ngIf="item.type != 'conditional'">
        <div
          class="program-step-container__item card"
          *ngIf="item.type === 'start'"
        >
          <h4>{{ item?.description }}</h4>
        </div>
        <mec-program-radio
          class="program-step-container__item card"
          *ngIf="item.type === 'radio'"
          id="node{{ i }}"
          (onSelectItemChange)="validateNode(i, $event, true)"
          [programNode]="item"
        ></mec-program-radio>
        <mec-program-checkbox
          class="program-step-container__item card"
          *ngIf="item.type === 'checkbox'"
          id="node{{ i }}"
          (onSelectItemChange)="validateNode(i, $event, true)"
          [programNode]="item"
        >
        </mec-program-checkbox>
        <mec-program-open-text
          class="program-step-container__item card"
          *ngIf="item.type === 'openText'"
          id="node{{ i }}"
          (onSelectItemChange)="
            validateNode(i, $event.programLog, $event.valid)
          "
          [programNode]="item"
        ></mec-program-open-text>
        <mec-program-output
          class="program-step-container__item card"
          *ngIf="item.type === 'output'"
          id="node{{ i }}"
          [programNode]="item"
        ></mec-program-output>
        <mec-program-eqvas
          class="program-step-container__item card"
          *ngIf="item.type === 'eqvas'"
          (onSelectItemChange)="validateNode(i, $event, true)"
          id="node{{ i }}"
          [programNode]="item"
        ></mec-program-eqvas>
        <mec-program-matrix-radio
          class="program-step-container__item card"
          *ngIf="item.type === 'matrix-radio'"
          (onSelectItemChange)="validateNode(i, $event, true)"
          id="node{{ i }}"
          [programNode]="item"
        ></mec-program-matrix-radio>
      </ng-container>
    </div>
  </div>
</div>
