<div
  class="social-publication-list"
  *ngIf="!isLoading || socialThreadsList?.threads?.length"
  [loadMore]="socialThreadsList.pagination.loadMore"
  [isLoadingMore]="isLoading"
  (medInfiniteScroll)="getThreads()"
>
  <mec-social-publication-item
    (socialThreadDeleteChange)="deleteThread(item)"
    *ngFor="let item of socialThreadsList.threads"
    [socialThread]="item"
  ></mec-social-publication-item>

  <h4 *ngIf="!socialThreadsList?.threads.length && !isLoading">
    {{ 'NO_RESULTS_FOUND' | translate }}
  </h4>
</div>

<!-- Loader Skeleton start -->
<ng-container *ngIf="isLoading">
  <div class="social-publication-list">
    <div
      *ngFor="let item of [0, 1, 2]"
      [medSkeletonLoading]="true"
      style="height: 300px; width: 100%"
    ></div>
  </div>
</ng-container>
<!-- Loader Skeleton end -->
