import { Subject } from 'rxjs';
import { CustomHubConfiguration } from '../../common/models/hub/custom-hub-configuration';
import { HubInstance } from '../../common/models/hub/hub-instance';

export abstract class Hub {
  public abstract _getUrlHub(): string;

  public abstract basePath(): string;

  public abstract host(): string;

  public abstract hub(): string;

  public abstract hubHeaders(): any;

  public abstract onHubChange: Subject<void>;

  public abstract getComponents(): any;

  public abstract setBasePath(basePath: string): void;

  public abstract setFixedHub(hub: string): void;

  public abstract setHub(): void;

  public abstract setHubHeaders(): void;

  public abstract getHubConfigurationByProp(prop: any): any;

  public abstract setHubConfiguration(
    configuration: CustomHubConfiguration
  ): void;

  public abstract getHubConfiguration?(): CustomHubConfiguration;

  public abstract getSetting(type: string): string | null;

  public abstract setHubStyles?(configuration: CustomHubConfiguration): void;
}
