<div *ngIf="breadcrumbs?.length" class="breadcrumbs page-content">
  <div class="breadcrumbs__list">
    <div
      (click)="goToRoute(breadcrumb.fullPath)"
      [ngClass]="{ 'breadcrumbs__item--active': isLast }"
      *ngFor="let breadcrumb of breadcrumbs; let isLast = last"
      class="breadcrumbs__item"
    >
      <span class="breadcrumbs__title" [title]="breadcrumb.title | translate">
        {{ breadcrumb.title | translate }}
      </span>
      <span class="breadcrumbs__separator" *ngIf="!isLast"> | </span>
    </div>
  </div>
</div>
