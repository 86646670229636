import { DirectionOptions } from '../../enums/common/direction-options';
import { PaginationOptions } from '../common/pagination-options';
import { SearchResult } from '../common/search/search-result';

export class SavedItemsList {
  public savedItems: SearchResult[] = [];
  public numberOfPages: number;
  public total: number;
  pagination: PaginationOptions = {
    direction: DirectionOptions.desc,
    limit: 3,
    page: 0,
  };
}
