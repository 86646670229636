<div class="file-upload-list">
  <div *ngFor="let file of files" class="file-upload-list__file">
    <mat-spinner *ngIf="file.isLoading"></mat-spinner>
    <ng-container *ngIf="!file.isLoading">
      <button
        (click)="removeFile(file)"
        class="file-upload-list__close-btn btn btn--sm btn--icon-only"
      >
        <fa-icon [icon]="removeIcon"></fa-icon>
      </button>
      <div class="file-upload-list__overlay"></div>
      <img
        *ngIf="file.type === fileTypes.IMAGE"
        class="file-upload-list__img"
        [src]="file.imageBlob"
      />
      <fa-icon
        *ngIf="file.type === fileTypes.VIDEO"
        [icon]="videoIcon"
        class="file-upload-list__icon-video"
      ></fa-icon>
      <fa-icon
        *ngIf="file.type !== fileTypes.IMAGE && file.type !== fileTypes.VIDEO"
        [icon]="documentIcon"
        class="file-upload-list__icon-video"
      ></fa-icon>
    </ng-container>
  </div>
</div>
