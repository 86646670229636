import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialNetworkParticipantsType } from '../../../common/enums/social-network/social-networks-participants-type';
import { ToastService } from '../../../services/toast.service';
import { SocialParticipantsSearchComponent } from '../social-participants-search/social-participants-search.component';
import { SocialParticipantsItemComponent } from '../social-participants-item/social-participants-item.component';
import {
  SocialNetworkParticipant,
  SocialNetworkParticipantsList,
} from '../../../common/models/social-network/social-network-participants';
import { ApiProfileV3Service } from '../../../services/api/api-profile-v3.service';
import { PaginationHelper } from '../../../common/helpers/pagination.helper';
import { SocialNetWorkService } from '../../../services/social-network.service';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mec-social-participants-list',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SocialParticipantsSearchComponent,
    SocialParticipantsItemComponent,
    SkeletonDirective,
  ],
  providers: [ApiProfileV3Service],
  templateUrl: './social-participants-list.component.html',
  styleUrls: ['./social-participants-list.component.scss'],
})
export class SocialParticipantsListComponent implements OnChanges {
  @Input() public participantsType: SocialNetworkParticipantsType;
  @Input() public profileId: string | number;
  @Input() socialNetworkIds: string;

  public participantsList: SocialNetworkParticipantsList =
    new SocialNetworkParticipantsList();

  public isLoading: boolean;
  public participantsSubscription: Subscription;

  constructor(
    private _toastService: ToastService,
    private _apiProfileV3Service: ApiProfileV3Service,
    private _socialNetworkService: SocialNetWorkService
  ) {
    // this._socialNetworkService.socialProfileChangeSubject.subscribe(
    //   (profileId) => {
    //     console.log(this.participantsType);
    //     this.profileId = profileId;
    //     this.getParticipants(null, true);
    //   }
    // );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['profileId']) {
      this.getParticipants(null, true);
    }
  }

  public async getParticipants(
    search?: string,
    reset?: boolean
  ): Promise<void> {
    this.isLoading = true;

    if (this.participantsSubscription) {
      this.participantsSubscription.unsubscribe();
    }

    if (reset) {
      this.participantsList = new SocialNetworkParticipantsList();
    }

    const hasSearchFilter = search !== undefined;
    if (hasSearchFilter) {
      this.participantsList = new SocialNetworkParticipantsList();
    }

    try {
      this.participantsSubscription = this._apiProfileV3Service
        .getParticipants(
          this.profileId as number,
          this.participantsType,
          this.participantsList.pagination,
          this.socialNetworkIds,
          search
        )
        .subscribe((participantsResult) => {
          this._apiProfileV3Service.getParticipants(
            this.profileId as number,
            this.participantsType,
            this.participantsList.pagination,
            this.socialNetworkIds
          );

          this.participantsList =
            PaginationHelper.newHandlePagination<SocialNetworkParticipant>(
              this.participantsList,
              'participants',
              participantsResult
            );

          this.isLoading = false;
        });
    } catch (error) {
      this._toastService.showError(error);
      this.isLoading = false;
    }
  }
}
