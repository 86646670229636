import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ApiCpdManagementService } from '../../../services/api/api-cpd-management.service';
import { ArticleType } from '../../../common/enums/article/article-type';
import { CpdManagementList } from '../../../common/models/cpd/cpd-management-list';
import { FooterComponent } from '../../common/footer/footer.component';
import { InfiniteScrollDirective } from '../../../directives/infinite-scroll.directive';
import { LibraryItemComponent } from '../../library/library-item/library-item.component';
import { NewsCardComponent } from '../../news/news-card/news-card.component';
import { PaginationHelper } from '../../../common/helpers/pagination.helper';
import { SettingsService } from '../../../services/settings.service';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { ToastService } from '../../../services/toast.service';
import { SearchCardComponent } from '../../common/search/search-card/search-card.component';
import { SearchResult } from '../../../common/models/common/search/search-result';

@Component({
  selector: 'mec-cpd-management-card-list',
  standalone: true,
  imports: [
    CommonModule,
    FooterComponent,
    InfiniteScrollDirective,
    LibraryItemComponent,
    NewsCardComponent,
    SearchCardComponent,
    SkeletonDirective,
    TranslateModule,
  ],
  templateUrl: './cpd-management-card-list.component.html',
  styleUrls: ['./cpd-management-card-list.component.scss'],
})
export class CpdCardListComponent implements OnInit {
  @Input() public limit: number;
  @Input() public excludes: number;
  @Input() public maxPage: number;

  public cpdManagementList: CpdManagementList = new CpdManagementList();
  public isLoading = true;
  public disableCpd: boolean;

  public contentTypeEvents = ArticleType.EVENTS;
  public contentTypeNews = ArticleType.NEWS;
  public contentTypeCourse = ArticleType.COURSE;
  public contentTypeLibraryFile = ArticleType.LIBRARY_FILE;

  constructor(
    private _apiCpdManagementService: ApiCpdManagementService,
    private _settingsService: SettingsService,
    private _toastService: ToastService
  ) {}

  public async ngOnInit(): Promise<void> {
    if (this.limit) {
      this.cpdManagementList.pagination.limit = this.limit;
    }
    this.cpdManagementList.pagination.maxPage = this.maxPage;
    this.disableCpd = await this._settingsService._getPermissionValue(
      'DISABLE_CPD'
    );

    if (this.disableCpd) {
      return;
    }

    await this.getCpdManagementList();
  }

  public async getCpdManagementList(reset?: boolean): Promise<void> {
    this.isLoading = true;
    this.cpdManagementList.pagination.isLoadingMore = true;

    if (reset) {
      this.cpdManagementList = new CpdManagementList();
    }

    try {
      const itemsToAdd = await this._apiCpdManagementService.getCertificatesMe(
        this.cpdManagementList.pagination
      );
      this.cpdManagementList =
        PaginationHelper.newHandlePagination<SearchResult>(
          this.cpdManagementList,
          'cpdManagement',
          itemsToAdd
        );
    } catch (error: any) {
      this._toastService.showError(error);
    }

    this.cpdManagementList.pagination.isLoadingMore = false;
    this.isLoading = false;
  }
}
