<div class="sidebar">
  <div class="card sidebar__profile-card" *ngIf="isLogged">
    <div class="sidebar__profile">
      <div
        *ngIf="!isLoadingAvatar"
        [ngClass]="{ 'sidebar__avatar-container--hidden-img': hiddenAvatar }"
        class="sidebar__avatar-container"
      >
        <img
          class="sidebar__avatar"
          *ngIf="sideBarData?.imageAvatarUrl && !hiddenAvatar"
          (error)="imageAvatarError()"
          (load)="imageAvatarLoaded()"
          src="{{ sideBarData.imageAvatarUrl }}"
        />
        <fa-icon
          *ngIf="!sideBarData?.imageAvatarUrl || hiddenAvatar"
          [icon]="userIcon"
        ></fa-icon>
      </div>
      <!-- Start Skeleton loader -->
      <div
        class="sidebar__avatar-container"
        *ngIf="isLoadingAvatar"
        style="border-radius: 50%"
        [medSkeletonLoading]="true"
      ></div>
      <!-- End Skeleton loader -->

      <ng-container *ngIf="!isLoadingInstance">
        <h4>{{ sideBarData.name }}</h4>
        <span>{{ sideBarData.email }}</span>
      </ng-container>

      <!-- Start Skeleton loader -->
      <h4
        *ngIf="isLoadingInstance"
        [medSkeletonLoading]="true"
        style="width: 100px"
      ></h4>
      <span
        *ngIf="isLoadingInstance"
        [medSkeletonLoading]="true"
        style="width: 120px"
      ></span>
      <!-- End Skeleton loader -->
    </div>

    <div
      *ngIf="defaultPath"
      class="sidebar__menu-item"
      (click)="goToPage(defaultPath.slug)"
    >
      <span> {{ defaultPath.name | translate }} </span>
      <fa-icon [icon]="feedIcon"></fa-icon>
    </div>
  </div>

  <div class="card sidebar__menus-card">
    <div *ngIf="isMobile && headerMenuItems" class="sidebar__menu-container">
      <div *ngFor="let subMenu of headerMenuItems">
        <div
          (click)="goToPage(subMenu.targetSlug)"
          class="sidebar__menu-item sidebar__submenu-title"
        >
          <span class="sidebar__submenu-title">
            {{ subMenu?.content?.title | translate }}
          </span>
          <fa-icon *ngIf="subMenu.icon" [icon]="subMenu.icon"></fa-icon>
        </div>
      </div>
    </div>

    <ng-container *ngFor="let menu of sideBarData.menuItems">
      <div *ngIf="menu.showItem" class="sidebar__menu-container">
        <div class="sidebar__menu-title">
          <span> {{ menu.name | translate }} </span>
        </div>
        <div *ngFor="let subMenu of menu.items">
          <div
            *ngIf="subMenu.showItem"
            (click)="goToPage(subMenu.slug)"
            class="sidebar__menu-item sidebar__submenu-title"
          >
            <span class="sidebar__submenu-title">
              {{ subMenu.name | translate }}
            </span>
            <fa-icon *ngIf="subMenu.icon" [icon]="subMenu.icon"></fa-icon>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="sidebar__actions" *ngIf="!isLogged && isMobile">
    <button class="btn" (click)="goToPage('sign-up')">
      {{ 'SIGN_UP' | translate }}
    </button>
    <button class="btn btn--outline" (click)="goToPage('login')">
      {{ 'LOGIN' | translate }}
    </button>
  </div>
</div>
