import { EventPrivacy } from '../../enums/event/event-privacy';
import { EventStatus } from '../../enums/event/event-status';
import { ChatRoom } from '../common/chat-room';

export class EventWebinar {
  public chatRoom: ChatRoom;
  public clientId: string;
  public description: string;
  public duration: number;
  public externalReferenceId: string;
  public organizerAppId: string;
  public organizerEmail: string;
  public organizerName: string;
  public organizerUrl: string;
  public privacy: EventPrivacy;
  public startAt: Date;
  public status: EventStatus;
  public token: string;
  public topic: string;
}
