import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { environment } from '../../environments';
import { HttpClient } from '@angular/common/http';
import { Evaluation, PaginationOptions } from '../../common';
import { Observable } from 'rxjs';
import { Hub } from '../abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class ApiEvaluationsService extends ApiBaseService {
  protected override apiBaseUrl = environment.campusV2;
  protected override routePath = `hubs/${this._hubService.hub()}/evaluations`; // : string;

  constructor(private _hubService: Hub, private _httpClient: HttpClient) {
    super(_httpClient);

    // this._hubService.onHubChange.subscribe(() => {
    //   this.routePath = `hubs/${this._hubService.hub()}/evaluations`;
    // });
  }

  public getEvaluations(
    status: string,
    paginationOptions: PaginationOptions,
    searchValue?: string
  ): Observable<Evaluation[]> {
    let path = `?direction=${paginationOptions.direction}&limit=${paginationOptions.limit}&page=${paginationOptions.page}&status=${status}`;

    if (searchValue) {
      path = `${path}&q=${searchValue}`;
    }
    if (paginationOptions.path) {
      path = `${path}&path=${paginationOptions.path}`;
    }

    return super.getObservable<Evaluation[]>(path);
  }
}
