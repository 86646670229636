import { SearchResult } from '../common/search/search-result';
import { CustomForm } from '../forms/custom-form';

export class SurveyEntity {
  public content: SearchResult;
  public createdAt: Date;
  public form?: CustomForm;
  public hub: number;
  public id: string;
  public profile: number;
  public status: 'WAITING' | 'ANSWERED' | 'CANCELED';
  public updatedAt: Date;
}
