<div class="alert">
  <div class="header">
    <ng-container [ngSwitch]="data.type">
      <fa-icon
        class="header__icon header__icon--{{ data.type }}"
        *ngSwitchCase="'confirmation'"
        [icon]="helpIcon"
      ></fa-icon>
      <fa-icon
        class="header__icon header__icon--{{ data.type }}"
        *ngSwitchCase="'alert'"
        [icon]="confirmIcon"
      ></fa-icon>
      <fa-icon
        class="header__icon header__icon--{{ data.type }}"
        *ngSwitchCase="'error'"
        [icon]="errorIcon"
      ></fa-icon>
    </ng-container>
    <span [class]="'header__title header__title--' + data.type">{{
      data.title
    }}</span>
  </div>
  <p class="content" [innerHTML]="data.message"></p>
  <div class="footer">
    <ng-container *ngIf="data.type === 'confirmation'">
      <button
        class="btn btn--sm btn--outline btn--error"
        (click)="_dialogRef.close(false)"
      >
        {{ data.negativeAction }}
      </button>
      <button class="btn btn--sm btn--primary" (click)="_dialogRef.close(true)">
        {{ data.positiveAction }}
      </button>
    </ng-container>
    <ng-container *ngIf="data.type !== 'confirmation'">
      <button class="btn btn--sm btn--primary" (click)="_dialogRef.close()">
        {{ data.neutralAction }}
      </button>
    </ng-container>
  </div>
</div>
