<div (click)="openReactionModal()" class="social-reactions-info">
  <ng-container *ngFor="let reaction of givenReactions">
    <div
      *ngIf="reaction.count > 0"
      class="social-reactions-info__reaction-container"
    >
      <div
        class="social-reactions-info__container-icon"
        [ngClass]="{
        'social-reactions-info__container-icon--like':
        reaction.reaction === reactionsTypes.LIKE,
      'social-reactions-info__container-icon--love':
        reaction.reaction === reactionsTypes.LOVE,
      'social-reactions-info__container-icon--genius':
        reaction.reaction === reactionsTypes.GENIUS,
      'social-reactions-info__container-icon--support':
        reaction.reaction === reactionsTypes.SUPPORT,
      'social-reactions-info__container-icon--funny':
        reaction.reaction === reactionsTypes.FUNNY,
    }"
      >
        <fa-icon
          class="social-reactions-info__icon"
          [icon]="reactionsInfo.reactions[reaction.reaction].icon"
        ></fa-icon>
      </div>
      <span class="social-reactions-info__number">{{ reaction.count }}</span>
    </div>
  </ng-container>
</div>
