import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';
import { MatRadioModule } from '@angular/material/radio';
import { ProgramAnswer } from '../../../common/models/care-monitor/program-answer';
import { ProgramLog } from '@meplis/common';

@Component({
  selector: 'mec-program-radio',
  standalone: true,
  imports: [CommonModule, MatRadioModule],
  templateUrl: './program-radio.component.html',
  styleUrls: ['./program-radio.component.scss'],
})
export class ProgramRadioComponent implements OnChanges {
  @Input() public programNode: ProgramNode;
  @Output() public onSelectItemChange = new EventEmitter<ProgramLog>();

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.programNode) {
    }
  }

  public selectItem(value: string): void {
    const log = {
      to: this.programNode.id,
      at: new Date().toISOString(),
      results: [{ name: this.programNode.name, value: value }],
    };

    this.onSelectItemChange.emit(log);
  }
}
