<ng-container *ngIf="!disableSavedItems">
  <div class="saved-items-card-list">
    <div
      class="saved-items-card-list__list-items"
      [loadMore]="savedItemsList?.pagination.loadMore"
      [isLoadingMore]="savedItemsList.pagination.isLoadingMore"
      (medInfiniteScroll)="getSavedItemsList()"
    >
      <mec-search-card
        [searchResult]="savedItem"
        *ngFor="let savedItem of savedItemsList?.savedItems"
      ></mec-search-card>

      <h2
        class="saved-items-card-list__no-results"
        *ngIf="!isLoading && !savedItemsList?.savedItems?.length"
      >
        {{ 'NO_RESULTS_FOUND' | translate }}
      </h2>
      <!-- Loader Skeleton start -->
      <ng-container *ngIf="savedItemsList?.pagination?.isLoadingMore">
        <div
          *ngFor="let item of [0, 1, 2]"
          [medSkeletonLoading]="true"
          style="height: 300px; width: 100%"
        ></div>
      </ng-container>
      <!-- Loader Skeleton end -->
    </div>
  </div>
</ng-container>
