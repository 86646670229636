import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments';
import { ApiBaseService } from './api-base.service';
import {
  CertificateEntity,
  Course,
  CoursePage,
  LibraryItem,
  PaginationOptions,
  Step,
} from '../../common';
import { Hub } from '../abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class ApiCoursesV2Service extends ApiBaseService {
  protected apiBaseUrl = environment.campusV2;
  protected routePath = `hubs/${this._hubService.hub()}/courses`;
  private headers = {
    headers: this._hubService.hubHeaders(),
  };

  constructor(private _hubService: Hub, private _httpClient: HttpClient) {
    super(_httpClient);

    // this._hubService.onHubChange.subscribe(() => {
    //   this.routePath = `hubs/${this._hubService.hub()}/courses`;
    // });
  }

  public getPages(courseSlug: string): Promise<CoursePage[]> {
    const path = `${courseSlug}/pages?limit=20&type=PAGE`;
    return super.get<CoursePage[]>(path);
  }

  public getCertificateByStep(
    stepId: number,
    courseIdOrSlug: number | string
  ): Promise<CertificateEntity> {
    const path = `${courseIdOrSlug}/steps/${stepId}/certificate`;
    return super.get<CertificateEntity>(path);
  }

  public getLibraryByStep(
    stepId: number,
    courseSlug: string,
    paginationOptions: PaginationOptions
  ): Promise<LibraryItem[]> {
    const path = `${courseSlug}/steps/${stepId}/libraries?direction=${paginationOptions.direction}&limit=${paginationOptions.limit}&orderby=${paginationOptions.orderBy}&page=${paginationOptions.page}`;
    return super.get<LibraryItem[]>(path);
  }

  public getCourseById(courseId: number): Promise<Course> {
    const path = `${courseId}`;
    return super.get<Course>(path);
  }

  public getCourseBySlug(courseSlug: string): Promise<Course> {
    const path = `${courseSlug}`;
    return super.get<Course>(path);
  }

  public issueCertificateForStep(
    stepId: number,
    courseIdOrSlug: number | string
  ): Promise<CertificateEntity> {
    const path = `${courseIdOrSlug}/steps/${stepId}/certificate`;
    return super.post<CertificateEntity>(path, {}, this.headers);
  }
}
