import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments';
import { ApiBaseService } from './api-base.service';
import {
  PaginationOptions,
  SocialNetWorkThread,
  SocialNetworkReaction,
  SocialNetworkReactions,
} from '../../common';
import { Hub } from '../abstract/hub.abstract';

@Injectable()
export class ApiSocialNetworksV1Service extends ApiBaseService {
  protected apiBaseUrl = environment.socialV1;
  protected routePath = ``;
  private _headers = this._hubService.hubHeaders();

  constructor(private _hubService: Hub, private _httpClient: HttpClient) {
    super(_httpClient);
  }

  public getReactions(
    instanceId: string,
    threadId: string,
    reaction?: SocialNetworkReactions
  ): Promise<SocialNetworkReaction[]> {
    const path = `instances/${instanceId}/threads/${threadId}/reactions`;

    const params = {};

    if (reaction) {
      Object.assign(params, { type: reaction });
    }

    return super.get<SocialNetworkReaction[]>(path, { params: params });
  }

  public getCommentsFrom(
    instanceId: string,
    threadId: string,
    paginationOptions: PaginationOptions
  ): Promise<SocialNetWorkThread[]> {
    const path = `instances/${instanceId}/threads/${threadId}/threads`;

    const params = {};

    Object.assign(params, { limit: paginationOptions.limit });
    Object.assign(params, { page: paginationOptions.page });

    return super.get<SocialNetWorkThread[]>(path, { params: params });
  }

  public putReactionPublication(
    instanceId: string,
    threadId: string,
    reaction: SocialNetworkReactions
  ): Promise<void> {
    const path = `instances/${instanceId}/threads/${threadId}/reactions/${reaction.toLowerCase()}`;

    return super.put<void>(path);
  }

  public removeReactionPublication(
    instanceId: string,
    threadId: string,
    reaction: SocialNetworkReactions
  ): Promise<void> {
    const path = `instances/${instanceId}/threads/${threadId}/reactions/${reaction.toLowerCase()}`;

    return super.delete<void>(path);
  }

  public deletePublication(
    socialThreadId: string,
    socialInstanceId: string
  ): Promise<void> {
    const path = `instances/${socialInstanceId}/threads/${socialThreadId}`;
    return super.delete<void>(path);
  }

  public saveComment(
    socialThread: SocialNetWorkThread,
    socialInstanceId: string,
    publicationId: string
  ): Promise<SocialNetWorkThread> {
    const path = `instances/${socialInstanceId}/threads/${publicationId}/threads`;
    return super.post<SocialNetWorkThread>(path, [socialThread]);
  }

  public savePublication(
    socialThread: SocialNetWorkThread,
    socialInstanceId: string
  ): Promise<void> {
    const path = `instances/${socialInstanceId}/threads`;
    return super.post<void>(path, [socialThread]);
  }

  public savePublicationRepost(
    socialThread: SocialNetWorkThread,
    socialThreadRepostId: string,
    socialInstanceId: string
  ): Promise<void> {
    const path = `instances/${socialInstanceId}/threads/${socialThreadRepostId}/repost`;
    return super.post<void>(path, [socialThread]);
  }

  public getPublications(
    profileId: number | string,
    paginationOptions: PaginationOptions,
    socialInstanceIds: string
  ): Promise<SocialNetWorkThread[]> {
    let path = ``;

    const params = {};

    if (profileId) {
      path = `participants/${profileId}/threads`;
      Object.assign(params, { instances: socialInstanceIds });
    } else {
      path = `instances/${socialInstanceIds}/threads/global`;
    }

    Object.assign(params, { limit: paginationOptions.limit });
    Object.assign(params, { page: paginationOptions.page });
    Object.assign(params, { direction: paginationOptions.direction });
    Object.assign(params, { orderBy: paginationOptions.orderBy });

    return super.get<SocialNetWorkThread[]>(path, { params: params });
  }
}
