import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faCircle,
  faCircleCheck,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle as startCircle } from '@fortawesome/free-regular-svg-icons';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'mec-program-steps',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
  templateUrl: './program-steps.component.html',
  styleUrls: ['./program-steps.component.scss'],
})
export class ProgramStepsComponent implements OnChanges {
  @Input() public steps: Array<ProgramNode[]>;
  @Input() public stepDescription: string;
  @Input() public currentStepIndex: number;

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentStepIndex']?.currentValue) {
    }
  }

  public checkIcon = faCircleCheck;
  public listIcon = faList;
  public startIcon = startCircle;
  public endIcon = faCircle;
}
