import { OwnContentType } from './../../enums/common/own-content-type';
import { ArticleType } from '../../enums/article/article-type';
import { DirectionOptions } from '../../enums/common/direction-options';
import { EventFormat } from '../../enums/event/event-format';
import { EventStatus } from '../../enums/event/event-status';
import { EventType } from '../../enums/event/event-type';
import { ContentEntity } from '../common/content-entity';
import { PaginationOptions } from '../common/pagination-options';
import { CpdConfigs } from '../cpd/cpd-configs';
import { CpdCredit } from '../cpd/cpd-credit';
import { Tag } from '../tags/tag';
import { LocationEntity } from './event-location';
import { ArticleStatus } from '../../enums/article/article-status';

export class SummaryEvent {
  public id: number;
  public defaultImage?: number;
  public defaultImageHash?: string;
  public slug: string;
  public tags: Tag[];
  public languages: string[];
  public articleStatus: ArticleStatus;
  public content: ContentEntity;
  public type: ArticleType;
  public ownContentType: OwnContentType;
  public cpdConfigs?: CpdConfigs[];
  public cpdCredit?: CpdCredit;

  // Event specific
  public endsAt?: Date;
  public startsAt?: Date;
  public eventType?: EventType;
  public eventStatus?: EventStatus;
  public eventFormat?: EventFormat;
  public internalWebinarId?: string; // todo(mleon): required if eventType is 'WEBINAR'
  public address?: string; // todo(mleon): required if eventType is 'PRESENTIAL'
  public location?: LocationEntity;

  // todo(mleon): check implementation of the below properties
  public title?: string;
}

export class EventsList {
  public events: SummaryEvent[] = [];
  public numberOfPages: number;
  public total: number;
  pagination: PaginationOptions = {
    direction: DirectionOptions.desc,
    limit: 10,
    page: 0,
  };
}
