import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SocialNetworkParticipant } from '../../../common/models/social-network/social-network-participants';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { ApiProfileV3Service } from '../../../services/api/api-profile-v3.service';
import { SocialNetWorkService } from '../../../services/social-network.service';
import { CommonService } from '../../../services/common.service';

@Component({
  selector: 'mec-social-participants-item',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  providers: [ApiProfileV3Service],
  templateUrl: './social-participants-item.component.html',
  styleUrls: ['./social-participants-item.component.scss'],
})
export class SocialParticipantsItemComponent implements OnChanges, OnInit {
  @Input() participant: SocialNetworkParticipant;
  @Input() public socialNetworkIds: string;

  public avatarUrl: string;

  constructor(
    private _apiProfileV3Service: ApiProfileV3Service,
    private _socialNetworkService: SocialNetWorkService,
    private _commonService: CommonService
  ) {}

  public async ngOnInit(): Promise<void> {}

  public ngOnChanges(): void {
    if (this.participant) {
      this.avatarUrl = CommonHelper.getAvatarImage(this.participant.id);
    }
  }

  public goToProfile(): void {
    this._commonService.navigate([
      'participants',
      'profile',
      this.participant.id as unknown as string,
    ]);
  }

  public async followParticipant(): Promise<void> {
    try {
      this.participant.following = true;

      await this._apiProfileV3Service.followParticipant(
        this.participant.id,
        this.socialNetworkIds
      );
    } catch (error) {}
  }

  public async unfollowParticipant(): Promise<void> {
    try {
      this.participant.following = false;

      await this._apiProfileV3Service.unfollowParticipant(
        this.participant.id,
        this.socialNetworkIds
      );
    } catch (error) {}
  }
}
