import { LanguageFilter } from '../models/common/language/language-filter';

export const LanguagesFilter: LanguageFilter[] = [
  {
    name: 'LANGUAGE.ENGLISH',
    countryName: 'LANGUAGE.COUNTRY.ENGLAND',
    isSelected: true,
    isDisabled: true,
    language: 'en',
    isGlobal: true,
    flagCode: 'gb',
  },
  {
    name: 'LANGUAGE.GERMAN',
    countryName: 'LANGUAGE.COUNTRY.GERMANY',
    language: 'de',
    isGlobal: true,
    flagCode: 'de',
  },
  {
    name: 'LANGUAGE.SPANISH',
    countryName: 'LANGUAGE.COUNTRY.SPAIN',
    language: 'es',
    isGlobal: true,
    flagCode: 'es',
  },
  {
    name: 'LANGUAGE.FRENCH',
    countryName: 'LANGUAGE.COUNTRY.FRANCE',
    language: 'fr',
    isGlobal: true,
    flagCode: 'fr',
  },
  {
    name: 'LANGUAGE.ITALIAN',
    countryName: 'LANGUAGE.COUNTRY.ITALY',
    isSelected: false,
    language: 'it',
    isGlobal: true,
    flagCode: 'it',
  },
  {
    name: 'LANGUAGE.JAPANESE',
    countryName: 'LANGUAGE.COUNTRY.JAPAN',
    language: 'ja',
    isGlobal: true,
    flagCode: 'jp',
  },
  {
    name: 'LANGUAGE.KOREAN',
    countryName: 'LANGUAGE.COUNTRY.KOREA',
    language: 'ko',
    isGlobal: true,
    flagCode: 'kr',
  },
  {
    name: 'LANGUAGE.PORTUGUESE',
    countryName: 'LANGUAGE.COUNTRY.PORTUGAL',
    language: 'pt',
    isGlobal: true,
    flagCode: 'pt',
  },
  {
    name: 'LANGUAGE.BRAZILIAN_PORTUGUESE',
    countryName: 'LANGUAGE.COUNTRY.BRAZIL',
    language: 'pt-br',
    isGlobal: true,
    flagCode: 'br',
  },
  {
    name: 'LANGUAGE.CHINESE',
    countryName: 'LANGUAGE.COUNTRY.CHINA',
    language: 'zh',
    isGlobal: true,
    flagCode: 'cn',
  },
];
