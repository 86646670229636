import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastComponent } from '../components/common/toast/toast.component';
import { ToastController } from '@ionic/angular';
import { PlatformService } from './platform.service';
import {
  faCheck,
  faCircleInfo,
  faSquare,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { GTMService } from './google-tag-manager.service';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public static TIME = 3500;
  public static POSITION = 'top';
  public static readonly ACTION = 'Ok';

  private _toastController: any;

  constructor(
    public translateService: TranslateService,
    private _gtmService: GTMService,
    private _platformService: PlatformService
  ) {
    if (this._platformService.platform === 'mobile') {
      this._toastController = inject(ToastController);
      ToastService.POSITION = 'bottom';
    } else {
      this._toastController = inject(MatSnackBar);
    }
  }

  public async showError(
    message: any,
    action: string = ToastService.ACTION,
    time: number = ToastService.TIME,
    onActionClick?: () => void
  ): Promise<void> {
    try {
      message = this.translateService.instant(message);
    } catch (error) {
      message = this.translateService.instant('FEEDBACK_UNKNOWN_ERROR');
    }

    // mleon(): todo normalize with GOOGLE_ANALYTICS constants
    this._gtmService.tagEvent({
      event_action: 'show',
      event_category: 'app-toast',
      event_label: 'showError',
      value: message,
    });

    if (this._platformService.platform === 'mobile') {
      return this.showMobileError(message, time);
    }

    this._toastController.openFromComponent(ToastComponent, {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: time,
      panelClass: 'snackbar',
      data: {
        action,
        icon: faTriangleExclamation,
        message,
        messageType: 'error',
        onActionClick,
      },
    });
  }

  public async showSuccess(
    message?: string,
    action: string = ToastService.ACTION,
    time: number = ToastService.TIME
  ): Promise<void> {
    try {
      message = this.translateService.instant(message);
    } catch (error) {
      message = this.translateService.instant(
        'FEEDBACK_SAVED_DATA_ADD_SUCCESS'
      );
    }

    // mleon(): todo normalize with GOOGLE_ANALYTICS constants
    this._gtmService.tagEvent({
      event_action: 'show',
      event_category: 'app-toast',
      event_label: 'showSuccess',
      value: message,
    });

    if (this._platformService.platform === 'mobile') {
      return this.showMobileSuccess(message, time);
    }

    this._toastController.openFromComponent(ToastComponent, {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: time,
      panelClass: 'snackbar',
      data: {
        action,
        icon: faCheck,
        message,
        messageType: 'success',
      },
    });
  }

  public async showInfo(
    message: string,
    action: string = ToastService.ACTION,
    time: number = ToastService.TIME
  ): Promise<void> {
    message = this.translateService.instant(message);

    // mleon(): todo normalize with GOOGLE_ANALYTICS constants
    this._gtmService.tagEvent({
      event_action: 'show',
      event_category: 'app-toast',
      event_label: 'showInfo',
      value: message,
    });

    if (this._platformService.platform === 'mobile') {
      return this.showMobileInfo(message, time);
    }

    this._toastController.openFromComponent(ToastComponent, {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: time,
      panelClass: 'snackbar',
      data: {
        action,
        icon: faCircleInfo,
        message,
        messageType: 'info',
      },
    });
  }

  //mobile methods
  public async showMobileError(
    error: any,
    time: number = ToastService.TIME
  ): Promise<void> {
    if (!error) {
      error = 'FEEDBACK_UNKNOWN_ERROR';
    }

    const message = this.translateService.instant(error);

    // mleon(): todo normalize with GOOGLE_ANALYTICS constants
    this._gtmService.tagEvent({
      event_action: 'show',
      event_category: 'app-toast',
      event_label: 'showMobileError',
      value: error,
    });

    const toast = await this._toastController.create({
      message: message,
      duration: time,
      position: ToastService.POSITION,
      icon: faSquare, // 'alert-circle-outline',
      color: 'warning',
    });

    await toast.present();
  }

  public async showMobileSuccess(
    message?: string,
    time: number = ToastService.TIME
  ): Promise<void> {
    if (!message) {
      message = 'FEEDBACK_SAVED_ITEMS_ADD_SUCCESS';
    }

    const successMessage = this.translateService.instant(message);

    // mleon(): todo normalize with GOOGLE_ANALYTICS constants
    this._gtmService.tagEvent({
      event_action: 'show',
      event_category: 'app-toast',
      event_label: 'showMobileSuccess',
      value: message,
    });

    const toast = await this._toastController.create({
      message: successMessage,
      duration: time,
      position: ToastService.POSITION,
      icon: faSquare, // 'checkmark-circle-outline',
      color: 'success',
    });

    await toast.present();
  }

  public async showMobileInfo(
    message: string,
    time: number = ToastService.TIME
  ): Promise<void> {
    // mleon(): todo normalize with GOOGLE_ANALYTICS constants
    this._gtmService.tagEvent({
      event_action: 'show',
      event_category: 'app-toast',
      event_label: 'showMobileInfo',
      value: message,
    });

    const toast = await this._toastController.create({
      message: message,
      duration: time,
      position: ToastService.POSITION,
      icon: faSquare, // 'information-circle-outline',
    });

    await toast.present();
  }
}
