import { ContentEntity } from '../common/content-entity';
import { ScoreSystemType } from '../course/score-system-type';
import { SettingsEntity } from '../settings/settings-entity';
import { Tag } from '../tags/tag';

export declare class HubEntity {
  public id: number;
  public slug: string;

  // Subscription
  public subscriptionId: number;

  // Roles
  public role?: [];

  // Media
  public defaultImage?: number;
  public defaultImageInverted?: number;
  public faviconImage?: number;
  public mobileBigSizeImage?: number;
  public webBigSizeImage?: number;

  // Content
  public content: ContentEntity;
  public legalName: string;
  public name: string;
  public tags: Tag[];

  // Booleans and Settings
  public activated?: boolean;
  public administrator?: boolean;
  public disableEvents?: boolean;
  public disableLibrary?: boolean;
  public disableNews?: boolean;
  public online: boolean;
  public savedItem: boolean;
  public scoreSystemType?: ScoreSystemType;
  public settings?: SettingsEntity[];
  public showFeaturesOnDashboard?: boolean;
  public skipDashboard?: boolean;
  public trackNavigation?: boolean;
}

export const HubEntityDefault: HubEntity = {
  content: {
    content:
      '<p>Meplis Core is a platform for building digital health solutions.</p>',
    description:
      'Meplis Core is a platform for building digital health solutions.',
    excerpt: 'Meplis Core is a platform for building digital health solutions.',
    images: [],
    name: 'Meplis Core',
    title: 'Meplis Core',
  },
  id: 1,
  legalName: 'Meplis NV',
  name: 'Meplis Core',
  online: true,
  savedItem: false,
  slug: 'core',
  subscriptionId: 1,
  tags: [],
};
