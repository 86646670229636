import { Component, Inject, Input } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import { GOOGLE_ANALYTICS } from '../../../common/constants/google-analytics.constants';
import { GTMService } from '../../../services/google-tag-manager.service';
import { SearchResult } from '../../../common/models/common/search/search-result';

@Component({
  selector: 'mec-social-share',
  standalone: true,
  imports: [CommonModule, ShareButtonsModule, ShareIconsModule],
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss'],
})
export class SocialShareComponent {
  @Input() public item: SearchResult;
  @Input() public description: string;
  @Input() public url: string;

  constructor(
    @Inject(DOCUMENT) public document: any,
    private _gtmService: GTMService
  ) {
    this.url = document.location.href;
  }

  public sharedOpened(method: string) {
    // mleon(): todo normalize with GOOGLE_ANALYTICS constants
    this._gtmService.tagEvent({
      event_action: GOOGLE_ANALYTICS.events.share,
      event_category: 'share',
      event_label: 'share results list',
      method: method,
      content_type: this.item.type,
      content_id: this.item.slug || this.item.id.toString(),
      item_id: this.item.id.toString(),
    });
  }
}
