export class ModalOptions {
  public isDarkColor?: boolean;

  // Header
  public title?: string;
  public subTitle?: string;
  public closeable?: boolean;

  // Positive buttons
  public showPositiveButton?: boolean;
  public positiveButtonLabel?: string;
  public showSecondaryPositiveButton?: boolean;
  public secondaryPositiveButtonLabel?: string;

  // Negative buttons
  public showNegativeButton?: boolean;
  public negativeButtonLabel?: string;
  public showSecondaryNegativeButton?: boolean;
  public secondaryNegativeButtonLabel?: string;

  //Upload buttons
  public showUploadButton?: boolean;
  public hideFooter?: boolean;
  public hideHeader?: boolean;
}
