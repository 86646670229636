import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ProgramAnswer } from '../../../common/models/care-monitor/program-answer';
import { ProgramLog } from '@meplis/common';

@Component({
  selector: 'mec-program-checkbox',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule, FormsModule, ReactiveFormsModule],
  templateUrl: './program-checkbox.component.html',
  styleUrls: ['./program-checkbox.component.scss'],
})
export class ProgramCheckboxComponent implements OnDestroy {
  @Input() public programNode: ProgramNode;
  @Output() public onSelectItemChange = new EventEmitter<ProgramLog>();

  public formGroup: FormGroup;
  public subscription: Subscription;

  constructor(private _formBuilder: FormBuilder) {}

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.programNode) {
      this.formGroup = this._formBuilder.group({
        options: this._formBuilder.array(
          Object.keys(this.programNode?.values).map((key) => false)
        ),
      });

      const control = this.formGroup.controls['options'];
      this.subscription = control.valueChanges.subscribe((result) => {
        const valuesSelected = Object.keys(this.programNode.values)
          .map((node, index) => {
            if (result[index]) {
              return {
                name: this.programNode.name,
                value: this.programNode.values[index].value,
              };
            }
          })
          .filter((node) => node);

        this._selectItems(valuesSelected);
      });
    }
  }

  private _selectItems(programAnswers: ProgramAnswer[]): void {
    const log = {
      to: this.programNode.id,
      at: new Date().toISOString(),
      results: programAnswers,
    };

    this.onSelectItemChange.emit(log);
  }
}
