<textarea
  #input
  mecMention
  [(ngModel)]="bodyText"
  [mentionItemsList]="items"
  [isLoading]="isLoading"
  [mentionsBody]="bodyText"
  (mentionsBodyChange)="changeBodyText($event)"
  (searchChange)="loadParticipants($event)"
  [disabled]="!socialNetworkId"
  class="social-textarea field__input"
  placeholder="{{ 'SOCIALNETWORK.CREATE_ENTRY.PLACEHOLDER' | translate }}"
></textarea>
