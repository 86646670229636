import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiAuthenticationV1Service } from './api/api-authenticationV1.service';
import { AuthenticationHelper, LoginCredentials, LoginResult } from '../common';
import { CommonService } from './common.service';
import { PlatformService } from './platform.service';
import { MepStorage } from './abstract/mep-storage.abstract';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public onLoggedInOut = new Subject<void>();
  private _loggedIn: boolean;

  public token: string;

  constructor(
    private _apiAuthenticationV1Service: ApiAuthenticationV1Service,
    private _commonService: CommonService,
    private _platformService: PlatformService,
    private _location: Location,
    private _mepStorage: MepStorage
  ) {}

  public async handleAuthentication(): Promise<void> {
    const routeToNavigate =
      this._platformService.platform === 'mobile'
        ? 'login'
        : this._location.path();

    this.verifyIfIsLoggedIn(routeToNavigate);
  }

  public async verifyIfIsLoggedIn(routeToNavigate?: string): Promise<void> {
    if (!(await this._hasRefreshToken())) {
      this.logOut(routeToNavigate);
      return;
    }

    if (await this._isExpiredToken()) {
      try {
        await this._refreshToken();
      } catch (error) {
        this.logOut(routeToNavigate);
      }
      return;
    }

    this.token = await this._mepStorage.get('access_token');
    this.setLoggedIn(true);
  }

  public async login(
    credentials: LoginCredentials,
    redirect = true
  ): Promise<boolean> {
    const loginResult =
      await this._apiAuthenticationV1Service.loginWithPassword(credentials);
    this._setSession(loginResult);
    this.setLoggedIn(true);

    if (redirect) {
      await this._commonService.redirectDefaultRoute();
    }

    if (!AuthenticationHelper.verifyTokenIntegrity(loginResult.access_token)) {
      this.logOut('login');
      return false;
    }

    return true;
  }

  private async _hasRefreshToken(): Promise<boolean> {
    const refreshToken = await this._getRefreshToken();

    if (refreshToken && refreshToken.length > 0) {
      return true;
    }

    return false;
  }

  public setLoggedIn(isLogged: boolean): void {
    this._loggedIn = isLogged;
    this.onLoggedInOut.next();
  }

  public get isLogged(): boolean {
    return this._loggedIn;
  }

  public getLoggedInObservable(): Observable<void> {
    return this.onLoggedInOut.asObservable();
  }

  private async _isExpiredToken(): Promise<boolean> {
    const endDateExpiration = await this._mepStorage.get('end_date');
    if (!endDateExpiration) {
      return true;
    }

    const start = new Date().getTime();
    const end = new Date(endDateExpiration as any).getTime();

    return start > end;
  }

  private async _getRefreshToken(): Promise<string> {
    return await this._mepStorage.get('refresh_token');
  }

  public getToken(): string {
    return this.token;
  }

  private async _refreshToken(): Promise<void> {
    const refreshToken = this._getRefreshToken();

    if (!refreshToken) {
      return;
    }

    const refreshTokenResult =
      await this._apiAuthenticationV1Service.loginRefreshToken(
        refreshToken as any
      );
    this._setSession(refreshTokenResult);
  }

  private async _setSession(loginResult: LoginResult): Promise<void> {
    loginResult.end_date = new Date(
      new Date().getTime() + Number(loginResult.expires_in) * 1000
    ).toISOString();

    this._mepStorage.setItems(loginResult);
    this.token = loginResult.access_token;
  }

  public async logOut(
    routeToNavigate?: string,
    removeSession = true
  ): Promise<boolean> {
    // Even if the API errors, we still want to remove the session
    try {
      const token = (await this.getToken()) as string;
      if (token && removeSession) {
        await this._apiAuthenticationV1Service.logout(token);
      }
    } catch (error) {
      // mleon(): implement LogReportService
      console.log(error);
      return false;
    }

    this._removeSession(routeToNavigate);
    return true;
  }

  private async _removeSession(routeToNavigate?: string): Promise<void> {
    const loginResult = new LoginResult();
    const keys = Object.keys(loginResult);

    this._mepStorage.removeItems(keys);

    this.setLoggedIn(false);

    if (this._commonService.isPlatformBrowser) {
      setTimeout(() => {
        this._navigateTo(routeToNavigate);
      }, 200);
    } else {
      this._navigateTo(routeToNavigate);
    }
  }

  private _navigateTo(routeToNavigate?: string): void {
    if (routeToNavigate) {
      if (routeToNavigate.indexOf('/') > -1) {
        this._commonService.navigateByUrl(routeToNavigate);
      } else {
        this._commonService.navigate([routeToNavigate]);
      }
      return;
    }
    this._commonService.navigate([], { replaceUrl: true });
  }
}
