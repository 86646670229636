<div
  class="card event-card"
  (click)="gotoEvent(event.slug); $event.stopPropagation()"
>
  <div class="event-card__img-container">
    <div class="event-card__overlay"></div>

    <img
      [ngClass]="{ hidden: isLoadingImage || hiddenImage }"
      (error)="hiddenImage = true; isLoadingImage = false"
      (load)="isLoadingImage = false"
      class="event-card__cover"
      src="{{ imageUrl }}"
    />

    <!-- Skeleton image start -->
    <div
      [medSkeletonLoading]="true"
      *ngIf="isLoadingImage"
      class="event-card__cover"
    ></div>
    <!-- Skeleton image end -->
  </div>

  <div class="event-card__title-header">
    <h2 class="event-card__title" title="{{ event.content.title }}">
      {{ event.content.title }}
    </h2>
    <span class="course-card__subtitle" title="{{ event.content.subtitle }}">
      {{ event.content.subtitle }}
    </span>
  </div>

  <div class="event-card__content">
    <mec-tags-list [tags]="event?.tags"></mec-tags-list>
    <button
      class="btn btn--sm"
      (click)="gotoEvent(event.slug); $event.stopPropagation()"
    >
      + {{ 'READ_MORE' | translate }}
    </button>
  </div>
</div>
