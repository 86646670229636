export class FileParams {
  public access_token?: string;
  public cache?: string | number;
  public download?: boolean;
  public format?: string;
  public height?: string | number;
  public quality?: string | number;
  public smart?: boolean;
  public width?: string | number;
}
