<div class="auth-title__title-container">
  <mat-accordion>
    <mat-expansion-panel
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
    >
      <mat-expansion-panel-header>
        <div class="auth-title__title-header">
          <h4>{{ title | translate }}</h4>
          <img class="logo auth-title__account-logo" src="{{ accountLogo }}" />
        </div>
      </mat-expansion-panel-header>
      <div class="auth-title__title-content">
        <p>{{ 'AUT.ACCOUNT.DESCRIPTION_1' | translate }}</p>
        <p>{{ 'AUT.ACCOUNT.DESCRIPTION_2' | translate }}</p>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
