import { ProgramGroup } from '../../enums/care-monitor/program-group';

export class ProgramMetadata {
  public EXECUTION: string;
  public appId: string;
  public careMonitorFinishCallback: string;
  public client: string;
  public frequencyHasSchedule: boolean;
  public group: ProgramGroup;
  public isStickyAndHasNext: boolean;
  public patientName: string;
  public patientProfileId: number;
  public primaryColor: string;
  public program_name: string;
  public secondaryColor: string;
  public tenant: string;
  public tenantToken: string;
}
