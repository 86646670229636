import { CustomFormType } from '../../enums/forms/custom-form-type';
import { CustomFormAnswer } from './custom-form-answer';
import { CustomFormInfo } from './custom-form-info';

export class CustomFormSubmission {
  public id?: string;
  public profile?: string;
  public answers: CustomFormAnswer[];
  public form: CustomFormInfo;
  public origin: CustomFormType;
  public originId: string;
}
