<ng-container *ngIf="!disableSurvey">
  <div
    class="survey-card-list__list-items"
    [loadMore]="loadMore"
    [isLoadingMore]="isLoadingMore"
    (medInfiniteScroll)="getSurveysMe()"
  >
    <mec-survey-card
      [survey]="survey"
      *ngFor="let survey of surveyList.surveys"
    >
    </mec-survey-card>
    <!-- Loader Skeleton start -->
    <ng-container *ngIf="isLoadingMore">
      <div
        *ngFor="let item of [0, 1, 2]"
        [medSkeletonLoading]="true"
        style="height: auto; min-height: 400px; width: 100%"
      ></div>
    </ng-container>
    <!-- Loader Skeleton end -->
  </div>
  <h2 *ngIf="!isLoadingSurvey && !surveyList.surveys.length">
    {{ 'NO_RESULTS_FOUND' | translate }}
  </h2>
</ng-container>
