import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ApiEventsService } from '../../../services/api/api-events.service';
import { ApiSearchService } from '../../../services/api/api-search.service';
import { CommonService } from '../../../services/common.service';
import { InfiniteScrollDirective } from '../../../directives/infinite-scroll.directive';
import { PaginationHelper } from '../../../common/helpers/pagination.helper';
import { Routes } from '../../../common/constants/routes.constants';
import {
  SearchResult,
  SearchResultList,
} from '../../../common/models/common/search/search-result';
import { SearchCardComponent } from '../../common/search/search-card/search-card.component';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { ToastService } from '../../../services/toast.service';
import { SettingsService } from '../../../services/settings.service';

@Component({
  selector: 'mec-event-card-list',
  standalone: true,
  imports: [
    CommonModule,
    InfiniteScrollDirective,
    SearchCardComponent,
    SkeletonDirective,
    TranslateModule,
  ],
  providers: [ApiEventsService],
  templateUrl: './events-card-list.component.html',
  styleUrls: ['./events-card-list.component.scss'],
})
export class EventsCardListComponent implements OnInit {
  @Input() public limit: number;
  @Input() public maxPage: number;
  @Input() public excludes: number;
  @Input() public title: string;
  @Input() public disableItems: boolean;

  public searchResultsList: SearchResultList = new SearchResultList();
  public isLoadingEvents: boolean;

  constructor(
    private _apiSearchService: ApiSearchService,
    private _commonService: CommonService,
    private _toastService: ToastService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.searchResultsList.pagination.types = 'event';
    if (this.limit) {
      this.searchResultsList.pagination.limit = this.limit;
    }

    //todo put it out of the component

    // this.enableEvents = await this._settingsService._getPermissionValue(
    //   'HAS_EVENTS'
    // );

    this.searchResultsList.pagination.maxPage = this.maxPage;
    this.getEvents();
  }

  public goToPage(): void {
    this._commonService.navigate([Routes.events.fullPath]);
  }

  public async getEvents(reset?: boolean): Promise<void> {
    this.isLoadingEvents = true;
    this.searchResultsList.pagination.isLoadingMore = true;

    if (reset) {
      this.searchResultsList = new SearchResultList();
    }

    try {
      const itemsToAdd = await this._apiSearchService.getSearchResults(
        null,
        this.searchResultsList.pagination,
        null,
        null,
        null,
        'events',
        this.excludes ? this.excludes.toString() : null
      );
      this.searchResultsList =
        PaginationHelper.newHandlePagination<SearchResult>(
          this.searchResultsList,
          'searchResult',
          itemsToAdd
        );
    } catch (error: any) {
      this._toastService.showError(error);
    }

    this.isLoadingEvents = false;
    this.searchResultsList.pagination.isLoadingMore = false;
  }
}
