import { SocialNetworkPrivacy } from '../../enums/social-network/social-network-privacy';
import { SocialNetworkStatus } from '../../enums/social-network/social-network-status';
import { SocialNetworkReference } from './social-network-reference';

export class SocialNetworkInstance {
  public appId: string;
  public description: string;
  public name: string;
  public privacy: SocialNetworkPrivacy;
  public status: SocialNetworkStatus;
  public token: string;
  public reference: SocialNetworkReference;
}
