<div *ngIf="!showExam" class="step-multi">
  <mec-step-exam-common-info
    [step]="step"
    [courseId]="courseId"
    [isLoading]="isLoading"
    [examInstances]="step?.instancies"
    (clickGoToExam)="goToExam()"
    (clickOpenResults)="openResults()"
  ></mec-step-exam-common-info>
</div>

<ng-container>
  <mec-campus-survey
    [surveyModel]="surveyModel"
    (completeSurvey)="completeMultichoiceExam()"
    [hideSurvey]="!showExam"
    (closeSurvey)="showExam = false"
    (surveyModelChange)="
      saveMultichoiceExam($event.questionId, $event.entityValue)
    "
  ></mec-campus-survey>
</ng-container>
