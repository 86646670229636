import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { environment } from '../../environments';
import {
  PaginationOptions,
  ProfileEntity,
  ProfileExistsValidation,
  SocialNetworkParticipant,
  SocialNetworkParticipantsType,
} from '../../common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiProfileV3Service extends ApiBaseService {
  protected apiBaseUrl = environment.apiUrl;
  protected routePath = `profile/v3`;

  constructor(private _httpClient: HttpClient) {
    super(_httpClient);
  }

  public getProfile(): Promise<ProfileEntity> {
    const path = `?data=BIOGRAPHY&data=CONTACTS`;
    return super.get<ProfileEntity>(path);
  }

  public followParticipant(
    profileIdToFollow: number,
    socialInstanceIds: string
  ): Promise<void> {
    const path = `profiles/${profileIdToFollow}/following?socialnetwork=${socialInstanceIds}`;
    return super.post<void>(path);
  }

  public unfollowParticipant(
    profileIdToFollow: number,
    socialInstanceIds: string
  ): Promise<void> {
    const path = `profiles/${profileIdToFollow}/following?socialnetwork=${socialInstanceIds}`;
    return super.delete<void>(path);
  }

  public getParticipantsByMention(
    pagination: PaginationOptions,
    socialInstanceId: string,
    searchQuery?: string
  ): Observable<ProfileEntity[]> {
    const params = {};
    const path = `profiles`;

    if (searchQuery) {
      Object.assign(params, { q: searchQuery });
    }

    Object.assign(params, {
      socialnetwork: socialInstanceId,
    });

    Object.assign(params, { direction: pagination.direction });
    Object.assign(params, { limit: pagination.limit });

    return super.getObservable<ProfileEntity[]>(path, {
      params: params,
    });
  }

  public getParticipants(
    profileId: number,
    participantsType: SocialNetworkParticipantsType,
    pagination: PaginationOptions,
    socialInstanceIds: string,
    searchQuery?: string
  ): Observable<SocialNetworkParticipant[]> {
    const params = {};
    let path = `profiles`;

    if (
      participantsType &&
      participantsType !== SocialNetworkParticipantsType.community
    ) {
      path = `profiles/${profileId}/${participantsType}`;
    }

    if (searchQuery) {
      Object.assign(params, { q: searchQuery });
    }

    Object.assign(params, {
      socialnetwork: socialInstanceIds,
    });

    Object.assign(params, { data: 'BIOGRAPHY,STATISTICS,CONNECTED' });
    Object.assign(params, { direction: pagination.direction });
    Object.assign(params, { limit: pagination.limit });

    return super.getObservable<SocialNetworkParticipant[]>(path, {
      params: params,
    });
  }

  public getProfileById(
    profileId: number | string,
    socialInstanceIds?: string
  ): Promise<ProfileEntity> {
    const params = {};
    const path = `profiles/${profileId}`;

    Object.assign(params, { data: 'BIOGRAPHY,STATISTICS,CONNECTED' });

    if (socialInstanceIds?.length) {
      Object.assign(params, { socialnetwork: socialInstanceIds });
    }

    return super.get<ProfileEntity>(path, { params: params });
  }

  public existsEmailOrPhone(
    email?: string,
    mobileCountry?: number,
    mobileNumber?: number
  ): Promise<ProfileExistsValidation> {
    const path = '/exists';
    const params = {};

    if (email) {
      Object.assign(params, { email: email });
    }

    if (mobileCountry && mobileNumber) {
      Object.assign(params, { 'mobile-country': mobileCountry });
      Object.assign(params, { 'mobile-number': mobileNumber });
    }

    return super.get<ProfileExistsValidation>(path, { params: params });
  }
}
