import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[medInfiniteScroll]',
  standalone: true,
})
export class InfiniteScrollDirective {
  @Output() medInfiniteScroll = new EventEmitter();
  @Input() loadMore: boolean;
  @Input() isLoadingMore: boolean;
  public footerHeight = 0;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    const footerElement = this._document.getElementById('footerElement');
    this.footerHeight =
      footerElement?.offsetHeight != null ? footerElement.offsetHeight : 0;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const pos =
      (document.documentElement.scrollTop || document.body.scrollTop) +
      document.documentElement.offsetHeight;
    const max =
      document.documentElement.scrollHeight - (this.footerHeight + 200);
    this._changeDetectorRef.detectChanges();
    if (pos > max && this.loadMore && !this.isLoadingMore) {
      // this.loadMore = false;
      this.medInfiniteScroll.emit();
    }
  }
}
