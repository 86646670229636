import { StepType } from '../../enums/course/step-type';
import { ContentEntity } from '../common/content-entity';
import { BooleanAnswer } from '../evaluation/boolean-answer';

export declare class CourseSurveyQuestion {
  public id: number;
  public order: number;
  public savedItem: boolean;
  public mandatory: boolean;
  public type: StepType;
  public minLength: number;
  public maxLength: number;
  public acceptMultipleAnswers: boolean;
  public content: ContentEntity;
  public answers: BooleanAnswer[];
  public defaultValue: string | number;
}
