import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { HookAction } from '../../enums/hook/hook-actions';
import { HookPlatforms } from '../../enums/hook/hook-platforms';
import { HookSize } from '../../enums/hook/hooks-size';

export declare class Hook {
  public id: number;
  public action: HookAction;
  public icon: string;
  public iconProp?: IconProp;
  public label: string;
  public order: number;
  public parameters: { [key: string]: string };
  public platform: HookPlatforms;
  public size: HookSize;
  public url?: string;
}
