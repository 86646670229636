<div class="library-list" [ngClass]="{ 'library-list--row-list': isRowList }">
  <ng-container
    *ngIf="!isLoading"
    [loadMore]="libraryItemsList.pagination.loadMore"
    [isLoadingMore]="libraryItemsList.pagination.isLoadingMore"
    (medInfiniteScroll)="getLibraryFiles()"
  >
    <mec-library-item
      class="library-item"
      [ngClass]="{ 'library-item--row-list': isRowList }"
      *ngFor="let item of libraryItemsList.libraryItems"
      [isRowList]="isRowList"
      [libraryItem]="item"
      (editEventEmmiter)="getLibraryFiles(true)"
      (deleteEventEmmiter)="removeLibraryItem($event)"
    ></mec-library-item>
    <!-- Loader Skeleton start -->
    <ng-container *ngIf="libraryItemsList.pagination.isLoadingMore">
      <div
        *ngFor="let item of [0, 1, 2]"
        [medSkeletonLoading]="true"
        style="height: 100%; width: 100%"
      ></div>
    </ng-container>
    <!-- Loader Skeleton end -->
  </ng-container>

  <!-- Loader Skeleton start -->
  <ng-container *ngIf="isLoading">
    <ng-container *ngIf="!isRowList">
      <div
        *ngFor="let item of [0, 1, 2, 3, 4, 5]"
        [medSkeletonLoading]="true"
        style="height: 100%; width: 100%; aspect-ratio: 1"
      ></div>
    </ng-container>

    <ng-container *ngIf="isRowList">
      <div
        *ngFor="let item of [0, 1, 2]"
        [medSkeletonLoading]="true"
        style="height: 100px; width: 100%"
      ></div>
    </ng-container>
  </ng-container>
  <!-- Loader Skeleton end -->
</div>
<h4 *ngIf="!libraryItemsList.libraryItems.length && !isLoading">
  {{ 'NO_RESULTS_FOUND' | translate }}
</h4>
