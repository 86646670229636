import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { environment } from '../../environments';
import { PaginationOptions } from '../../common/models/common/pagination-options';
import { SearchResult } from '../../common/models/common/search/search-result';
import { Hub } from '../abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class ApiSearchService extends ApiBaseService {
  protected apiBaseUrl = environment.campusV2;
  protected routePath = `hubs/${this._hubService.hub()}/search`; // : string;

  private _headers = this._hubService.hubHeaders();

  constructor(private _hubService: Hub, private _httpClient: HttpClient) {
    super(_httpClient);
  }

  public getSearchResultByIds(ids?: string): Promise<SearchResult[]> {
    const path = ``;
    const params = {};
    Object.assign(params, { ids: ids });
    return super.get<SearchResult[]>(path, { params, headers: this._headers });
  }

  public getSearchResults(
    searchFilter: string,
    paginationOptions: PaginationOptions,
    socialNetworkId?: string,
    tags?: string,
    languages?: string,
    types?: string,
    excludes?: string
  ): Promise<SearchResult[]> {
    const path = ``;

    const params = {};

    if (searchFilter && searchFilter !== '') {
      Object.assign(params, { q: searchFilter });
    }

    if (socialNetworkId) {
      Object.assign(params, { socialNetworkId: socialNetworkId });
    }

    if (tags) {
      Object.assign(params, { tags: tags });
    }

    if (languages) {
      Object.assign(params, { languages: languages });
    }

    if (types) {
      Object.assign(params, { types: types });
    }

    if (excludes) {
      Object.assign(params, { excludes: excludes });
    }

    const options = paginationOptions as unknown as any;
    Object.keys(options).forEach((key: string) => {
      if (options[key] && options[key] !== '') {
        Object.assign(params, { [key]: options[key] });
      }
    });

    return super.get<SearchResult[]>(path, { params, headers: this._headers });
  }
}
