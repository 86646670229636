import { Injectable } from '@angular/core';
import { ApiFilesV2Service } from './api/api-files-v2.service';
import { FileEntity, UploadSignature } from '../common';
import { ToastService } from './toast.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpProgressEvent } from '@angular/common/http';
import { FileWebService } from './file.web.service';

@Injectable({
  providedIn: 'root',
})
export class LibraryService {
  constructor(
    private _apiFilesV2Service: ApiFilesV2Service,
    private _fileWebService: FileWebService,
    private _sanitizer: DomSanitizer,
    private _toastService: ToastService
  ) {}

  public async getUploadSignature(): Promise<UploadSignature> {
    try {
      return await this._apiFilesV2Service.getUploadSignature();
    } catch (error) {
      this._toastService.showError(error);
    }
    return null;
  }

  public uploadFiles(
    options: any,
    onComplete: (
      libraryFile: FileEntity,
      libraryFilesConfirmResult: FileEntity[]
    ) => void,
    onProgress: (progress: HttpProgressEvent) => void,
    filesList?: FileEntity[],
    currentFile?: FileEntity,
    folderPath?: string,
    verifyIfHasLoading?: () => void
  ): void {
    let files;
    if (options.target) {
      files = options.target.files;
    } else {
      files = options.files;
    }

    const currentFiles = [...files];

    currentFiles.forEach((file: File, index) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = async () => {
        const uploadSignature = await this.getUploadSignature();

        if (!uploadSignature) {
          return;
        }

        const blob: Blob = new Blob([
          new Uint8Array(reader.result as ArrayBuffer),
        ]);

        const blobURL: string = URL.createObjectURL(blob);

        const typeInfoWithoutBar = file.type.split('/');
        const typeText = typeInfoWithoutBar[0] + '/' + typeInfoWithoutBar[1];

        const fileToAdd: FileEntity = {
          id: Date.now(),
          filename: file.name,
          length: file.size,
          mimeType: file.type,
          extension: file.type,
          imageBlob: this._sanitizer.bypassSecurityTrustUrl(blobURL),
          isTypeImage: typeInfoWithoutBar[0] === 'image',
          typeText: typeText,
          blob: blob,
          isLoading: true,
          uploadSignature: uploadSignature,
        };

        if (index === 0 && filesList && currentFile) {
          this.selectFile(fileToAdd, filesList);
          currentFile = fileToAdd;
        }

        this.sendFileToUpload(fileToAdd, onComplete, onProgress, folderPath);

        if (filesList) {
          filesList.push(fileToAdd);
        }

        if (verifyIfHasLoading) {
          verifyIfHasLoading();
        }
      };

      reader.onerror = (error) => {
        this._toastService.showError(error);
      };
    });
  }

  public selectFile(file: FileEntity, filesList: FileEntity[]): void {
    filesList.forEach((file) => (file.selected = false));
    file.selected = true;
  }

  public sendFileToUpload(
    fileToUpload: FileEntity,
    onComplete: (
      libraryFile: FileEntity,
      libraryFilesConfirmResult: FileEntity[]
    ) => void,
    onProgress: (progress: HttpProgressEvent) => void,
    folderPath: string
  ): void {
    try {
      this._fileWebService.upload(
        fileToUpload,
        null,
        () => this.confirmFile(fileToUpload, onComplete, folderPath),
        onProgress
      );
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  public async confirmFile(
    libraryFile: FileEntity,
    onComplete: (
      libraryFile: FileEntity,
      libraryFilesConfirmResult: FileEntity[]
    ) => void,
    folderPath?: string
  ): Promise<void> {
    try {
      const libraryFileConfirmResult =
        await this._apiFilesV2Service.confirmFile(
          libraryFile.uploadSignature.token,
          libraryFile.filename,
          folderPath
        );

      onComplete(libraryFile, libraryFileConfirmResult);
    } catch (error) {
      this._toastService.showError(error);
    }
  }
}
