<div [ngClass]="{ 'modal--dark-color': options.isDarkColor }" class="modal">
  <div *ngIf="options.hideHeader !== true" class="modal__header">
    <div class="modal__header-info">
      <h5 class="modal__title">{{ options.title | translate }}</h5>
      <p *ngIf="options.subTitle !== null" class="modal__subtitle">
        {{ options.subTitle | translate }}
      </p>
    </div>

    <button
      *ngIf="options.closeable === true"
      (click)="closeClick.emit()"
      class="btn btn--icon btn--sm btn--no-bg modal__close"
    >
      <fa-icon class="modal__icon" [icon]="closeIcon">close</fa-icon>
    </button>
  </div>
  <div #modalBody class="modal__body">
    <ng-content></ng-content>
  </div>
  <div class="modal__footer" *ngIf="options.hideFooter !== true">
    <button
      class="btn btn--sm modal__btn btn--error btn--sm btn--outline"
      [ngClass]="{
        'modal__btn--hide': options.showSecondaryNegativeButton === false
      }"
      [disabled]="disableActions"
      (click)="secondaryNegativeClick.emit()"
    >
      {{ options.secondaryNegativeButtonLabel | translate }}
    </button>

    <div class="modal__footer-actions">
      <button
        class="btn btn--sm modal__btn btn--outline"
        [ngClass]="{ 'modal__btn--hide': options.showNegativeButton === false }"
        [disabled]="disableActions"
        (click)="negativeClick.emit()"
      >
        {{ options.negativeButtonLabel | translate }}
      </button>
      <button
        class="btn btn--sm modal__btn btn--outline"
        [ngClass]="{
          'modal__btn--hide': options.showSecondaryPositiveButton === false
        }"
        [disabled]="disableActions"
        (click)="secondaryPositiveClick.emit()"
      >
        {{ options.secondaryPositiveButtonLabel | translate }}
      </button>
      <button
        class="btn btn--sm modal__btn btn--primary"
        [ngClass]="{ 'modal__btn--hide': options.showPositiveButton === false }"
        [disabled]="disableActions"
        (click)="positiveClick.emit()"
      >
        {{ options.positiveButtonLabel | translate }}
      </button>
    </div>
  </div>
</div>
