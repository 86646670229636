import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibraryListComponent } from '../../library/library-list/library-list.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'mec-course-library-list',
  standalone: true,
  imports: [CommonModule, LibraryListComponent, TranslateModule],
  templateUrl: './course-library-list.component.html',
  styleUrls: ['./course-library-list.component.scss'],
})
export class CourseLibraryListComponent {
  @Input() public stepId: number;
  @Input() public courseSlug: string;
}
