import { EvaluationStatus } from "../../enums/evaluation/evaluation-status";
import { ContentEntity } from "../common/content-entity";
import { Course } from "../course/courses";
import { Step } from "../course/step";
import { Profile } from "../profile/profile";

export declare class Evaluation {
  public id: number;
  public isActivated?: boolean;
  public content?: ContentEntity;
  public order?: number;
  public savedItem?: boolean;
  public course: Course;
  public finishedAt?: Date;
  public profile: Profile;
  public score?: number;
  public evaluation: Evaluation;
  public status?: EvaluationStatus;
  public step?: Step;
  public avatarProfileUrl?: string;
}