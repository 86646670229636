<div class="social-comment-item">
  <img class="social-comment-item__avatar" src="{{ profileAvatarUrl }}" />
  <div class="social-comment-item__container">
    <div class="social-comment-item__message-container">
      <div class="social-comment-item__header">
        <div class="social-comment-item__name">
          <h4 class="social-item__name">{{ comment?.profile?.name }}</h4>
          <small class="social-item__date">{{
            comment?.publishedAt | date : 'longDate'
          }}</small>
        </div>
        <div *ngIf="comment?.owner" class="social-comment-item__container-edit">
          <mat-menu #filemenu="matMenu">
            <button mat-menu-item (click)="deleteThread()">
              <span> {{ 'DELETE' | translate }} </span>
            </button>
          </mat-menu>
          <button
            [matMenuTriggerFor]="filemenu"
            (click)="$event.stopPropagation()"
            class="btn btn--sm btn--no-bg btn--icon"
          >
            <fa-icon class="social-item__edit-icon" [icon]="editIcon"></fa-icon>
          </button>
        </div>
      </div>
      <div class="social-comment-item__body">
        <mec-social-publication-body
          [socialThread]="comment"
        ></mec-social-publication-body>
      </div>
    </div>

    <div class="social-comment-item__reactions">
      <mec-social-publication-reaction
        [socialThread]="comment"
      ></mec-social-publication-reaction>
      <span *ngIf="hasAnyReaction" class="social-comment-item__pipe"> | </span>
      <mec-social-publication-reactions-info
        [socialThread]="comment"
      ></mec-social-publication-reactions-info>
    </div>
  </div>
</div>
