import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonHelper } from '../../../../common/helpers/common.helper';
import { MepStorage } from '../../../../services/abstract/mep-storage.abstract';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'filepicker-insert',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './filepicker-insert.component.html',
  styleUrls: ['./filepicker-insert.component.scss'],
})
export class FilepickerInsertComponent implements OnInit {
  @Input() data: any;
  public url: string;

  constructor() {}

  public async ngOnInit(): Promise<void> {
    if (this.data?.insertUrl) {
      if (this.data?.insertType === 'VIDEO') {
        this.url = `${this.data?.insertUrl}?quality=720&format=mp4`;
        return;
      }

      this.url = `${this.data?.insertUrl}?quality=720&width=352&format=${this.data.insertExtension}`;
    }
  }
}
