<div class="social-publication-reaction">
  <button
    (click)="putReaction(mainIcon.name)"
    (mouseover)="showReactions = true"
    (mouseleave)="showReactions = false"
    class="social-publication-reaction__btn btn btn--sm btn--no-bg"
    [ngClass]="{
      'social-publication-reaction__reaction--like':
        mainIcon.name === reactionsTypes.LIKE && givenReaction,
      'social-publication-reaction__reaction--love':
        mainIcon.name === reactionsTypes.LOVE && givenReaction,
      'social-publication-reaction__reaction--genius':
        mainIcon.name === reactionsTypes.GENIUS && givenReaction,
      'social-publication-reaction__reaction--support':
        mainIcon.name === reactionsTypes.SUPPORT && givenReaction,
      'social-publication-reaction__reaction--funny':
        mainIcon.name === reactionsTypes.FUNNY && givenReaction
    }"
  >
    <fa-icon [icon]="mainIcon.icon"></fa-icon>
    <span>{{ mainIcon.label | translate }}</span>
  </button>
  <div
    (mouseover)="showReactions = true"
    (mouseleave)="showReactions = false"
    [ngClass]="{
      'social-publication-reaction__reactions--active': showReactions
    }"
    class="social-publication-reaction__reactions card"
  >
    <fa-icon
      (click)="putReaction(reactionsTypes.LIKE)"
      class="social-publication-reaction__reaction social-publication-reaction__reaction--like"
      [icon]="reactions[reactionsTypes.LIKE].icon"
    ></fa-icon>

    <fa-icon
      (click)="putReaction(reactionsTypes.LOVE)"
      class="social-publication-reaction__reaction social-publication-reaction__reaction--love"
      [icon]="reactions[reactionsTypes.LOVE].icon"
    ></fa-icon>

    <fa-icon
      (click)="putReaction(reactionsTypes.GENIUS)"
      class="social-publication-reaction__reaction social-publication-reaction__reaction--genius"
      [icon]="reactions[reactionsTypes.GENIUS].icon"
    ></fa-icon>

    <fa-icon
      (click)="putReaction(reactionsTypes.SUPPORT)"
      class="social-publication-reaction__reaction social-publication-reaction__reaction--support"
      [icon]="reactions[reactionsTypes.SUPPORT].icon"
    ></fa-icon>

    <fa-icon
      (click)="putReaction(reactionsTypes.FUNNY)"
      class="social-publication-reaction__reaction social-publication-reaction__reaction--funny"
      [icon]="reactions[reactionsTypes.FUNNY].icon"
    ></fa-icon>
  </div>
</div>
