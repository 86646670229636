<div class="program-radio">
  <h4>{{ programNode?.labelText }}</h4>
  <div class="program-radio__options">
    <mat-radio-group class="program-radio__radio-group">
      <mat-radio-button
        *ngFor="let item of programNode?.values"
        [value]="item.value"
        (click)="$event.stopPropagation()"
        (change)="selectItem(item.value)"
        ><span (click)="$event.stopPropagation()">
          {{ item.name }}
        </span></mat-radio-button
      >
    </mat-radio-group>
  </div>
</div>
