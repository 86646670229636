import { TDateISO } from '../../interfaces/templates/date';
import { SettingsType } from './settings-type';

export declare class SettingsEntity {
  public createdAt: TDateISO | Date;
  public readOnly: boolean;
  public type: SettingsType;
  public updatedAt: TDateISO | Date;
  public value: string;
}
