import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin } from 'rxjs';
import { Hub } from '../abstract/hub.abstract';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root',
})
export class ApiI18nService extends ApiBaseService {
  protected apiBaseUrl = environment.translateUrl as string;
  protected routePath = ``;

  constructor(private _httpClient: HttpClient) {
    super(_httpClient);
  }

  public async getTranslation(lang: string, hub: string): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    let translationObject: Object = {};

    try {
      const globalTranlate = await super.get(
        `campus/${environment.name}/${lang}`,
        {
          headers,
        }
      );
      translationObject = globalTranlate;
    } catch (error) {
      console.log(error);
    }

    try {
      const hubTranslate = await super.get(
        `${hub}-campus/${environment.name}/${lang}`,
        {
          headers,
        }
      );
      translationObject = {
        ...translationObject,
        ...(hubTranslate as object),
      };
    } catch (error) {
      console.log(error);
    }

    return translationObject;
  }
}
