import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { ProfileCountry, ProfileEntity } from '../../common';
import { environment } from '../../environments';

@Injectable({
  providedIn: 'root',
})
export class ApiProfileV2Service extends ApiBaseService {
  protected apiBaseUrl = environment.apiUrl;
  protected routePath = `profile/v2/`;

  constructor(private _httpClient: HttpClient) {
    super(_httpClient);
  }

  public getCountries(): Promise<ProfileCountry[]> {
    const path = 'locations/country';
    return super.get<ProfileCountry[]>(path);
  }

  public updateProfile(profile: ProfileEntity): Promise<ProfileEntity> {
    const path = '';
    return super.put<ProfileEntity>(path, profile);
  }

  public saveProfile(
    person: ProfileEntity,
    authToken: string
  ): Promise<ProfileEntity> {
    const headers = {
      'X-Oauth-Key': authToken,
    };
    const path = 'persons';
    return super.post<ProfileEntity>(path, person, { headers });
  }
}
