import { Injectable } from '@angular/core';
import { ActionSheetController, ActionSheetButton } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { MepActionButton } from '../common/models/common/action-button/mep-action-button';

@Injectable({
  providedIn: 'root',
})
export class ActionSheetService {
  constructor(
    private _sheetController: ActionSheetController,
    public translateService: TranslateService
  ) {}

  public async showActionsSheet(buttons: MepActionButton[]): Promise<any> {
    const buttonsSheet: ActionSheetButton[] = [];

    buttons.forEach((button) => {
      buttonsSheet.push({
        text: button.text,
        role: button.role,
        data: button.data,
      });
    });

    const alert = await this._sheetController.create({
      buttons: buttons,
    });

    await alert.present();

    const result = await alert.onDidDismiss();
    return result;
  }
}
