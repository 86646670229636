<div
  class="card course-card"
  (click)="goToCourseDetail(); $event.stopPropagation()"
>
  <div class="course-card__img-container">
    <div class="course-card__overlay"></div>

    <img
      [ngClass]="{ hidden: isLoadingImage || hiddenImage }"
      (error)="hiddenImage = true; isLoadingImage = false"
      (load)="isLoadingImage = false"
      class="course-card__cover"
      src="{{ imageUrl }}"
    />

    <!-- Skeleton image start -->
    <div
      [medSkeletonLoading]="true"
      *ngIf="isLoadingImage"
      class="course-card__cover"
    ></div>
    <!-- Skeleton image end -->
  </div>

  <div class="course-card__title-header">
    <h2 class="course-card__title" title="{{ course.content.title }}">
      {{ course.content.title }}
    </h2>
    <span class="course-card__subtitle" title="{{ course.content.subtitle }}">
      {{ course.content.subtitle }}
    </span>
  </div>

  <div class="course-card__content">
    <button
      *ngIf="!hasShowGoToClassroom"
      class="btn btn--sm"
      (click)="goToCourseDetail()"
    >
      + {{ 'READ_MORE' | translate }}
    </button>
    <div
      class="course-card__content-info"
      *ngIf="hasShowGoToClassroom"
      (click)="
        goToCourseStep(course?.currentStep?.id); $event.stopPropagation()
      "
    >
      <div class="course-card__step-info">
        <h4
          class="course-card__info-title"
          title="{{ course?.currentStep?.content?.title }}"
        >
          {{ course?.currentStep?.content?.title }}
        </h4>
        <small> {{ 'COURSE_PROGRESS' | translate }} </small>
      </div>

      <circle-progress
        [percent]="course?.percentageComplete"
        [radius]="20"
        [titleFontSize]="'14'"
        [outerStrokeWidth]="8"
        [innerStrokeWidth]="8"
        [outerStrokeColor]="'#4882c2'"
        [outerStrokeGradientStopColor]="'#53a9ff'"
        [innerStrokeColor]="'#e7e8ea'"
        [animation]="true"
        [animationDuration]="300"
      ></circle-progress>

      <fa-icon class="course-card__detail-icon" [icon]="detailIcon"></fa-icon>
    </div>

    <span class="course-card__invite-only" *ngIf="hasShowInvitedOnly">
      {{ 'INVITE_ONLY' | translate }}
    </span>

    <span class="course-card__invite-only" *ngIf="isExpiredInvitation">
      {{ 'INVITATION_EXPIRED' | translate }}
    </span>

    <button
      class="btn btn--sm btn--full-width btn--outline"
      *ngIf="hasShowGoToSubscribe && !isExpiredInvitation"
      (click)="openSubscriptionModal(); $event.stopPropagation()"
    >
      {{ 'SUBSCRIBE' | translate }}
    </button>
  </div>
</div>
