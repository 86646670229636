<mec-modal
  [options]="modalOptions"
  [disableActions]="!searchItemSelected"
  (positiveClick)="confirmSelectedContent()"
  (closeClick)="matDialogRef.close(false)"
>
  <div class="social-content">
    <p>{{ 'APP_STATES.MODAL.DESCRIPTION' | translate }}</p>
    <mec-autocomplete
      [items]="contentAutocompleteItems"
      [formGroup]="formGroup"
      [placeholder]="'SEARCH'"
      [controlName]="'search'"
      [isDynamicList]="true"
      (searchFilterChange)="getContentItems($event)"
      (optionSelectedChange)="setContent($event)"
    ></mec-autocomplete>

    <ng-container *ngIf="searchItemSelected">
      <mec-social-content-card
        [searchItem]="searchItemSelected"
      ></mec-social-content-card>
    </ng-container>
  </div>
</mec-modal>
