import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments';
import { Hook } from '../../common';
import { ApiBaseService } from './api-base.service';
import { Hub } from '../abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class ApiHooksService extends ApiBaseService {
  protected apiBaseUrl = environment.campusV2;
  protected routePath = `hubs/${this._hubService.hub()}/hooks`; // : string;

  constructor(private _hubService: Hub, private _httpClient: HttpClient) {
    super(_httpClient);

    // this._hubService.onHubChange.subscribe(() => {
    //   this.routePath = `hubs/${this._hubService.hub()}/hooks`;
    // });
  }

  public getHooks(): Promise<Hook[]> {
    const path = ``;
    return super.get<Hook[]>(path);
  }
}
