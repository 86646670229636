<div class="course-content page-content">
  <div class="course-content__container-specifications" *ngIf="!loadingDetails">
    <div class="course-content__container-target">
      <div class="course-content__section">
        <h2 class="course-content__title">{{ 'ABOUT' | translate }}</h2>
        <p>{{ course?.content?.description }}</p>
      </div>

      <div class="course-content__section">
        <h2 class="course-content__title">
          <fa-icon [icon]="targetIcon"></fa-icon
          >{{ 'TARGET_AUDIENCE' | translate }}
        </h2>
        <p>{{ course?.content?.targetAudience }}</p>
      </div>
    </div>

    <div class="specification">
      <button
        (click)="openSubscriptionModal()"
        *ngIf="
          _authService.isLogged && hasShowGoToSubscribe && !isExpiredInvitation
        "
        class="btn btn--sm"
      >
        {{ 'SUBSCRIBE' | translate }}
      </button>

      <div class="specification__card card">
        <div class="course-content__section">
          <h2 class="specification__title">
            <fa-icon [icon]="infoIcon"></fa-icon>
            {{ 'SPECIFICATIONS' | translate }}
          </h2>
          <div class="specification__list">
            <div class="specification__item">
              <div class="specification__icon-container">
                <fa-icon [icon]="editIcon"></fa-icon>
              </div>
              <div class="specification__info">
                <h4 class="course-content__specification-name">
                  {{ 'EXECUTION' | translate }}
                </h4>
                <p class="course-content__specification-value">
                  {{ courseTypeSetting | translate }}
                </p>
              </div>
            </div>

            <div class="specification__item">
              <div
                class="specification__icon-container specification__icon-container--black"
              >
                <fa-icon [icon]="laptopIcon"></fa-icon>
              </div>
              <div class="specification__info">
                <h4 class="course-content__specification-name">
                  {{ 'AVAILABLE_FOR' | translate }}
                </h4>
                <p class="course-content__specification-value">
                  {{ supportedInterfaceSetting | translate }}
                </p>
              </div>
            </div>

            <div
              class="specification__item"
              *ngIf="certificateSetting === 'YES'"
            >
              <div class="specification__icon-container">
                <fa-icon [icon]="certificateIcon"></fa-icon>
              </div>
              <div class="specification__info">
                <p class="course-content__specification-value">
                  {{
                    'This application provides a verifiable certification when its completed.'
                  }}
                </p>
              </div>
            </div>

            <div class="specification__item">
              <div
                [ngClass]="{
                  'specification__icon-container--black':
                    certificateSetting === 'YES'
                }"
                class="specification__icon-container"
              >
                <fa-icon [icon]="dollarIcon"></fa-icon>
              </div>
              <div class="specification__info">
                <h4 class="course-content__specification-name">
                  {{ 'INVESTMENT' | translate }}
                </h4>
                <p
                  class="course-content__specification-value"
                  *ngIf="priceSetting !== '0'"
                >
                  {{ priceSetting }}
                </p>
                <p
                  class="course-content__specification-value"
                  *ngIf="!priceSetting || priceSetting === '0'"
                >
                  {{ 'Free' }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Loader Skeleton start -->
    <div
      class="course-content__section"
      *ngIf="loadingDetails"
      [medSkeletonLoading]="true"
    >
      <h2 class="course-content__title">Skeleton text</h2>
    </div>
    <ng-container *ngIf="loadingDetails">
      <div [medSkeletonLoading]="true" style="height: 200px; width: 100%"></div>
    </ng-container>
    <!-- Loader Skeleton end -->
  </div>

  <div *ngIf="!loadingDetails" class="course-content__section">
    <h2 class="course-content__title">
      <fa-icon [icon]="checkSquareIcon"></fa-icon
      >{{ 'GOALS_OBJECTIVES' | translate }}
    </h2>
    <div
      class="course-content__item-list"
      *ngFor="let item of course?.content?.goals"
    >
      <fa-icon [icon]="checkIcon"></fa-icon>
      <span>{{ item }}</span>
    </div>
  </div>

  <div *ngIf="!loadingDetails" class="course-content__section">
    <h2 class="course-content__title">
      <fa-icon [icon]="alertIcon"></fa-icon
      >{{ 'NON_GOALS_OBJECTIVES' | translate }}
    </h2>
    <div
      class="course-content__item-list"
      *ngFor="let item of course?.content?.nonGoals"
    >
      <fa-icon [icon]="closeIcon"></fa-icon>
      <span>{{ item }}</span>
    </div>
  </div>

  <div *ngIf="!loadingDetails" class="course-content__section">
    <h2 class="course-content__title">
      <fa-icon [icon]="listIcon"></fa-icon>{{ 'COURSE_STRUCTURE' | translate }}
    </h2>
    <mec-course-steps [course]="course"></mec-course-steps>
  </div>
</div>
