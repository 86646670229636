<div class="event-question">
  <div class="event-question__header">
    <h4 class="event-question__title">
      {{ 'WEBINARS.QUESTION.SEND' | translate }}
    </h4>
  </div>
  <div class="event-question__content">
    <div class="field__group">
      <textarea
        [(ngModel)]="question"
        [disabled]="isSaving || isLoading"
        [placeholder]="'WEBINARS.QUESTION.MESSAGE_PLACEHOLDER' | translate"
        class="event-question__textarea field__input"
      ></textarea>
    </div>
  </div>
  <div class="event-question__actions">
    <button
      (click)="saveQuestion()"
      [disabled]="!question || question === '' || isSaving || isLoading"
      class="event-question__btn-send btn btn--sm btn--outline"
    >
      <span *ngIf="!isSaving">{{ 'SEND' | translate }}</span>
      <mat-spinner *ngIf="isSaving"></mat-spinner>
    </button>
  </div>
</div>
