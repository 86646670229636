<div class="search-cards-list">
  <h2 class="search-cards-list__title">{{ title | translate }}</h2>
  <h5 *ngIf="searchFilter" class="search-cards-list__subtitle">
    {{ 'SEARCH.QUERY' | translate : { query: searchFilter } }}
  </h5>
  <ng-container *ngIf="!isLoading && searchResultList?.searchResult?.length">
    <div
      class="search-cards-list__list-items"
      [loadMore]="searchResultList?.pagination.loadMore"
      (medInfiniteScroll)="search()"
    >
      <mec-search-card
        [searchResult]="searchResult"
        *ngFor="let searchResult of searchResultList?.searchResult"
      ></mec-search-card>

      <!-- <mec-news-card [news]="news" *ngFor="let news of newsList"> </mec-news-card> -->
      <!-- Loader Skeleton start -->
      <ng-container *ngIf="isLoading">
        <div
          *ngFor="let item of [0, 1, 2]"
          [medSkeletonLoading]="true"
          style="height: 300px; width: 100%"
        ></div>
      </ng-container>
      <!-- Loader Skeleton end -->
    </div>
  </ng-container>
  <h2
    class="search-cards-list__no-results"
    *ngIf="!isLoading && !searchResultList?.searchResult?.length"
  >
    {{ 'NO_RESULTS_FOUND' | translate }}
  </h2>

  <!-- Loader Skeleton start -->
  <ng-container *ngIf="isLoading">
    <div *ngIf="isLoading" [medSkeletonLoading]="true">
      <h2 class="search-cards-list__title">Skeleton text</h2>
    </div>
    <ng-container>
      <div class="search-cards-list__list-items">
        <div
          *ngFor="let item of [0, 1, 2]"
          [medSkeletonLoading]="true"
          style="height: 300px; width: 100%"
        ></div>
      </div>
    </ng-container>
    <!-- Loader Skeleton end -->
  </ng-container>
</div>
