<div class="programs-item card" (click)="goToProgram()">
  <div class="programs-item__top">
    <div class="programs-item__image-container">
      <img
        class="programs-item__image avatar"
        [ngClass]="{ hidden: isLoadingImage || hiddenImage }"
        *ngIf="imageUrl && !hiddenImage"
        (error)="imageError()"
        (load)="imageLoaded()"
        [src]="imageUrl"
      />
      <!-- Start Skeleton loader -->
      <div
        class="programs-item__image avatar"
        *ngIf="isLoadingImage"
        style="border-radius: 50% !important"
        [medSkeletonLoading]="true"
      ></div>
      <!-- End Skeleton loader -->
      <div class="programs-item__top-info">
        <span class="programs-item__hub">{{ program?.metadata?.tenant }}</span>
        <span class="programs-item__name">
          {{ program?.metadata?.program_name }}
        </span>
      </div>
    </div>
    <div
      class="programs-item__status"
      [ngClass]="{
        'programs-item__status--completed':
          program?.statusEnum === programsStatus.FINISHED,
        'programs-item__status--paused':
          program?.statusEnum === programsStatus.PAUSED ||
          program?.statusEnum === programsStatus.UPDATED,
        'programs-item__status--not-completed':
          program?.statusEnum === programsStatus.NOT_APPLICABLE ||
          program?.statusEnum === programsStatus.NOT_EXECUTED,
        'programs-item__status--started':
          program?.statusEnum === programsStatus.INIT
      }"
    >
      <span class="programs-item__title-status">{{
        program?.statusLabel | translate
      }}</span>
    </div>
  </div>
  <div class="programs-item__bottom">
    <span class="programs-item__date">{{
      program?.updatedAt | date : 'mediumDate'
    }}</span>
    <span
      [title]="program?.project?.description"
      class="line-clamp-3 programs-item__description"
      >{{ program?.project?.description }}</span
    >
  </div>
</div>
