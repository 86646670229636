import { ComponentType } from '@angular/cdk/portal';
import { Component, Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PlatformService } from './platform.service';
import { ModalController, ModalOptions } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private _modalController: any;
  private _modalMobileController: any;

  constructor(private _platformService: PlatformService) {
    if (this._platformService.platform === 'mobile') {
      this._modalMobileController = inject(ModalController);
      this._modalController = inject(MatDialog);
    } else {
      this._modalController = inject(MatDialog);
    }
  }

  // Modal sizes conform https://getbootstrap.com/docs/4.2/components/modal/
  // Small.modal-sm	300px
  // Default	None	500px
  // Large.modal - lg	800px
  // Extra large.modal - xl	1140px

  public openModal<T, D>(
    component: ComponentType<T>,
    data?: D,
    options?: { fullscreen?: boolean; hasBackdrop?: boolean },
    width: 300 | 500 | 800 | 1140 = 500
  ): Promise<any> {
    // if (this._platformService.platform === 'mobile') {
    //   return this.openMobileModal(component, data);
    // }

    const openedDialog = this._modalController.open(component, {
      data,
      panelClass: `modal__panel${options?.fullscreen ? '--fullscreen' : ''}`,
      hasBackdrop:
        options?.hasBackdrop === undefined ? true : options?.hasBackdrop,
      disableClose: true,
      width: options?.fullscreen ? '100vw' : '100%',
      height: options?.fullscreen ? '100vh' : undefined,
      maxWidth: options?.fullscreen ? '100vw' : `${width}px`,
      maxHeight: options?.fullscreen ? '100vh' : '90vh',
    });

    return openedDialog;
  }

  //mobile methods
  public async openMobileModal(component: any, data?: any): Promise<any> {
    const modalOptions: ModalOptions = {
      component: component,
      componentProps: data,
    };

    const modal = await this._modalMobileController.create(modalOptions);
    modal.present();

    const result = await modal.onDidDismiss();
    return result;
  }

  public async closeMobileModal(result?: any): Promise<boolean> {
    return await this._modalMobileController.dismiss(result);
  }
}
