import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventPresentationsItemComponent } from '../event-participants-item/event-participants-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { EventParticipant } from '../../../common/models/common/event-participant';

@Component({
  selector: 'mec-event-participants-list',
  standalone: true,
  imports: [CommonModule, TranslateModule, EventPresentationsItemComponent],
  templateUrl: './event-participants-list.component.html',
  styleUrls: ['./event-participants-list.component.scss'],
})
export class EventPresentationsListComponent {
  @Input() public participants: EventParticipant[];
  @Input() public title: string = 'PRESENTATIONS';

  constructor() {}
}
