import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments';
import { PaginationOptions, SearchResult } from '../../common';
import { ApiBaseService } from './api-base.service';
import { Hub } from '../abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class ApiSavedItemsService extends ApiBaseService {
  protected apiBaseUrl = environment.campusV2;
  protected routePath = `hubs/${this._hubService.hub()}/saved-items`; // : string;

  constructor(private _hubService: Hub, private _httpClient: HttpClient) {
    super(_httpClient);

    // this._hubService.onHubChange.subscribe(() => {
    //   this.routePath = `hubs/${this._hubService.hub()}/saved-items`;
    // });
  }

  public getSavedItems(
    paginationOptions: PaginationOptions
  ): Promise<SearchResult[]> {
    const path = `?direction=${paginationOptions.direction}&limit=${paginationOptions.limit}&orderby=${paginationOptions.orderBy}&page=${paginationOptions.page}`;
    return super.get<SearchResult[]>(path);
  }

  public addSavedItem(id: number, mod: string): Promise<SearchResult> {
    const path = ``;
    return super.post<SearchResult>(path, { id, module: mod });
  }

  public removeSavedItem(id: number, mod: string): Promise<void> {
    const path = `${mod}/${id}`;
    return super.delete<void>(path);
  }
}
