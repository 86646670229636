export declare type Permission =
  | 'ACTIVE_SUBSCRIPTION'
  | 'ADMINISTRATIVE_INTERFACE'
  | 'CAREMONITOR'
  | 'COURSE"'
  | 'COURSE_CONTENT'
  | 'COURSE_PARTICIPANT"'
  | 'COURSE_PARTICIPANT_ALL_PARTICIPANTS'
  | 'COURSE_PARTICIPANT_REPORTS'
  | 'COURSE_SETTINGS'
  | 'EVALUATOR"'
  | 'EVALUATOR_REPORTS'
  | 'HUB"'
  | 'HUB_CONTENT'
  | 'HUB_LIBRARY'
  | 'HUB_PARTICIPANT"'
  | 'HUB_PARTICIPANT_ALL_PARTICIPANTS'
  | 'HUB_PARTICIPANT_REPORTS'
  | 'HUB_SETTINGS'
  | 'PRODUCTS'
  | 'SCHOLAR'
  | 'WORKPLACE"'
  | 'WORKPLACE_PARTICIPANTS'
  | 'WORKPLACE_SETTINGS';
