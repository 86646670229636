<ng-container *ngIf="!disableDropdownMenu && hasMenuItems">
  <button
    mat-icon-button
    [matMenuTriggerFor]="dropdownMenu"
    (click)="$event.stopPropagation()"
    attr.aria-label="{{ 'OPEN' | translate }}"
  >
    <fa-icon [icon]="iconEllipsisVertical"></fa-icon>
  </button>
  <mat-menu #dropdownMenu="matMenu">
    <ng-container *ngFor="let menuItem of menuItems">
      <button
        class="dropdown-menu__item"
        mat-menu-item
        (click)="menuItemsClick(menuItem); $event.stopPropagation()"
      >
        <fa-icon
          class="dropdown-menu__icon"
          [icon]="menuItem.icon"
          *ngIf="menuItem.icon"
        >
        </fa-icon>
        <span class="dropdown-menu__name">{{ menuItem.name | translate }}</span>
      </button>
    </ng-container>
    <button class="dropdown-menu__item" mat-menu-item>
      <span> {{ 'CANCEL' | translate }} </span>
    </button>
  </mat-menu>
</ng-container>
