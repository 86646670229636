<div
  class="dashboard-cards__scholar-message"
  *ngIf="!loadingInstance && instance"
>
  <p
    *ngIf="!instance?.role?.administrator && !instance?.role?.evaluator"
    [innerHTML]="
      'DASHBOARD.WELCOME.SCHOLAR_MESSAGE'
        | translate : { name: instance?.content?.title }
    "
  ></p>
  <p
    *ngIf="instance?.role?.administrator || instance?.role?.evaluator"
    [innerHTML]="
      'DASHBOARD.WELCOME.SCHOLAR_MESSAGE'
        | translate : { name: instance?.content?.title }
    "
  ></p>
  <fa-icon [icon]="icons['info']"></fa-icon>
</div>
<!-- Start skeleton loader -->
<div
  class="dashboard-cards__scholar-message"
  styles="height: 100px"
  [medSkeletonLoading]="true"
  *ngIf="loadingInstance"
></div>
<!-- End skeleton loader -->

<ng-container *ngIf="!hasHooks && !loading">
  <div
    class="card dashboard-cards"
    *ngIf="programsCount && programsCount > 0"
    (click)="goToPage('programs')"
  >
    <div class="dashboard-cards__content-programs">
      <div class="dashboard-cards__item-programs">
        <fa-icon [icon]="icons['programs']"></fa-icon>
        <p class="dashboard-cards__program-title">
          {{ 'PROGRAMS.LIST.TITLE' | translate }}
        </p>
      </div>
      <div class="tag">{{ programsCount }}</div>
    </div>
  </div>

  <div class="dashboard-cards__container-cards">
    <div class="card dashboard-cards__card" (click)="goToPage('library')">
      <fa-icon [icon]="icons['library']"></fa-icon>
      <p class="dashboard-cards__title">{{ 'LIBRARY' | translate }}</p>
    </div>

    <div class="card dashboard-cards__card" (click)="goToPage('feed')">
      <fa-icon [icon]="icons['feed']"></fa-icon>
      <p class="dashboard-cards__title">
        {{ 'SOCIALNETWORK.FEED' | translate }}
      </p>
    </div>

    <div class="card dashboard-cards__card" (click)="goToPage('social')">
      <fa-icon [icon]="icons['participants']"></fa-icon>
      <p class="dashboard-cards__title">
        {{ 'SOCIALNETWORK.PARTICIPANTS' | translate }}
      </p>
    </div>

    <div class="card dashboard-cards__card" (click)="goToPage('events')">
      <fa-icon [icon]="icons['events']"></fa-icon>
      <p class="dashboard-cards__title">{{ 'EVENTS' | translate }}</p>
    </div>

    <div class="card dashboard-cards__card" (click)="goToPage('news')">
      <fa-icon [icon]="icons['news']"></fa-icon>
      <p class="dashboard-cards__title">{{ 'NEWS' | translate }}</p>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="hasHooks && !loading">
  <div class="dashboard-cards__container-cards">
    <div
      *ngFor="let hook of hooks"
      class="card dashboard-cards__card"
      (click)="goToPage(hook.url, hook.action)"
    >
      <fa-icon [icon]="hook.iconProp"></fa-icon>
      <p class="dashboard-cards__title">{{ hook.label | translate }}</p>
    </div>
  </div>
</ng-container>

<!-- Start skeleton loader -->
<ng-container *ngIf="loading">
  <div class="dashboard-cards__container-cards">
    <div
      [medSkeletonLoading]="true"
      *ngFor="let item of [0, 1, 2, 3]"
      class="dashboard-cards__card"
      style="height: 80px"
    ></div>
  </div>
</ng-container>
<!-- End skeleton loader -->
