import { Injectable } from '@angular/core';
import { ApiI18nService } from './api/api-i18n.service';
import { TranslateService } from '@ngx-translate/core';
import { Hub } from './abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class i18nService {
  private _language = 'en';
  private _region = 'ww';

  constructor(
    private _apiI18nService: ApiI18nService,
    private _translateService: TranslateService
  ) {}

  public async setLanguageAndRegion(
    language: string,
    region: string,
    hub: string
  ): Promise<void> {
    this._language = language;
    this._region = region;
    await this.setLocale(language, hub);
  }

  public async setLocale(lang = 'en', hub: string): Promise<void> {
    const translateResult = await this._apiI18nService.getTranslation(
      lang,
      hub
    );

    this._translateService.setTranslation(lang, translateResult, true);
    this._translateService.setDefaultLang(lang);
    this._translateService.use(lang);
  }

  public setTranslate(): void {}

  public get language(): string {
    return this._language;
  }

  public get region(): string {
    return this._region;
  }
}
