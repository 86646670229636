export enum ArticleType {
  CERTIFICATE = 'CERTIFICATE',
  CONTENT = 'CONTENT',
  EVENTS = 'EVENTS',
  EXAM = 'EXAM',
  FILE = 'FILE',
  FOLDER = 'FOLDER',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  NEWS = 'NEWS',
  PAGE = 'PAGE',
  SELF_PACED = 'SELF_PACED',
  SURVEY = 'SURVEY',
  TIME_LIMITED = 'TIME_LIMITED',

  COURSE = 'COURSE',
  LIBRARY_FILE = 'LIBRARY_FILE',
  LIBRARY_FOLDER = 'LIBRARY_FOLDER',
}
