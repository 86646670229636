import { MultipleChoiceQuestionAnswerType } from '../../../enums/course/multiple-choice-question-answer-type';
import { StepType } from '../../../enums/course/step-type';
import { ContentEntity } from '../../common/content-entity';
import { Answer } from '../answer';
import { BooleanAnswer } from '../boolean-answer';

export declare class MultiChoiceQuestion {
  public id: number;
  public order: number;
  public savedItem: boolean;
  public acceptMultipleAnswers: boolean;
  public shuffleAnswers: boolean;
  public type: StepType;
  public answerType: MultipleChoiceQuestionAnswerType;
  public content: ContentEntity;
  public booleanAnswers: BooleanAnswer[];
  public answers: Answer[];
  public question: MultiChoiceQuestion;
  public answerId?: number;
}
