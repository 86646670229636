import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBar,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { IconDefinition, IconLookup } from '@fortawesome/fontawesome-svg-core';
import { CommonService } from '../../../services/common.service';

@Component({
  selector: 'mec-toast',
  standalone: true,
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatButtonModule,
    FontAwesomeModule,
  ],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  public icon: IconLookup;

  constructor(
    public _commonService: CommonService,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      message: string;
      action: string;
      icon: IconDefinition;
      onActionClick: () => void;
      messageType: 'info' | 'error' | 'success';
    },
    public _snackBar: MatSnackBar
  ) {
    this.icon = data.icon;
  }

  public dismiss(): void {
    this._snackBar.dismiss();
  }
}
