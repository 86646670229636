<div class="participants-search__filter-container">
  <div
    class="participants-search__search-container field__group field__group--icon-right"
  >
    <input
      (input)="searchParticipants($event)"
      class="field__input field__input--sm"
      placeholder="{{ 'SEARCH.PLACEHOLDER' | translate }}"
    />
    <fa-icon
      class="participants-search__search-icon"
      [icon]="searchIcon"
    ></fa-icon>
  </div>
</div>
