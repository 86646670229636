import { Route } from '@angular/router';
import { ROUTES } from './routes';
import { Routes } from '@meplis/common';

export const BASE_PATH = ':region/:language';

export const appRoutes: Route[] = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/home/home.base.component').then(
        (c) => c.HomeBaseComponent
      ),
  },
  {
    path: BASE_PATH,
    children: ROUTES,
  },
];
