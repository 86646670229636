<div class="program-eqvas">
  <h4>{{ programNode?.labelText }}</h4>
  <div class="program-eqvas__range-container">
    <div *ngIf="tiers?.length" class="program-eqvas__options">
      <div
        *ngFor="let tier of tiers; let i = index"
        class="program-eqvas__option"
      >
        <div
          [ngClass]="{
            'program-eqvas__option-number-container--selected':
              i === optionSelectedIndex
          }"
          (click)="selectItem(tier.value, i)"
          class="program-eqvas__option-number-container"
        >
          <span class="program-eqvas__option-number"> {{ tier.value }} </span>
        </div>
        <span class="program-eqvas__option-description" *ngIf="i == 0">
          {{ programNode?.validation?.minDescription }}
        </span>
        <span
          class="program-eqvas__option-description program-eqvas__option-description--end"
          *ngIf="i == tiers.length - 1"
        >
          {{ programNode?.validation?.maxDescription }}
        </span>
      </div>
    </div>
  </div>
</div>
