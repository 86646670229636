import { Component, ElementRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { InstanceService } from '../../../services/instance.service';
import { CommonService } from '../../../services/common.service';
import { MepStorage } from '../../../services/abstract/mep-storage.abstract';
import { faCookieBite } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'mec-cookie-bar',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
  templateUrl: './cookie-bar.component.html',
  styleUrls: ['./cookie-bar.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0,
        })
      ),
      transition('void => *', animate(600)),
      transition('* => void', animate(100)),
    ]),
  ],
})
export class CookieBarComponent implements OnInit {
  public hubName: string;
  cookieIcon = faCookieBite;

  constructor(
    public _commonService: CommonService,
    private _el: ElementRef<HTMLElement>,
    private _instanceService: InstanceService,
    private _mepStorage: MepStorage
  ) {}

  public async ngOnInit(): Promise<void> {
    const hubInstance = await this._instanceService.getInstance();
    this.hubName = hubInstance?.slug;

    this._checkPolicyPrivacyStorage();
  }

  private _checkPolicyPrivacyStorage(): void {
    const hasAcceptedCookies = this._mepStorage.get(`${this.hubName}.read`);

    if (hasAcceptedCookies) {
      this._el.nativeElement.parentElement.removeChild(this._el.nativeElement);
    }
  }

  public acceptCookies(): void {
    this._mepStorage.set(`${this.hubName}.read`, '1');
    this._checkPolicyPrivacyStorage();
  }

  public goToPage(path: string): void {
    this._commonService.navigate([path], null, true);
  }
}
