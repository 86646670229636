import { Injectable } from '@angular/core';
import { environment } from '../../environments';
import { ApiBaseService } from './api-base.service';
import { HttpClient } from '@angular/common/http';
import {
  AppConstants,
  CommonHelper,
  PaginationOptions,
  Program,
  ProgramLog,
  ProgramStepResult,
} from '../../common';

@Injectable({
  providedIn: 'root',
})
export class ApiChainService extends ApiBaseService {
  protected apiBaseUrl = environment.chainV2;
  protected routePath = ``;

  constructor(private _httpClient: HttpClient) {
    super(_httpClient);
  }

  private _getHeaders(headers?: any) {
    const authenticationHeaders = {
      'X-Mepl-App-Platform': AppConstants.platform,
      'X-Mepl-Time-Zone': CommonHelper.getHeaderTimezone(),
    };
    if (headers) {
      return { ...authenticationHeaders, ...headers };
    }
    return { ...authenticationHeaders };
  }

  public async saveStepProgram(
    id: string,
    logs: ProgramLog[]
  ): Promise<ProgramStepResult> {
    const path = `executions/${id}/batch`;
    return super.post<ProgramStepResult>(path, logs, {
      headers: this._getHeaders(),
    });
  }

  public async getProgramById(id: string): Promise<Program> {
    let path = `executions/${id}`;
    return super.get<Program>(path, { headers: this._getHeaders() });
  }

  public getProgramCurrentStep(
    programId: string,
    logId?: string
  ): Promise<ProgramStepResult> {
    let path = `executions/${programId}/current`;

    if (logId) {
      path = `${path}/${logId}`;
    }

    return super.get<ProgramStepResult>(path, { headers: this._getHeaders() });
  }

  public async getExecutionsPending(tenantToken?: string): Promise<Program[]> {
    const path = `executions-pending`;
    let params = {};

    if (tenantToken) {
      params = {
        tenant: tenantToken,
      };
    }

    return super.get<Program[]>(path, { params });
  }

  public async getExecutionsCompleted(
    tenantToken?: string,
    paginationOptions?: PaginationOptions
  ): Promise<Program[]> {
    const path = `executions-completed`;
    let params = {};

    if (tenantToken) {
      params = {
        tenant: tenantToken,
      };
    }

    const options = paginationOptions as unknown as any;
    Object.keys(options).forEach((key: string) => {
      if (options[key] && options[key] !== '') {
        Object.assign(params, { [key]: options[key] });
      }
    });

    return super.get<Program[]>(path, { params });
  }

  public async getProgramsCount(
    tenantToken: string
  ): Promise<{ count: number }> {
    //const headers = await this._getHeaders();

    const path = `executions-pending-count`;
    let params = {};

    if (tenantToken) {
      params = {
        tenant: tenantToken,
      };
    }

    return super.get<{ count: number }>(path, { params });
  }
}
