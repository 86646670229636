import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalOptions } from '../../../common/models/modal/modal-options';
import { CommonService } from '../../../services/common.service';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'mec-modal',
  standalone: true,
  imports: [CommonModule, TranslateModule, FontAwesomeModule],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() public disableActions: boolean;
  @Input() public options: ModalOptions;

  @Output() public closeClick = new EventEmitter<void>();
  @Output() public negativeClick = new EventEmitter<void>();
  @Output() public positiveClick = new EventEmitter<void>();
  @Output() public secondaryNegativeClick = new EventEmitter<void>();
  @Output() public secondaryPositiveClick = new EventEmitter<void>();

  public closeIcon = faXmark;

  constructor(public _commonService: CommonService) {}
}
