export enum OwnContentType {
  FILE = 'FILE',
  GENERAL = 'GENERAL',

  blog = 'blog',
  booleanAnswer = 'booleanAnswer',
  campus = 'campus',
  course = 'course',
  evaluation = 'evaluation',
  event = 'event',
  folderFile = 'folderFile',
  hub = 'hub',
  libraryFile = 'libraryFile',
  libraryFolder = 'libraryFolder',
  multiChoiceQuestion = 'multiChoiceQuestion',
  multiChoiceQuestionInstance = 'multiChoiceQuestionInstance',
  pages = 'pages',
  personaEvent = 'personaEvent',
  product = 'product',
  socialNetwork = 'socialNetwork',
  socialNetworkSettings = 'socialNetworkSettings',
  socialNetworkSettingsRoles = 'socialNetworkSettingsRoles',
  step = 'step',
  stepLibrary = 'stepLibrary',
  submission = 'submission',
  surveyAnswer = 'surveyAnswer',
  surveyAnswerChoice = 'surveyAnswerChoice',
  surveyQuestion = 'surveyQuestion',
}
