import { ArticleStatus } from '../../../enums/article/article-status';
import { ArticleType } from '../../../enums/article/article-type';
import { ArticleVisibility } from '../../../enums/article/article-visibility';
import { ContentEntity } from '../content-entity';
import { Course } from '../../course/courses';
import { CpdConfigs } from '../../cpd/cpd-configs';
import { DirectionOptions } from '../../../enums/common/direction-options';
import { Evaluation } from '../../evaluation/evaluation';
import { EventType } from '../../../enums/event/event-type';
import { FileTypes } from '../../../enums/file/file-types';
import { HubEntity } from '../../hub/hub-entity';
import { Language } from '../language/language';
import { LibraryItem } from '../../library/library-item';
import { LocationEntity } from '../../event/event-location';
import { OwnContentType } from '../../../enums/common/own-content-type';
import { PaginationOptions } from '../pagination-options';
import { ProfileEntity } from '../../profile/profile-entity';
import { Step } from '../../course/step';
import { StepExamMultiChoinceInstance } from '../../evaluation/exam-multi-choice/step-exam-multi-choice-instance';
import { Tag } from '../../tags/tag';
import { UserRole } from '../../user/user-role';
import { CpdCredit } from '../../cpd/cpd-credit';
import { EventStatus } from '../../../enums/event/event-status';
import { EventFormat } from '../../../enums/event/event-format';

export class SearchResult {
  public id: number;
  public type: ArticleType;
  public ownContentType: OwnContentType;

  // Required
  public articleStatus: ArticleStatus;
  public content: ContentEntity;
  public createdAt: Date;
  public languages: Language[];
  public originLanguage: Language;
  public publishedAt: Date;
  public savedItem: boolean;
  public slug: string;
  public status: ArticleStatus;
  public tags: Tag[];
  public updatedAt: Date;
  public visibility: ArticleVisibility;

  // Event specific
  public endsAt?: Date;
  public startsAt?: Date;
  public eventType?: EventType;
  public eventStatus?: EventStatus;
  public eventFormat?: EventFormat;
  public internalWebinarId?: string; // todo(mleon): required if eventType is 'WEBINAR'
  public address?: string; // todo(mleon): required if eventType is 'PRESENTIAL'
  public location?: LocationEntity;

  // Optional
  public answered?: boolean;
  public author?: ProfileEntity;
  public children?: Step[];
  public course?: Course;
  public courseId?: boolean;
  public coverFileHash?: string;
  public cpdConfigs?: CpdConfigs[];
  public cpdCredit?: CpdCredit;
  public defaultImage?: number;
  public defaultImageHash?: string;
  public evaluations?: Evaluation[];
  public fileHash?: string;
  public fileId?: number;
  public fileType?: FileTypes;
  public folder?: LibraryItem;
  public hasAttachedLibraryFiles?: boolean;
  public hasRead?: boolean;
  public instancies?: StepExamMultiChoinceInstance[];
  public inviteeExclusivity?: boolean;
  public isOnline?: boolean;
  public lastTakenStep?: boolean;
  public libraryFileType?: string;
  public mainTag?: Tag;
  public order?: number;
  public parent?: Step;
  public parentId?: number;
  public path?: string;
  public readRoles?: UserRole[];
  public startsLocked?: boolean;
  public steps?: Step[];
  public title?: string;
  public token?: string;
  public traincloud?: HubEntity;
  public unlocked?: boolean;
  public unlockedAt?: Date;
  public url?: string;
  public writeRoles?: UserRole[];
}

export class SearchResultList {
  public searchResult: SearchResult[] = [];
  public numberOfPages: number;
  public total: number;
  pagination: PaginationOptions = {
    direction: DirectionOptions.desc,
    limit: 10,
    page: 0,
    types: 'pages,blog,event,course,libraryFile',
  };
}
