<div class="program-steps card">
  <div
    *ngFor="let step of steps; let i = index"
    class="program-steps__step-container"
    [ngClass]="{
      'program-steps__step-container--answered': currentStepIndex > i,
      'program-steps__step-container--start': i === 0
    }"
  >
    <div *ngIf="i > 0 && i < steps?.length" class="program-steps__line"></div>

    <ng-container>
      <fa-icon
        class="program-steps__icon"
        *ngIf="currentStepIndex > i"
        [icon]="checkIcon"
      ></fa-icon>
      <fa-icon
        *ngIf="currentStepIndex <= i"
        class="program-steps__icon"
        [icon]="listIcon"
      ></fa-icon>
    </ng-container>
  </div>
</div>
<div class="program-steps__title-container">
  <h5 class="program-steps__title">
    {{ 'STEP' | translate }} {{ currentStepIndex + 1 }} {{ 'OF' | translate }}
    {{ steps?.length }}
  </h5>
  <span class="program-steps__description">
    {{ stepDescription | translate }}
  </span>
</div>
