export class TagBannerItem {
  public name: string;
  public language?: string;
  public region?: string;
  public imageBackgroundHash?: string;
  public buttonLabel?: string;
  public buttonPath?: string;
  public isDarkLabel?: boolean;
  public openExternalLink?: boolean;
  public description?: string;
  public imageLoaded?: boolean;
  public imageUrl?: string;
}
