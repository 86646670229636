<div
  (click)="goToContent(); $event.stopPropagation()"
  class="card content-card"
>
  <button
    (click)="clickRemoveContent()"
    class="content-card__remove-btn btn btn--sm"
    *ngIf="isEditable"
  >
    <fa-icon [icon]="removeIcon"></fa-icon>
    <span> {{ 'REMOVE' | translate }} </span>
  </button>

  <img class="content-card__img" *ngIf="imageUrl" [src]="imageUrl" />

  <div class="content-card__container-info">
    <div class="content-card__container-type">
      <fa-icon class="content-card__icon-type" [icon]="typeIcon"></fa-icon>
      <span class="content-card__label-type">{{ typeLabel | translate }}</span>
    </div>
    <h2>{{ searchItem?.content?.title }}</h2>
    <h5 class="line-clamp-2 content-card__description">
      {{ searchItem?.content?.description }}
    </h5>
  </div>
</div>
