import { DirectionOptions } from '../../enums/common/direction-options';
import { PaginationOptions } from '../common/pagination-options';
import { ContentCourse } from './content-course';
import { Step } from './step';

export class Course {
  public id: number;
  public nextStep: number;
  public totalStep: number;
  public stepTitle: string;
  public stepProgress: number;
  public content: ContentCourse;
  public webBigSizeImage: number;
  public webBigSizeImageHash: string;
  public mobileBigSizeImage: number;
  public mobileBigSizeImageHash: string;
  public slug: string;
  public invitedOnly: boolean;
  public invitationDate: Date;
  public evaluator: boolean;
  public scholar: boolean;
  public administrator: boolean;
  public timeLimitCountdown: string;
  public timeLimitDurationInDays: string;
  public type: string;
  public currentStep: Step;
  public percentageComplete: number;
}

export class CoursesList {
  public courses: Course[] = [];
  public numberOfPages: number;
  public total: number;
  pagination: PaginationOptions = {
    direction: DirectionOptions.desc,
    limit: 10,
    page: 0,
  };
}
