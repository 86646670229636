import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments';
import { ApiBaseService } from './api-base.service';
import { PaginationOptions, SearchResult } from '../../common';
import { Hub } from '../abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class ApiNewsService extends ApiBaseService {
  protected apiBaseUrl = environment.campusV2;
  protected routePath = `hubs/${this._hubService.hub()}/news`; // : string;

  constructor(private _hubService: Hub, private _httpClient: HttpClient) {
    super(_httpClient);

    // this._hubService.onHubChange.subscribe(() => {
    //   this.routePath = `hubs/${this._hubService.hub()}/news`;
    // });
  }

  public getNews(
    paginationOptions: PaginationOptions,
    excludes?: number
  ): Promise<SearchResult[]> {
    let path = `?direction=${paginationOptions.direction}&limit=${paginationOptions.limit}&orderby=${paginationOptions.orderBy}&page=${paginationOptions.page}`;

    if (excludes) {
      path += `&excludes=${excludes}`;
    }

    return super.get<SearchResult[]>(path);
  }

  public getNewsBySlug(newsSlug: string): Promise<SearchResult> {
    const path = `${newsSlug}`;
    return super.get<SearchResult>(path);
  }
}
