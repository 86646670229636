import { Injectable } from '@angular/core';

import { ApiSavedItemsService } from './api/api-saved-items.service';
import { SearchResult } from '../common/models/common/search/search-result';
import { ToastService } from './toast.service';
import { BehaviorSubject } from 'rxjs';
import { GTMService } from './google-tag-manager.service';
import { GOOGLE_ANALYTICS } from '../common/constants/google-analytics.constants';

@Injectable({
  providedIn: 'root',
})
export class SavedItemsService {
  public _savedItem: BehaviorSubject<SearchResult> =
    new BehaviorSubject<SearchResult>(null);
  public _savedItemObservable = this._savedItem.asObservable();

  constructor(
    private _apiSavedItemsService: ApiSavedItemsService,
    private _gtmService: GTMService,
    private _toastService: ToastService
  ) {}

  public async toggleSavedItem(item: SearchResult): Promise<SearchResult> {
    const { id, ownContentType: mod, savedItem } = item;

    try {
      await this._apiSavedItemsService[
        savedItem ? 'removeSavedItem' : 'addSavedItem'
      ](id, mod);

      item.savedItem = !savedItem;
      this._savedItem.next(item);

      this._toastService.showSuccess('FEEDBACK_SAVED_ITEMS_REMOVE_SUCCESS');

      // mleon(): todo normalize with GOOGLE_ANALYTICS constants
      this._gtmService.tagEvent({
        event_category: GOOGLE_ANALYTICS.events.selectContent,
        event_label: savedItem ? 'remove' : 'add',
        value: savedItem ? '0' : '1',
      });
    } catch (errorSavedItem) {
      console.log(errorSavedItem);

      this._toastService.showError('FEEDBACK_SAVED_ITEMS_ADD_ERROR');
    }

    return item;
  }
}
