<mec-modal
  [options]="modalOptions"
  (positiveClick)="subscribeCourse()"
  [disableActions]="enrollForm.invalid || isSaving"
  (closeClick)="matDialogRef.close(false)"
>
  <form [formGroup]="enrollForm" *ngIf="!isLoading" class="course-subscribe">
    <h3>{{ 'ENROLL_AGREEMENT_INTRO' | translate }}</h3>

    <div class="course-subscribe__terms field__group">
      <mat-checkbox formControlName="agreement">
        <p>{{ 'ENROLL_AGREEMENT_LABEL' | translate }}</p>
        <div class="course-subscribe__check-documents">
          <a (click)="goToPage(item.href)" *ngFor="let item of legalDocuments">
            <small class="label label--centered label--underlined">{{
              item.title | translate
            }}</small>
          </a>
        </div>
      </mat-checkbox>
    </div>
  </form>

  <!-- Skeleton loader start -->
  <ng-container *ngIf="isLoading">
    <h3 style="height: 30px" [medSkeletonLoading]="true">Text text</h3>
    <div [medSkeletonLoading]="true" style="height: 100px; margin-top: 12px">
      Text
    </div>
  </ng-container>
  <!-- Skeleton loader end -->
</mec-modal>
