import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { InstanceService } from './instance.service';
import { Hub } from './abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  private title: string;
  private subTitle: string;
  private _renderer2: Renderer2;

  constructor(
    private _titleService: Title,
    private _translateService: TranslateService,
    private _instanceService: InstanceService,
    private _location: Location,
    private _hubService: Hub,
    private meta: Meta,
    private _rendererFactory2: RendererFactory2,
    @Inject(DOCUMENT) private dom: Document
  ) {
    this._renderer2 = this._rendererFactory2.createRenderer(null, null);
  }

  public async updateSEO(
    title: string,
    appendHubTitle?: boolean,
    description?: string,
    imageUrl?: string,
    noIndex?: boolean
  ): Promise<void> {
    if (appendHubTitle && title) {
      title = this._translateService.instant(title);

      const instance = await this._instanceService.getInstance();
      title = `${title} | ${instance?.content?.title}`;
    }

    this.removeNoIndexMetas();
    this.setTitle(title);

    if (description && description !== '') {
      this.setDescription(description);
    }

    if (imageUrl && imageUrl !== '') {
      this.setImage(imageUrl);
    }

    this.setUrl();

    if (noIndex) {
      this.addNoIndexMetas();
    }
  }

  public setSiteName(siteName: string): void {
    const completeUrl = [this._hubService.host(), this._location.path()].join(
      ''
    );

    let script = this._renderer2.createElement('script');
    script.type = 'application/ld+json';
    script.text = `{
      "@context" : "https://schema.org",
      "@type" : "WebSite",
      "name" : "${siteName}",
      "url" : "${completeUrl}"
    }`;

    this._renderer2.appendChild(this.dom.head, script);
  }

  public setTitle(title: string): void {
    this.title = title;

    title = [this.subTitle, this.title].filter((t) => t).join(' - ');

    this._titleService.setTitle(title);
    this._setMeta('title', title);
  }

  public setSubTitle(subTitle: string): void {
    this.subTitle = subTitle;

    this.setTitle(this.title);
  }

  public setDescription(description: string): void {
    const tags: Array<string> = [
      'description',
      'og:description',
      'twitter:description',
    ];

    tags.forEach((tag) => {
      this._setMeta(tag, description);
    });
  }

  public setImage(imageUrl: string): void {
    const tags: Array<string> = ['twitter:image', 'og:image'];

    tags.forEach((tag) => {
      this._setMeta(tag, imageUrl);
    });
  }

  public setUrl(url?: string): void {
    const tags: Array<string> = ['twitter:url', 'og:url'];
    let completeUrl: string = url;

    if (!completeUrl) {
      completeUrl = [this._hubService.host(), this._location.path()].join('');
    }

    tags.forEach((tag) => {
      this._setMeta(tag, completeUrl);
    });
    this._setLinkCanonical(completeUrl);
  }

  public addNoIndexMetas(): void {
    this._setMeta('robots', 'noindex');
    this._setMeta('googlebot', 'noindex');
  }

  public removeNoIndexMetas(): void {
    const existingRobotsMeta: HTMLMetaElement =
      this.meta.getTag(`name="robots"`);
    if (existingRobotsMeta) {
      this.dom.head.removeChild(existingRobotsMeta);
    }

    const existingGoogleBotMeta: HTMLMetaElement =
      this.meta.getTag(`name="googlebot"`);
    if (existingGoogleBotMeta) {
      this.dom.head.removeChild(existingGoogleBotMeta);
    }
  }

  public setFavicon(url: string): void {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = url;
    this.dom.head.appendChild(link);
  }

  private _setLinkCanonical(url: string): void {
    const canonicalId = 'canonical-link';
    const existingCanonical: HTMLLinkElement = this.dom.getElementById(
      canonicalId
    ) as HTMLLinkElement;

    if (existingCanonical) {
      this.dom.head.removeChild(existingCanonical);
    }

    const link: HTMLLinkElement = this.dom.createElement('link');
    link.id = canonicalId;
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);
    this.dom.head.appendChild(link);
  }

  private _setMeta(name: string, content: string): void {
    const existingMeta: HTMLMetaElement = this.meta.getTag(`name="${name}"`);
    const newMeta: MetaDefinition = { name, content };
    if (existingMeta) {
      this.meta.updateTag(newMeta);
    } else {
      this.meta.addTag(newMeta);
    }
  }
}
