import { UserPresets } from "./user-presets";

export class UserRole {
  public id: number;
  public name: string;
  public permissions: string[];
  public updatedAt: Date;
  public createdAt: Date;
  public scholar: boolean;
  public evaluator: boolean;
  public administrator: boolean;
  public userPresets: UserPresets;
  public grade: number;
}