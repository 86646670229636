<mec-modal [options]="modalOptions" (closeClick)="closeModal()">
  <div class="image-viewer">
    <div
      style="width: 90%; height: 70vh"
      *ngIf="isLoadingSkeleton"
      [medSkeletonLoading]="true"
    ></div>

    <div *ngIf="!isLoadingSkeleton" class="image-viewer__container">
      <button
        class="image-viewer__arrow image-viewer__arrow--left btn btn--no-bg btn--icon"
        [ngClass]="{ 'image-viewer__arrow--hidden': currentFileIndex === 0 }"
        (click)="$event.stopPropagation(); onLeftClick()"
      >
        <fa-icon class="image-viewer__arrow-icon" [icon]="leftIcon"></fa-icon>
      </button>

      <div class="image-viewer__slider-area">
        <div class="fade-slider">
          <div class="fade-slider__container">
            <div
              class="fade-slider__slide-item"
              *ngFor="let file of data?.files; let i = index"
              [ngClass]="{
                'fade-slider__slide-item--active':
                  data?.files[currentFileIndex] === file
              }"
            >
              <img
                (load)="isLoading = false"
                *ngIf="file.type === 'IMAGE'"
                class="fade-slider__file"
                (load)="isLoading = false"
                src="{{
                  file?.url +
                    '?quality=720&width=1024&access_token=' +
                    accessToken
                }}"
              />
              <video
                *ngIf="file.type === 'VIDEO'"
                class="fade-slider__file"
                controls
              >
                <source
                  (load)="isLoading = false"
                  type="{{ 'video/mp4' }}"
                  src="{{
                    file.url +
                      '?quality=720&width=1024&format=mp4&access_token=' +
                      accessToken
                  }}"
                />
              </video>
            </div>
          </div>
        </div>
      </div>

      <button
        class="image-viewer__arrow image-viewer__arrow--right btn btn--no-bg btn--icon"
        [ngClass]="{
          'image-viewer__arrow--hidden':
            currentFileIndex === data.files.length - 1
        }"
        (click)="$event.stopPropagation(); onRightClick()"
      >
        <fa-icon class="image-viewer__arrow-icon" [icon]="rightIcon"></fa-icon>
      </button>
    </div>
  </div>
</mec-modal>
