import { Injectable } from '@angular/core';
import { environment } from '../../environments';
import { ApiBaseService } from './api-base.service';
import {
  AuthenticationPasswordValidation,
  CommonHelper,
  LoginCredentials,
  LoginResult,
} from '../../common';

@Injectable({
  providedIn: 'root',
})
export class ApiAuthenticationV1Service extends ApiBaseService {
  protected apiBaseUrl = environment.apiUrl as string;
  protected routePath = 'authorization/v1/';

  private getHeaders(headers?: any) {
    const authenticationHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Mepl-Client-Token': environment.authentication.clientToken,
      'X-Mepl-Time-Zone': CommonHelper.getHeaderTimezone(),
    };
    if (headers) {
      return { ...authenticationHeaders, ...headers };
    }
    return { ...authenticationHeaders };
  }

  public loginWithPassword(
    credentials: LoginCredentials
  ): Promise<LoginResult> {
    const headers = this.getHeaders();
    const params = {
      grantType: 'password',
      username: credentials.username,
      password: credentials.password,
    };

    return super.post<LoginResult>('open/login', params, { headers });
  }

  public loginRefreshToken(refreshToken: string): Promise<LoginResult> {
    const headers = this.getHeaders();
    const params = {
      grantType: 'refresh_token',
      refreshToken: refreshToken,
    };

    return super.post<LoginResult>('open/login', params, { headers });
  }

  public logout(token: string): Promise<LoginResult> {
    const headers = this.getHeaders();
    const params = {
      token: token,
    };

    return super.post<LoginResult>('open/logout', params, { headers });
  }

  public changePassword(password: string, token: string): Promise<void> {
    const headers = this.getHeaders();
    const params = {
      password: password,
      token: token,
    };

    return super.post('open/profile/password/reset', params, { headers });
  }

  public checkAccessToken(token: string): Promise<void> {
    const headers = this.getHeaders();
    const params = {
      token: token,
    };

    return super.post('open/check/token', params, { headers });
  }

  public getPasswordScore(
    password: string
  ): Promise<AuthenticationPasswordValidation> {
    const headers = this.getHeaders();
    const params = {
      password: password,
    };

    return super.post('open/profile/password/validator', params, { headers });
  }

  public getRegistrationToken(): Promise<string> {
    const headers = this.getHeaders();

    return super.get<string>('open/registration', {
      headers,
      responseType: 'text',
    });
  }

  public requestPasswordChange(email: string): Promise<void> {
    const headers = this.getHeaders();
    const params = {
      email: email,
    };

    return super.post('open/profile/password/request', params, { headers });
  }
}
