import { Injectable, NgZone, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AlertComponent } from '../components/common/alert/alert.component';
import { AlertData } from '../common/models/common/alert/alert-data';
import { AlertButton, AlertController } from '@ionic/angular';
import { MepActionButton } from '../common';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private _alertController: any;

  constructor(
    private _platformService: PlatformService,
    private _zone: NgZone,
    public translateService: TranslateService
  ) {
    if (this._platformService.platform === 'mobile') {
      this._alertController = inject(AlertController);
    } else {
      this._alertController = inject(MatDialog);
    }
  }

  public async showAlert(title: string, message: string): Promise<any> {
    return this._zone.run(() => {
      if (this._platformService.platform === 'mobile') {
        return this.showConfirmAlert(message);
      }

      const openedDialog = this._alertController.open(AlertComponent, {
        data: {
          title,
          message,
          type: 'alert',
          neutralAction: 'OK',
        } as AlertData,
      });

      return new Promise((resolve, reject) => {
        openedDialog.afterClosed().subscribe((response: any) => {
          resolve(true);
          reject();
        });
      });
    });
  }

  public async showError(title: string, message: string): Promise<any> {
    if (this._platformService.platform === 'mobile') {
      return this.showConfirmAlert(message);
    }

    const openedDialog = this._alertController.open(AlertComponent, {
      data: {
        title,
        message,
        type: 'error',
        neutralAction: 'OK',
      } as AlertData,
    });

    return new Promise((resolve, reject) => {
      openedDialog.afterClosed().subscribe((response: any) => {
        resolve(response);
        reject();
      });
    });
  }

  public async showConfirmation(
    title: string,
    message: string,
    positiveLabel: string,
    negativeLabel: string
  ): Promise<any> {
    positiveLabel = this.translateService.instant(positiveLabel);
    negativeLabel = this.translateService.instant(negativeLabel);

    if (this._platformService.platform === 'mobile') {
      return this.showConfirmationAlert(title, positiveLabel, negativeLabel);
    }

    const openedDialog = this._alertController.open(AlertComponent, {
      data: {
        title,
        message,
        type: 'confirmation',
        negativeAction: negativeLabel,
        positiveAction: positiveLabel,
      } as AlertData,
    });

    return new Promise((resolve, reject) => {
      openedDialog.afterClosed().subscribe((response: any) => {
        resolve(response);
        reject();
      });
    });
  }

  //mobile methods
  public async showConfirmationAlert(
    message: string,
    positiveLabel: string,
    negativeLabel: string
  ): Promise<boolean> {
    const buttons: MepActionButton[] = [
      {
        text: positiveLabel,
        role: 'save',
      },
      {
        text: negativeLabel,
        role: 'cancel',
      },
    ];

    const result = await this.showActionsAlert(message, buttons);

    return result.role === 'save';
  }

  public async showActionsAlert(
    message: string,
    buttons: MepActionButton[]
  ): Promise<any> {
    const buttonsAlert: AlertButton[] = [];

    buttons.forEach((button) => {
      buttonsAlert.push({
        text: button.text,
        role: button.role,
      });
    });

    const alert = await this._alertController.create({
      message: this.translateService.instant(message),
      buttons: buttons,
    });

    await alert.present();

    const result = await alert.onDidDismiss();
    return result;
  }

  public async showConfirmAlert(message: string): Promise<any> {
    const alert = await this._alertController.create({
      message: this.translateService.instant(message),
      buttons: ['Ok'],
    });

    await alert.present();

    const result = await alert.onDidDismiss();
    return result;
  }
}
