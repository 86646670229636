import { Injectable } from '@angular/core';
import { Preferences, PreferencesPlugin } from '@capacitor/preferences';
import { MepStorage } from './abstract/mep-storage.abstract';

@Injectable({
  providedIn: 'root',
})
export class StorageMobileService implements MepStorage {
  private _storage: PreferencesPlugin = Preferences;

  public async get(key: string): Promise<string> {
    const result = await Preferences.get({ key });
    return result.value;
  }
  public set(key: string, value: string): void {
    Preferences.set({ key, value });
  }
  public remove(key: string): void {
    Preferences.remove({ key });
  }
  public removeItems(keys: any[]): void {
    keys.forEach(async (key) => {
      await this.remove(key);
    });
  }
  setItems(items: { [key: string]: any }): void {
    Object.keys(items).forEach((key) => {
      const value = items[key].toString();
      this.set(key, value);
    });
  }
}
