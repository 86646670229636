import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[medSkeletonLoading]',
  standalone: true,
})
export class SkeletonDirective implements OnChanges {
  @Input() medSkeletonLoading: boolean;
  @Input() isDark: boolean;

  constructor(private el: ElementRef<HTMLElement>) {}

  ngOnChanges(): void {
    if (this.medSkeletonLoading) {
      this.el.nativeElement.classList.add('skeleton');
    } else {
      this.el.nativeElement.classList.remove('skeleton');
    }

    if (this.isDark) {
      this.el.nativeElement.classList.add('skeleton--dark');
    } else {
      this.el.nativeElement.classList.remove('skeleton--dark');
    }
  }
}
