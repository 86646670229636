import { Pipe, PipeTransform } from '@angular/core';
import { CommonHelper } from '../common/helpers/common.helper';
import { FileParams } from '../common/models/files/file-params';

@Pipe({ name: 'cdnImages', standalone: true })
export class CdnImages implements PipeTransform {
  public transform(idOrHash: number | string, params: FileParams): any {
    if (!idOrHash) return idOrHash;
    return CommonHelper.getFileUrl(idOrHash, params);
  }
}
