import { StepType } from '../../enums/course/step-type';
import { Step } from '../course/step';
import { Evaluation } from './evaluation';
import { MultiChoiceQuestion } from './exam-multi-choice/multi-choice-question';
import { StepSubmissionExam } from './exam/step-submission-exam';
import { EvaluationStatus } from '../../enums/evaluation/evaluation-status';
import { Profile } from '../profile/profile';
import { Evaluator } from './evaluator';
import { Course } from '../course/courses';

export declare class StepInstance {
  public submissions?: StepSubmissionExam[];
  public questions?: MultiChoiceQuestion[];
  public course?: Course;
  public id: number;
  public type: StepType;
  public step: Step;
  public status: EvaluationStatus;
  public evaluations?: Evaluation[];
  public profile?: Profile;
  public evaluator: Evaluator;
  public startedAt: Date;
  public finishedAt: Date;
  public evaluatedAt: Date;
  public score?: number;
  public isTransient?: boolean;
  public average?: number;
  public feedback?: string;
}
