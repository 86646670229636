import { EventStatus } from '../../enums/event/event-status';
import { EventBackgroundContent } from './event-background-content';
import { EventParticipant } from '../common/event-participant';
import { EventFootnote } from './event-footnote';
import { SessionWebinar } from './session-webinar';

export class TemplateWebinarObject {
  public sessions?: SessionWebinar[];
  public numberCOL?: string;
  public currentSessionIndex?: number = 0;
  public hideBanner?: boolean;
  public bannerUrl?: string;
  public dateLabel?: string;
  public date?: string;
  public address?: string;
  public backgroundContent?: EventBackgroundContent;
  public participants?: EventParticipant[];
  public footnotes?: EventFootnote[];
}

//Full template example
/*

  public templateObject: TemplateWebinarObject = {
    //todo remove after
    currentSessionIndex: 2,
    sessions: [
      {
        eventStatus: EventStatus.ONDEMAND,
        audios: {
          description: 'Live Audio',
          items: [
            {
              description: 'English',
              language: 'en',
              vodId: 221088,
            },
            {
              description: 'German',
              language: 'de',
              vodHash:
                '8113d476661628fc44753056a126e5628b78f066c013ecf82116f9b5186fd769',
            },
            {
              description: 'French',
              language: 'fr',
              vodHash:
                '8113d476661628fc44753056a126e5628b78f066c013ecf82116f9b5186fd769',
            },
          ],
        },
        isDisabled: false,
        title: 'Session 1',
      },
      {
        eventStatus: EventStatus.ONDEMAND,
        audios: {
          description: 'Live Audio',
          items: [
            {
              description: 'English',
              language: 'en',
              vodId: 2105306,
            },
          ],
        },
        isDisabled: false,
        title: 'Session 2',
      },
      {
        eventLiveUrl: {
          src: 'https://cph-msl.akamaized.net/hls/live/2000341/test/master.m3u8',
          type: 'application/x-mpegURL',
        },
        eventLiveIsMultilanguage: false,
        eventStatus: EventStatus.LIVE,
        isDisabled: false,
        title: 'Session 3',
      },
      {
        eventStatus: EventStatus.ONDEMAND,
        audios: {
          description: 'Live Audio',
          items: [
            {
              description: 'English',
              language: 'en',
              vodId: null,
            },
          ],
        },
        isDisabled: true,
        title: 'Session 4',
      },
      {
        eventStatus: EventStatus.ONDEMAND,
        audios: {
          description: 'Live Audio',
          items: [
            {
              description: 'English',
              language: 'en',
              vodId: null,
            },
          ],
        },
        isDisabled: true,
        title: 'Session 5',
      },
    ],

    hideBanner: false,
    eventStatus: EventStatus.LIVE,

    bannerUrl:
      'https://api.meplis.com/files/v1/2069289/view?format=thumbnail&quality=50',
    date: 'Event on 04/02/2023 13:00 &mdash; 19:00 CET',
    backgroundContent: {
      sessions: [
        {
          title: 'Welcome and Course Objectives',
          description: '',
          content: `<p><strong>Introduction</strong>: Prof. David Taggart</p>
                  <ul>
                    <li><i>13:00 &mdash; 13:05</i>: Prof. David Taggart</li>
                    <li>
                      <strong>TIMEZONE</strong>: CET (Central European Time [GMT
                      +2])
                    </li>
                  </ul>`,
        },
        {
          title: 'Session 1',
          description: 'Evidence Basis, Drivers, Patient Selection',
          buttonText: 'WATCH_NOW',
          buttonVideoId: 221088,
          buttonIcon: 'fa-circle-play',
          buttonDisabled: false,
          content: `<p><strong>Moderator</strong>: Prof. David Taggart</p>
                  <ul>
                    <li>
                      <i>13:10 &mdash; 13:25</i>
                      <strong>Prof. David Taggart</strong>: Evidence as a driver
                      for OPCAB in 2022
                    </li>
                    <li>
                      <i>13:25 &mdash; 13:40</i>
                      <strong>Panel discussion</strong>: Patient selection and
                      preparation for an OPCAB approach
                      <ul>
                        <li>Prof. David Taggart</li>
                        <li>Prof. John Puskas</li>
                        <li>Prof. Paul Sergeant</li>
                        <li>Prof. Alexander Albert</li>
                        <li>Dr. Hiroshi Niinami</li>
                      </ul>
                    </li>
                    <li>
                      <i>13:40 &mdash; 14:00</i>
                      <strong>Questions and Answers</strong>
                    </li>
                  </ul>`,
        },
        {
          title: 'Session 2',
          description: 'Anaesthesia monitoring and management',
          buttonText: 'WATCH_NOW',
          buttonVideoId: 2105306,
          buttonIcon: 'fa-circle-play',
          buttonDisabled: false,
          content: `<p><strong>Moderator</strong>: Prof. John Puskas</p>
                  <ul>
                    <li>
                      <i>14:05 &mdash; 14:30</i>
                      <strong>Prof. Stephan Urs Sixt</strong>: Anaesthetic
                      management for the OPCAB procedure
                      <ul>
                        <li>Temperature management</li>
                        <li>Anaesthesia</li>
                        <li>Hemodynamic monitoring</li>
                        <li>Ischaemia prevention</li>
                        <li>Optimal filling and responses</li>
                        <li>Arrythmia</li>
                        <li>Inotropia</li>
                        <li>Anticoagulation</li>
                      </ul>
                    </li>
                    <li>
                      <i>14:30 &mdash; 14:45</i>
                      <strong>Questions and Answers</strong>
                    </li>
                  </ul>`,
        },
        {
          title: 'Session 3',
          description: 'Visualization, enucleation, and stabilization',
          content: `<p><strong>Moderator</strong>: Dr. Hiroshi Niinami</p>
                  <ul>
                    <li>
                      <i>14:45 &mdash; 14:55</i>
                      <strong>Prof. David Taggart</strong>: Introduction
                      <ul>
                        <li>Pericardial incision</li>
                        <li>Visualization</li>
                        <li>Enucleation</li>
                        <li>Stabilization</li>
                        <li>Incision</li>
                        <li>Shunting</li>
                        <li>Anastomosis</li>
                      </ul>
                    </li>
                    <li>
                      <i>14:55 &mdash; 15:05</i>
                      <strong>Prof. John Puskas</strong>: General Surgical
                      Principles and Preparation for OPCABG
                    </li>
                    <li>
                      <i>15:05 &mdash; 15:20</i>
                      <strong>Prof. Alexander Albert</strong>: The visualization
                      and stabilization of the anterior wall
                    </li>
                    <li>
                      <i>15:20 &mdash; 15:35</i>
                      <strong>Prof. Paul Sergeant</strong>: The visualization,
                      enucleation, and stabilization of the lateral wall
                    </li>
                    <li>
                      <i>15:35 &mdash; 15-50</i>
                      <strong>Prof. John Puskas</strong>: The visualization,
                      enucleation, and stabilization of the inferior wall
                    </li>
                    <li>
                      <i>15:50 &mdash; 16:10</i>
                      <strong>Questions and Answers</strong>
                    </li>
                  </ul>`,
        },
        {
          title: 'Session 4',
          description:
            'Grafts: selection, techniques, an-aortic stroke prevention',
          content: `<p><strong>Moderator</strong>: Prof. Alexander Albert</p>
                  <ul>
                    <li>
                      <i>16:20 &mdash; 16:30</i>
                      <strong>Prof. David Taggart</strong>: Graft selection and
                      distribution for OPCAB
                    </li>
                    <li>
                      <i>16:30 &mdash; 16:40</i>
                      <strong>Prof. John Puskas</strong>: Clampless or An-aortic
                      OPCAB and impact on outcomes
                    </li>
                    <li>
                      <i>16:40 &mdash; 16:50</i>
                      <strong>Prof. Alexander Albert</strong>: An-aortic (no
                      touch) OPCAB and how to do it
                    </li>
                    <li>
                      <i>16:50 &mdash; 17:00</i>
                      <strong>Dr. Hiroshi Niinami</strong>: Japanese perspective
                      on OPCABG
                    </li>
                    <li>
                      <i>17:00 &mdash; 17:10</i>
                      <strong>Prof. David Taggart</strong>: Fundamentals of TTFM
                      and High Frequency Ultrasound
                    </li>
                    <li>
                      <i>17:10 &mdash; 17:20</i>
                      <strong>Prof. Paul Sergeant</strong>: Anastomotic learning
                      &mdash; simulation and assessments
                    </li>
                    <li>
                      <i>17:20 &mdash; 17:40</i>
                      <strong>Questions and Answers</strong>
                    </li>
                  </ul>`,
        },
        {
          title: 'Session 5',
          description: 'S.O.P., Evidence, Next steps in OPCAB',
          content: `<p><strong>Moderator</strong>: Prof. Paul Sergeant</p>
                  <ul>
                    <li>
                      <i>17:40 &mdash; 17:55</i>
                      <strong>Prof. Paul Sergeant</strong>: Standard Operating
                      Procedures &mdash; Conversion
                    </li>
                    <li>
                      <i>17:55 &mdash; 18:10</i>
                      <strong>Prof. Alexander Albert</strong>: MIDCAB strategies
                      and priorities. A personalized approach
                    </li>
                    <li>
                      <i>18:10 &mdash; 18:25</i> <strong>Faculty</strong>: Wrap
                      up
                    </li>
                    <li>
                      <i>18:25 &mdash; 18:40</i>
                      <strong>Questions and Answers</strong> and
                      <strong>Panel Discussion</strong>
                    </li>
                  </ul>`,
        },
        {
          title: 'Closing Statements',
          content: `<p><strong>Acknowledgement</strong>: Prof. John Puskas</p>
                  <ul>
                    <li><i>18:55 &mdash; 19:00</i>: Prof. John Puskas</li>
                    <li>
                      <strong>TIMEZONE</strong>: CET (Central European Time [GMT
                      +2])
                    </li>
                  </ul>`,
        },
      ],
    },

    participants: [
      {
        country: 'BELGIUM',
        biography:
          'Has a medical degree from RU Gent (1974) and a business degree from KU Leuven (1993) in Belgium. He became a general and cardiothoracic surgeon in 1980 after a one-year fellowship in the USA (ECFMG 226-374-7) and one in the Netherlands. He was a staff surgeon in CV surgery at KU Leuven in Belgium from 1980-2014 and became full professor (1994) after a PhD thesis on Hazard Analysis (1988). His research focused on “Mathematical Modeling in Medicine” and his clinical activity on “Adult Cardiac Surgery”. Professor Sergeant proctored several thousand surgeons in over 1000 three-day seminars worldwide and lectured in more than 1000 international lectures. He became president of EACTS and was founder and president of CTSnet. Prof. Sergeant has been an advisor in micro and macroeconomics, quality auditor, management of knowledge. He also has an extensive peer-reviewed publication list covering these domains. His most recent worldwide project is called “My Virtual Surgery” with live, webinar and online simulation courses (&gt;600 x), more than 5500 scholars from &gt;150 countries, and three different curricula of cardiovascular surgery. The “My Virtual Surgery” project complies to the strictest to the science of learning with low fidelity simulation, wiki-designed simulators and qualitative as well as quantitative OSATS-based guidance',
        name: 'Prof. Paul Sergeant',
        imageHash:
          '5ba387d3c9bb6970b9c18004020885ea2c33fc79e14cfac4e4d5c3d4eab43b8d',
        recordings: [
          {
            description: 'The Value of point-of-care testing in Diabetes',
            timecode: 0 * 60 * 60 + 0 * 60 + 1,
            videoId: 221088,
          },
        ],
        role: 'Chair',
        subject: '',
      },
      {
        country: 'UK',
        biography:
          'Prof. David Taggart qualified from Glasgow University in 1981. After clinical training in Glasgow, Paris, Newcastle and London (under Prof Sir Magdi Yacoub and Mr Christopher Lincoln at the Royal Brompton Hospital) he was appointed as Consultant Cardiothoracic Surgeon at the John Radcliffe Hospital in Oxford in 1995. In 2004 he was appointed as Professor of Cardiovascular Surgery at the University of Oxford. He has two higher academic degrees (MD awarded with Honours 1989 and a PhD in 2000) and has conducted over 20 original scientific trials/studies and published over 340 peer- reviewed scientific manuscripts with a high proportion with original research data. On an annual basis he gives between 10-15 major international lectures. His main research focus has been on coronary revascularization and with specific reference to the evidence base for comparison to stents, multiple arterial grafts, off-pump CABG, quality assessment and the use of external stents for saphenous vein bypass grafts. He id the PI for the ART trial a randomized of over 3100 patients allocated to single or bilateral ITA grafts and now being analysed after 15 years of follow-up. He is the co-editor of two major textbooks of cardiac surgery (i) Core Concepts in cardiac Surgery and (ii) State of the Art Surgical Myocardial Revascularization. Along with Dr John Puskas he is the co-founder of the International Coronary Congress (ICC) and the International Society for Coronary Artery Surgery (ISCAS). He is also the Director of the Masterclass for Off-Pump CABG (sponsored by Medtronic).',
        name: 'Prof. David Taggart',
        imageHash:
          '867c003cdb84bf33b36af568cc33df53e4b7db62f9f96b5ab3667d3e94724e33',
        recordings: [
          {
            description: 'Test English',
            timecode: 0 * 60 * 60 + 4 * 60 + 12,
            videoId: 221088,
          },
          {
            description: 'Test French',
            timecode: 0 * 60 * 60 + 4 * 60 + 24,
            videoId: 221088,
          },
        ],
        role: 'Speaker',
        subject: '',
      },
      {
        country: 'GERMANY',
        biography:
          'Is a consultant in cardiac anaesthesiology at the University of Düsseldorf. He is the Supervisor of the Cardiac Anaesthesiology in Düsseldorf and one of the leaders of the OPCA BG training course in Düsseldorf with Prof. Alexander Albert. He has a keen interest in patient blood management and has established with Dr. Albert the SUBITO (Speed-Up Bleeding Investigation for Therapeutic Optimization) project, an innovative personalised patient blood management method in cardiac surgery.',
        name: 'Prof. Stephan Urs Sixt',
        imageHash:
          'c193f6670740858e688eb308dd19ba2fbface96868c5b137fb22716544b08946',
        recordings: [],
        role: 'Speaker',
        subject: '',
      },
      {
        country: 'GERMANY',
        biography:
          'Is a professor of cardiac surgery and the current director of the department of Cardiac Surgery at the Klinikum Dortmund, Germany. Early in his career, professor Albert was strongly influenced by the school of Professor Paul Sergeant in Belgium. He has an internationally recognized experience in Aortic No-Touch Off-Pump (Anaortic OPCAB) and minimal invasive bypass surgery (MICSCABG). His major focus of clinical and scholarly activities lies in the development and design of individualized surgical therapy for patients with coronary artery disease. Professor Albert draws on wide experience in several managerial positions creating and implementing new departmental processes. Additionally, he has been the head of the Medtronic International Training Center for OPCAB and MICS-CABG since its establishment in 2010 at the University of Düsseldorf and currently at the Klinikum Dortmund.',
        name: 'Prof. Alexander Albert',
        imageHash:
          '1cef34cfbca7ed55658336d62be4362f8af4d567fca39753aefffce5b108f0fe',
        recordings: [],
        role: 'Speaker',
        subject: '',
      },
      {
        country: 'JAPAN',
        biography:
          'Professor Hiroshi Niinami is the Professor of the Department of Cardiovascular Surgery at Tokyo Women’s Medical University. He finished his residency in Cardiac Surgery at Tokyo Women’s Medical University Hospital, and had research fellowship at Wayne State University School of Medicine in Detroit, before he worked at Alfred Hospital, Melbourne and Royal North Shore Hospital, Sydney in Australia. Prof. Niinami was Associate professor at Juntendo University in Tokyo and the Professor and Chairman of the Department of Cardiovascular Surgery at Saitama Medical University International Medical Center at Saitama, Japan. During his tenure of Saitama, he grew the institution as one of the biggest heart centers in Japan covering TAVR, minimally invasive Mitral Valve surgery, LVAD and transplant. Prof. Niinami is particularly expert at doing off-pump CABG with arterial grafts.',
        name: 'Prof. Hiroshi Niinami',
        imageHash:
          '31b70445a38837f6cfb3dd3effd994d2b24ac9e47224a12cc9117b6b21e0592a',
        recordings: [],
        role: 'Speaker',
        subject: '',
      },
      {
        country: 'USA',
        biography:
          'A pioneer and leader in beating heart CABG, is Professor of Cardiovascular Surgery in New York City at the Icahn School of Medicine at Mount Sinai, Director of both the Cardiovascular Clinical Research Unit and Coronary Revascularization Reference Center, Mount Sinai Health System. He is Chairman of Cardiovascular Surgery at Mount Sinai Morningside, Mount Sinai Downtown and Mount Sinai West Hospitals. Prof. Puskas previously held positions as Chief of Cardiothoracic Surgery at Emory University Hospital Midtown for ten years, where he completed his cardiothoracic residency and fellowship. He has and continues to contribute to multiple committees regarding AATS/STS, ISMICS, NHLBI and is the founder and co-director of the International Coronary Congress. Prof. Puskas has published over 250 peer-reviewed articles and contributed to more than 20 book chapters regarding cardiovascular surgery and holds multiple patents. Together with Prof. Taggart, he is co-Editor of the Oxford University Press textbook, State of the Art Surgical Coronary Revascularization, to be published in Q4, 2020.',
        name: 'Prof. John Puskas',
        imageHash:
          '4636c55f1a95b7cab0f92662f100ad5e9a29ea8f384069c3ee89bc9923f04e00',
        recordings: [],
        role: 'Speaker',
        subject: '',
      },
    ],
  };

*/
