export const GOOGLE_ANALYTICS = {
  events: {
    addPaymentInfo: 'add_payment_info',
    addShippingInfo: 'add_shipping_info',
    addToCart: 'add_to_cart',
    addToWishlist: 'add_to_wishlist',
    beginCheckout: 'begin_checkout',
    earnVirtualCurrency: 'earn_virtual_currency',
    generateLead: 'generate_lead',
    joinGroup: 'join_group',
    levelEnd: 'level_end',
    levelStart: 'level_start',
    levelUp: 'level_up',
    login: 'login',
    postScore: 'post_score',
    purchase: 'purchase',
    refund: 'refund',
    removeFromCart: 'remove_from_cart',
    savedItem: 'saved_item',
    search: 'search',
    selectContent: 'select_content',
    selectItem: 'select_item',
    selectPromotion: 'select_promotion',
    share: 'share',
    signUp: 'sign_up',
    spendVirtualCurrency: 'spend_virtual_currency',
    tutorialBegin: 'tutorial_begin',
    tutorialComplete: 'tutorial_complete',
    unlockAchievement: 'unlock_achievement',
    viewCart: 'view_cart',
    viewItem: 'view_item',
    viewItemList: 'view_item_list',
    viewPromotion: 'view_promotion',
  },
  parameters: {
    checkoutOption: 'checkout_option',
    checkoutStep: 'checkout_step',
    coupon: 'coupon',
    creativeName: 'creative_name',
    creativeSlot: 'creative_slot',
    currency: 'currency',
    discount: 'discount',
    groupId: 'group_id',
    index: 'index',
    itemBrand: 'item_brand',
    itemCategory: 'item_category',
    itemCategory2: 'item_category2',
    itemCategory3: 'item_category3',
    itemCategory4: 'item_category4',
    itemCategory5: 'item_category5',
    itemId: 'item_id',
    itemListId: 'item_list_id',
    itemListName: 'item_list_name',
    itemLocationId: 'item_location_id',
    itemName: 'item_name',
    itemVariant: 'item_variant',
    location: 'location',
    price: 'price',
    promotionId: 'promotion_id',
    promotionName: 'promotion_name',
    quantity: 'quantity',
    score: 'score',
    searchTerm: 'search_term',
    shippingTier: 'shipping_tier',
    tax: 'tax',
    transactionId: 'transaction_id',
    value: 'value',
    virtualCurrencyName: 'virtual_currency_name',
  },
  screenNames: {
    checkout: 'Checkout',
    checkoutOption: 'Checkout Option',
    checkoutStep: 'Checkout Step',
    error: 'Error',
    home: 'Home',
    levelEnd: 'Level End',
    levelStart: 'Level Start',
    levelUp: 'Level Up',
    login: 'Login',
    postScore: 'Post Score',
    purchase: 'Purchase',
    refund: 'Refund',
    removeFromCart: 'Remove From Cart',
    search: 'Search',
    selectContent: 'Select Content',
    selectItem: 'Select Item',
    selectPromotion: 'Select Promotion',
    share: 'Share',
    signUp: 'Sign Up',
    tutorialBegin: 'Tutorial Begin',
    tutorialComplete: 'Tutorial Complete',
    unlockAchievement: 'Unlock Achievement',
    viewCart: 'View Cart',
    viewItem: 'View Item',
    viewItemList: 'View Item List',
    viewPromotion: 'View Promotion',
  },
};
