import { DirectionOptions } from '../../enums/common/direction-options';
import { SocialNetworkReactions } from '../../enums/social-network/social-network-reactions';
import { SocialNetWorkThreadStatus } from '../../enums/social-network/social-network-thread-status';
import { PaginationOptions } from '../common/pagination-options';
import { ProfileEntity } from '../profile/profile-entity';
import { SocialAttachment } from './social-attachment';
import { SocialNetworkInstance } from './social-network-instance';
import { SocialNetworkReaction } from './social-network-reaction';
import { SocialNetworkState } from './social-network-state';

export class SocialNetWorkThread {
  public instancesId: string;
  public body: string;
  public commented: boolean;
  public customFeed: boolean;
  public instance: SocialNetworkInstance;
  public moderator: boolean;
  public owner: boolean;
  public profile: ProfileEntity;
  public publishedAt: Date;
  public status: SocialNetWorkThreadStatus;
  public token: string;
  public totalOfComments: number;
  public watching: boolean;
  public countReactions?: SocialNetworkReaction[];
  public reaction?: SocialNetworkReactions;
  public attachments?: SocialAttachment[];
  public states?: SocialNetworkState[] = [];
  public repost?: SocialNetWorkThread;
}

export class SocialNetWorkThreadList {
  public threads: SocialNetWorkThread[] = [];
  public pagination: PaginationOptions = {
    direction: DirectionOptions.desc,
    limit: 6,
    page: 0,
  };
}
