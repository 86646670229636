import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicHtmlModule } from '../../common/dynamic-html/dynamic-html.module';
import { EventBackgroundContent } from '../../../common/models/event/event-background-content';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonService } from '../../../services/common.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AudioItem } from '../../../common/models/event/audio-item';
import { EventService } from '../../../services/event.service';

@Component({
  selector: 'mec-event-info',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DynamicHtmlModule,
    MatExpansionModule,
    FontAwesomeModule,
  ],
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss'],
})
export class EventInfoComponent implements OnInit {
  @Input() title: string = 'BACKGROUND';
  @Input() backgroundContent: EventBackgroundContent;

  constructor(
    private _commonService: CommonService,
    private _eventService: EventService
  ) {}

  public ngOnInit(): void {
    if (this.backgroundContent?.sessions?.length) {
      this.backgroundContent.sessions = this.backgroundContent.sessions.map(
        (session) => {
          if (session?.buttonIcon) {
            session.buttonIconProp = this._commonService.getIcon(
              session?.buttonIcon
            );
          }

          return session;
        }
      );
    }
  }

  public updateVideoPlayer(videoUrl: string, currentTime: number = 0): void {
    let audioItem: AudioItem = {
      videoUrl,
      currentTime,
    };

    this._eventService.playerUpdateSubject.next(audioItem);
  }
}
