import { StepRuleOperator } from "../../../enums/course/step-rule-operator";
import { StepRuleType } from "../../../enums/course/step-rule-type";
import { LockedStepRule } from "./locked-step-rule";

export declare class StepExamRule {
  public id: number;
  public expectedValue: number;
  public lockedSteps: LockedStepRule[];
  public operator: StepRuleOperator;
  public retake: number;
  public type: StepRuleType;
}