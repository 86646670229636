import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SocialNetWorkThread,
  SocialNetWorkThreadList,
} from '../../../common/models/social-network/social-network-thread';
import { SocialPublicationItemComponent } from '../social-publication-item/social-publication-item.component';
import { ApiSocialNetworksV1Service } from '../../../services/api/api-social-networks-V1.service';
import { ToastService } from '../../../services/toast.service';
import { PaginationHelper } from '../../../common/helpers/pagination.helper';
import { InfiniteScrollDirective } from '../../../directives/infinite-scroll.directive';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { SocialNetWorkService } from '../../../services/social-network.service';
import { TranslateModule } from '@ngx-translate/core';
import { SocialPublicationCreateComponent } from '../social-publication-create/social-publication-create.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mec-social-publication-list',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SocialPublicationItemComponent,
    InfiniteScrollDirective,
    SkeletonDirective,
    SocialPublicationCreateComponent,
  ],
  providers: [ApiSocialNetworksV1Service],
  templateUrl: './social-publication-list.component.html',
  styleUrls: ['./social-publication-list.component.scss'],
})
export class SocialPublicationListComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() profileId: string | number;
  @Input() socialNetworkIds: string;
  @Input() isGlobal: boolean;

  public previousProfileId: number;

  public isLoading: boolean = true;

  public socialThreadsList: SocialNetWorkThreadList =
    new SocialNetWorkThreadList();

  public publicationReloadSubscription: Subscription;

  constructor(
    private _apiSocialNetworkV1Service: ApiSocialNetworksV1Service,
    private _socialNetWorksService: SocialNetWorkService,
    private _toastService: ToastService
  ) {
    this.socialThreadsList.pagination.limit = 10;

    this.publicationReloadSubscription =
      this._socialNetWorksService.socialPublicationsReloadSubject.subscribe(
        (reload) => {
          if (reload) {
            this.getThreads(true);
          }
        }
      );
  }

  public async ngOnInit(): Promise<void> {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      this.socialNetworkIds &&
      (changes['profileId']?.previousValue !=
        changes['profileId']?.currentValue ||
        this.isGlobal)
    ) {
      this.getThreads(true);
    }
  }

  public ngOnDestroy(): void {
    this.publicationReloadSubscription.unsubscribe();
  }

  public async getThreads(reset?: boolean): Promise<void> {
    this.isLoading = true;

    if (reset) {
      this.socialThreadsList = new SocialNetWorkThreadList();
    }

    try {
      const socialThreadsResult =
        await this._apiSocialNetworkV1Service.getPublications(
          this.profileId,
          this.socialThreadsList.pagination,
          this.socialNetworkIds
        );

      this.socialThreadsList =
        PaginationHelper.newHandlePagination<SocialNetWorkThread>(
          this.socialThreadsList,
          'threads',
          socialThreadsResult
        );
    } catch (error) {
      this._toastService.showError(error);
    }

    this.isLoading = false;
  }

  public async deleteThread(socialThread: SocialNetWorkThread): Promise<void> {
    try {
      await this._apiSocialNetworkV1Service.deletePublication(
        socialThread.token,
        socialThread.instancesId
      );

      this.socialThreadsList.threads = this.socialThreadsList.threads.filter(
        (thread) => thread.token !== socialThread.token
      );
    } catch (error) {
      this._toastService.showError(error);
    }
  }
}
