<div class="social-publication-body">
  <div
    #bodyText
    [ngClass]="{ 'social-publication-body--full-text': showSeeLess }"
    class="line-clamp-3"
  >
    <mec-dynamic-html [content]="body"></mec-dynamic-html>
  </div>
  <a
    class="social-publication-body__see-more"
    (click)="seeMore()"
    *ngIf="showSeeMore"
    >{{ 'SEE_MORE' | translate }}</a
  >
  <a
    class="social-publication-body__see-more"
    (click)="seeLess()"
    *ngIf="showSeeLess"
    >{{ 'SEE_LESS' | translate }}</a
  >
  <div
    *ngIf="socialThread?.attachments?.length"
    class="social-publication-body__body-files"
  >
    <ng-container *ngFor="let file of files">
      <div *ngIf="file.type === 'OTHER'" class="social-publication-body__file">
        <div class="social-publication-body__opacity"></div>
        <span class="social-publication-body__label-type">
          {{ getApplicationType(file.mimeType) }}
        </span>
        <button
          (click)="
            downloadFile(
              file.url + '?access_token=' + accessToken,
              file.filename + '.' + file.extension
            )
          "
          class="social-publication-body__download-btn btn btn--sm btn--icon-only btn--no-bg"
        >
          <fa-icon
            class="social-publication-body__download-icon"
            [icon]="downloadIcon"
          ></fa-icon>
        </button>
      </div>

      <div
        *ngIf="file.type === 'IMAGE'"
        class="social-publication-body__file"
        (click)="openFileViewer(file.id)"
      >
        <div class="social-publication-body__opacity"></div>
        <img
          class="social-publication-body__file-img"
          src="{{
            file.url +
              '?access_token=' +
              accessToken +
              '&format=thumbnail&width=426'
          }}"
        />
      </div>

      <div
        *ngIf="file.type === 'VIDEO'"
        class="social-publication-body__file"
        (click)="openFileViewer(file.id); $event.preventDefault()"
      >
        <div class="social-publication-body__opacity"></div>
        <video
          webkit-playsinline="true"
          medRetryUrlFile
          [retrySrc]="
            file.url +
            '?quality=720&width=352&format=mp4&access_token=' +
            accessToken
          "
          src="{{
            file.url +
              '?quality=720&width=352&format=mp4&access_token=' +
              accessToken
          }}"
          class="social-publication-body__file-video"
          controls
        ></video>
      </div>
    </ng-container>
  </div>
</div>
