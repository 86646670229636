import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments';
import { ApiBaseService } from './api-base.service';
import { FileEntity, UploadSignature } from '../../common';
import { Hub } from '../abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class ApiFilesV2Service extends ApiBaseService {
  protected apiBaseUrl = environment.filesV2;
  protected routePath = ``;

  constructor(private _httpClient: HttpClient, private _hubService: Hub) {
    super(_httpClient);
  }

  public getUploadSignature(): Promise<UploadSignature> {
    const path = `issue-upload-signature`;
    return super.get<UploadSignature>(path);
  }

  public getFileById(id: number): Promise<FileEntity> {
    const path = `${id}`;
    return super.get<FileEntity>(path);
  }

  public confirmFile(
    token: string,
    filename: string,
    folderPath?: string
  ): Promise<FileEntity[]> {
    let path = `files?path=Campus/${this._hubService.hub()}`;

    const params = {
      token: token,
      filename: filename,
    };

    if (folderPath) {
      path += `/library/${folderPath}`;
    }

    return super.post<FileEntity[]>(path, params);
  }
}
