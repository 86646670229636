import { Language } from './language';
import { LanguageFilter } from './language-filter';

export class Region {
  public regionId: string;
  public name: string;
  public slug: string;
  public languages: Language[];
  public languagesFilter: LanguageFilter[];
}
