import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';
import { ProgramRadioComponent } from '../program-radio/program-radio.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProgramCheckboxComponent } from '../program-checkbox/program-checkbox.component';
import { ProgramOpenTextComponent } from '../program-open-text/program-open-text.component';
import { ProgramOutputComponent } from '../program-output/program-output.component';
import { ProgramEqvasComponent } from '../program-eqvas/program-eqvas.component';
import { ProgramMatrixRadioComponent } from '../program-matrix-radio/program-matrix-radio.component';
import { Program, ProgramLog } from '@meplis/common';

@Component({
  selector: 'mec-program-step-container',
  standalone: true,
  imports: [
    CommonModule,
    ProgramRadioComponent,
    ProgramCheckboxComponent,
    ProgramOpenTextComponent,
    ProgramOutputComponent,
    ProgramEqvasComponent,
    ProgramMatrixRadioComponent,
    TranslateModule,
  ],
  templateUrl: './program-step-container.component.html',
  styleUrls: ['./program-step-container.component.scss'],
})
export class ProgramStepContainerComponent implements OnChanges {
  @Input() public programStep: ProgramNode[];
  @Input() public program: Program;
  @Input() public isLoadingStep: boolean;
  @Output() onStepValidChange = new EventEmitter<boolean>();

  constructor(@Inject(DOCUMENT) private _document: Document) {}

  public ngOnChanges(): void {
    if (this.programStep) {
    }
  }
  private _goToNextNode(stepIndex: number): void {
    const el = this._document.getElementById(`node${stepIndex}`);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  public validateNode(
    stepIndex: number,
    programLog: ProgramLog,
    valid: boolean
  ): void {
    this.programStep[stepIndex].isValid =
      programLog?.results?.length > 0 && valid;

    const isStepValid = !this.programStep.some((program) => !program.isValid);
    this.onStepValidChange.emit(isStepValid);

    if (this.programStep[stepIndex].isValid) {
      this.programStep[stepIndex].log = programLog;
    }

    this._goToNextNode(stepIndex);
  }
}
