import { Routes } from './routes.constants';

export const NetworkConstants = {
  patterns: [
    {
      formula: /@\[(.+?):([0-9a-z-]+?)\]/gi,
      name: 'mention',
      output: {
        directive:
          '<mec-social-profile-link label="@$1" id="$2"></mec-social-profile-link>',
        data: '{"char": "@", "id": "$2", "label": "$1"}',
      },
    },
    {
      formula: /@\[(.+?):([0-9a-z-]+?)\]/gi,
      name: 'mentionText',
      output: {
        directive: '@$1',
        data: '{"char": "@", "id": "$2", "label": "$1"}',
      },
    },
    {
      formula: /#\[?(\w+):?(\w+)?\]?/gi,
      name: 'hashtag',
      output: {
        directive:
          '<mec-social-hashtag-link label="#$1" id="$1"></mec-social-hashtag-link>',
        data: '{"char": "#", "id": "$1", "label": null}',
      },
    },
    {
      formula:
        /((http|ftp|https):\/\/[\w-]+(\.[\w-]+)*([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?)/gi,
      name: 'externalLink',
      output: {
        directive:
          '<mec-social-external-link url="$1"></mec-social-external-link>',
        data: '{"char": ">", "url": "$1", "label": null}',
      },
    },
  ],
};
