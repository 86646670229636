export const AuthenticationConstants = {
  localStorage: {
    login: 'meplis-campus-auth-login',
    disableLogo: 'meplis-campus-auth-disable-logo',
    inviteCode: 'meplis-campus-auth-invite-code',
  },
  authenticationLegalDocuments: [
    {
      href: 'https://docs.meplis.com/meplis-account/privacy-policy/',
      title: 'AUT.LEGAL_DOCUMENTS.PRIVACY_POLICY.TITLE',
    },
    {
      href: 'https://docs.meplis.com/meplis-account/terms-of-service/',
      title: 'AUT.LEGAL_DOCUMENTS.TERMS_OF_SERVICE.TITLE',
    },
  ],
} as const;
