import { ContentCourse } from "./content-course";

export class DetailCourse {
    public id: number;
    public publishedAt: Date;
    public course: CourseData;
}

export class CourseData {
    public content: ContentCourse;
}
