import { Injectable } from '@angular/core';

import { ApiCpdSurveyService } from './api/api-cpd-survey.service';
import { CpdConfigs } from '../common/models/cpd/cpd-configs';
import { OwnContentType } from '../common/enums/common/own-content-type';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class CpdService {
  private _cpdTimeout: { [key: string]: any } = {};
  private _cpdTimeoutDelay = 5 * 1000;

  constructor(
    private _apiCpdSurveyService: ApiCpdSurveyService,
    private _toastService: ToastService
  ) {}

  public hasCPD(
    collection: CpdConfigs[],
    type: OwnContentType,
    fileHash?: string
  ) {
    return collection.some((item: CpdConfigs) => {
      return (
        (item.type === type && !fileHash) || item?.file.fileHash === fileHash
      );
    });
  }

  public async createSurvey(
    cpdConfigs: CpdConfigs[],
    module: OwnContentType,
    moduleId: number | string
  ): Promise<void> {
    const key = moduleId.toString();

    if (this._cpdTimeout[key]) {
      clearTimeout(this._cpdTimeout[key]);
    }

    if (!this.hasCPD(cpdConfigs, module)) return;

    this._cpdTimeout[key] = setTimeout(async () => {
      try {
        await this._apiCpdSurveyService.createSurvey({ module, moduleId });
        this._toastService.showSuccess(
          'CAREMONITOR.NOTIFICATION.NEW_PROGRAM_AVAILABLE'
        );
      } catch (error) {
        this._toastService.showError(error);
      }
    }, this._cpdTimeoutDelay);
  }
}
