<div class="publication-create">
  <div class="publication-create__input-container">
    <img [src]="avatarUrl" class="avatar" />
    <div class="field__group">
      <input
        (click)="openCreateModal()"
        placeholder="{{ 'SOCIALNETWORK.CREATE_ENTRY.PLACEHOLDER' | translate }}"
        class="field__input"
      />
    </div>
  </div>
  <div class="publication-create__actions">
    <!-- web actions -->
    <ng-container *ngIf="!isMobile">
      <button
        (click)="openCreateModal('link')"
        class="publication-create__actions-btn btn btn--sm btn--no-bg btn--icon-text"
      >
        <fa-icon
          class="publication-create__actions-icon"
          [icon]="linkIcon"
        ></fa-icon>
        <span class="publication-create__actions-label">{{
          'APP_STATE' | translate
        }}</span>
      </button>

      <button
        (click)="openCreateModal('image-video')"
        class="publication-create__actions-btn btn btn--sm btn--no-bg btn--icon-text"
      >
        <fa-icon
          class="publication-create__actions-icon"
          [icon]="imagesIcon"
        ></fa-icon>
        <span class="publication-create__actions-label">{{
          'IMAGE_OR_VIDEO' | translate
        }}</span>
      </button>

      <button
        (click)="openCreateModal('file')"
        class="publication-create__actions-btn btn btn--sm btn--no-bg btn--icon-text"
      >
        <fa-icon
          class="publication-create__actions-icon"
          [icon]="fileIcon"
        ></fa-icon>
        <span class="publication-create__actions-label">{{
          'DOCUMENT' | translate
        }}</span>
      </button>
    </ng-container>

    <!-- mobile actions -->
    <ng-container *ngIf="isMobile">
      <button
        (click)="openCreateModal('link')"
        class="publication-create__actions-btn btn btn--sm btn--no-bg btn--icon-only"
      >
        <fa-icon
          class="publication-create__actions-icon"
          [icon]="linkIcon"
        ></fa-icon>
      </button>

      <button
        (click)="openCreateModal('image-video')"
        class="publication-create__actions-btn btn btn--sm btn--no-bg btn--icon-only"
      >
        <fa-icon
          class="publication-create__actions-icon"
          [icon]="imagesIcon"
        ></fa-icon>
      </button>

      <button
        (click)="openCreateModal('file')"
        class="publication-create__actions-btn btn btn--sm btn--no-bg btn--icon-only"
      >
        <fa-icon
          class="publication-create__actions-icon"
          [icon]="fileIcon"
        ></fa-icon>
      </button>
    </ng-container>
  </div>
</div>
