import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsEntity } from '../../common';
import { environment } from '../../environments';
import { ApiBaseService } from './api-base.service';
import { Hub } from '../abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class ApiCourseSettingsService extends ApiBaseService {
  protected apiBaseUrl = environment.campusV2;
  protected routePath = `hubs/${this._hubService.hub()}/courses`;

  constructor(private _hubService: Hub, private _httpClient: HttpClient) {
    super(_httpClient);

    this._hubService.onHubChange.subscribe(() => {
      this.routePath = `hubs/${this._hubService.hub()}/courses`;
    });
  }

  public getCourseSettings(courseSlug: string): Promise<SettingsEntity[]> {
    const path = `${courseSlug}/settings`;
    return super.get<SettingsEntity[]>(path);
  }
}
