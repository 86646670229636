<div class="how-to-join">
  <h2 class="how-to-join__title">{{ 'HOW_TO_JOIN' | translate }}</h2>
  <div class="how-to-join__container">
    <div class="card how-to-join__item" (click)="goToPage('login')">
      <span class="how-to-join__item-number">01</span>
      <p class="how-to-join__item-text">
        {{ 'CREATE_MEPLIS_ACCOUNT' | translate }}
      </p>
      <fa-icon class="how-to-join__icon" [icon]="iconCreateAccount"></fa-icon>
    </div>

    <fa-icon class="how-to-join__icon-arrow" [icon]="iconArrowRight"></fa-icon>

    <div class="card how-to-join__item" (click)="goToPage('login')">
      <span class="how-to-join__item-number">02</span>
      <p class="how-to-join__item-text">
        {{ 'COMPLETE_ACCESS_REQUIREMENTS' | translate }}
      </p>
      <fa-icon class="how-to-join__icon" [icon]="iconSubscription"></fa-icon>
    </div>

    <fa-icon class="how-to-join__icon-arrow" [icon]="iconArrowRight"></fa-icon>

    <div class="card how-to-join__item" (click)="goToPage('login')">
      <span class="how-to-join__item-number">03</span>
      <p class="how-to-join__item-text">
        {{ 'START_YOUR_TRAINING' | translate }}
      </p>
      <fa-icon class="how-to-join__icon" [icon]="iconStart"></fa-icon>
    </div>
  </div>
</div>
