import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { CommonService } from '../../../../services/common.service';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'mec-step-exam-waiting-info',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
  templateUrl: './step-exam-waiting-info.component.html',
  styleUrls: ['./step-exam-waiting-info.component.scss'],
})
export class StepExamWaitingInfoComponent {
  public iconInfo = faCircleInfo;

  constructor(public _commonService: CommonService) {}
}
