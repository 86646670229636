<div class="common-step-info">
  <ng-container *ngIf="lastEvaluation">
    <mec-step-exam-score-info
      (actionClick)="openResults()"
      [score]="lastEvaluation?.score"
    ></mec-step-exam-score-info>
  </ng-container>

  <ng-container
    *ngIf="lastExamInstance?.status === submissionsStatus.WAIT_EVALUATION"
  >
    <mec-step-exam-waiting-info></mec-step-exam-waiting-info>
  </ng-container>

  <div
    class="common-step-info__content"
    *ngFor="let evaluation of step?.evaluations"
  >
    <h2>
      {{ evaluation.content.title }}
    </h2>
    <p>{{ evaluation.content.description }}</p>
  </div>

  <div class="common-step-info__content">
    <mec-step-exam-rules
      [courseId]="courseId"
      [stepId]="step.id"
    ></mec-step-exam-rules>
  </div>

  <button
    class="common-step-info__action-button"
    class="btn"
    *ngIf="
      lastExamInstance?.status === submissionsStatus.READY ||
      lastExamInstance?.status === submissionsStatus.IN_PROGRESS
    "
    (click)="goToExam()"
  >
    <span *ngIf="!isLoading">{{ examButtonText | translate }}</span>
    <mat-spinner *ngIf="isLoading"></mat-spinner>
  </button>
</div>
