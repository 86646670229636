<div
  #list
  [ngClass]="{ 'mentions-list--hide': hidden }"
  class="card mentions-list"
>
  <div *ngIf="isLoading" class="mentions-list__spinner">
    <mat-spinner></mat-spinner>
  </div>
  <ng-container *ngIf="!isLoading">
    <div
      (click)="selectItem(item)"
      *ngFor="let item of itemsList"
      class="mentions-list__item"
    >
      <img
        class="avatar mentions-list__avatar"
        [src]="getAvatarimage(item.id)"
      />
      <h5>{{ item.fullName }}</h5>
    </div>
  </ng-container>
</div>
