export abstract class MepStorage {
  public abstract get(key: string): string | null | Promise<string>;

  public abstract set(key: string, value: string): void;

  public abstract remove(key: string): void;

  public abstract removeItems(keys: any[]): void;

  public abstract setItems(items: { [key: string]: any }): void;
}
