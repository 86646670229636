<form [formGroup]="formGroup" class="autocomplete">
  <div class="field__group field__group--icon-right">
    <input
      type="text"
      #inputAutocomplete
      class="autocomplete__input field__input"
      *ngIf="formGroup"
      placeholder="{{ placeholder | translate }}"
      aria-label=""
      matInput
      [formControlName]="controlName"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayName"
      (optionSelected)="optionSelected($event)"
    >
      <mat-option *ngFor="let item of items" [value]="item">
        <div class="autocomplete__option">
          <span>{{ item.title }}</span>
          <small class="autocomplete__subtitle" *ngIf="item.subtitle">{{
            item.subtitle
          }}</small>
        </div>
      </mat-option>
    </mat-autocomplete>
    <fa-icon [icon]="searchIcon"></fa-icon>
  </div>
</form>
