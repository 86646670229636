import { Routes } from './routes.constants';

export const AppConstants = {
  platform: 'web',
  project: 'campus',
  legalDocuments: [
    {
      href: 'privacy-policy',
      title: 'AUT.LEGAL_DOCUMENTS.PRIVACY_POLICY.TITLE',
    },
    {
      href: 'terms-of-service',
      title: 'AUT.LEGAL_DOCUMENTS.TERMS_OF_SERVICE.TITLE',
    },
    { href: 'cookie-policy', title: 'COOKIE_POLICY' },
    { href: 'disclaimer', title: 'DISCLAIMER' },
    { href: 'legal-notice', title: 'LEGAL_NOTICE' },
    { href: 'safe-harbor', title: 'SAFE_HARBOR' },
  ],
  pageTypes: [
    {
      type: 'CONTENT',
      label: 'CAMPUS_COURSE_CLASSROOM_STEP',
      icon: 'fa-file-alt',
    },
    {
      type: 'EXAM',
      label: 'CAMPUS_COURSE_CLASSROOM_STEP',
      icon: 'fa-check-square',
    },
    {
      type: 'MULTIPLE_CHOICE',
      label: 'CAMPUS_COURSE_CLASSROOM_STEP',
      icon: 'fa-check-square',
    },
    {
      type: 'SURVEY',
      label: 'CAMPUS_COURSE_CLASSROOM_STEP',
      icon: 'fa-edit',
    },
    {
      type: 'CERTIFICATE',
      label: 'CAMPUS_COURSE_CLASSROOM_STEP',
      icon: 'fa-file-certificate',
    },
    {
      type: 'NEWS',
      label: 'CAMPUS_COURSE_NEWS_ARTICLE',
      icon: 'fa-newspaper',
    },
    {
      type: 'EVENTS',
      label: 'CAMPUS_COURSE_EVENTS_ARTICLE',
      icon: 'fa-calendar',
    },
    {
      type: 'PAGE',
      label: 'CAMPUS_PAGES',
      icon: 'fa-bookmark',
    },
    {
      type: 'FILE',
      label: 'CAMPUS_COURSE_LIBRARY_FILE',
      icon: 'fa-file',
    },
  ],
};
