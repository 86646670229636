import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiSettingsService } from './api/api-settings.service';
import { SettingsEntity } from '../common';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private _settingsSubject = new BehaviorSubject<SettingsEntity[]>([]);

  constructor(private _apiInstanceService: ApiSettingsService) {}

  public async setSettings(reset?: boolean): Promise<void> {
    if (reset) {
      this._settingsSubject.next([]);
      return;
    }

    const settings = await this._apiInstanceService.getSettings();
    this._settingsSubject.next(settings);
  }

  public getSettings(): Promise<SettingsEntity[]> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<SettingsEntity[]>(async (resolve) => {
      let settings = this._settingsSubject.getValue();
      if (Object.keys(settings).length === 0 || settings.length === 0) {
        await this.setSettings();
      }
      settings = this._settingsSubject.getValue();
      resolve(settings);
    });
  }

  public async getPermissionByName(
    settingType: string
  ): Promise<SettingsEntity> {
    const settings = await this.getSettings();
    const settingByName = settings.find(
      (setting) => setting.type === settingType
    );
    return settingByName;
  }

  public async _getPermissionValue(permissionName: string): Promise<boolean> {
    const permission = await this.getPermissionByName(permissionName);
    return permission?.value === 'true' ? true : false;
  }
}
