import { FileTypes } from '../../enums/file/file-types';

export class ContentFile {
  public id: number;
  public createdAt: Date;
  public extension: string;
  public isOwner: boolean;
  public mimeType: string;
  public shareable: boolean;
  public status: string;
  public type: FileTypes;
  public url: string;
}
