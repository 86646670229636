<div class="card social-item">
  <div class="social-item__group">
    <fa-icon [icon]="globeIcon"></fa-icon>
    <span>{{ socialThread?.instance?.name }}</span>
  </div>
  <div class="social-item__divider"></div>
  <div class="social-item__content">
    <div (click)="goToProfile()" class="social-item__profile">
      <div class="social-item__container-avatar">
        <img class="social-item__avatar" src="{{ avatarUrl }}" />
        <div class="social-item__container-name">
          <h4 class="social-item__name">{{ socialThread?.profile?.name }}</h4>
          <small class="social-item__date">{{
            socialThread?.publishedAt | date : 'longDate'
          }}</small>
        </div>
      </div>
      <div *ngIf="socialThread?.owner" class="social-item__container-edit">
        <mat-menu #filemenu="matMenu">
          <button
            mat-menu-item
            (click)="deleteThread(); $event.stopPropagation()"
          >
            <span> {{ 'DELETE' | translate }} </span>
          </button>
        </mat-menu>
        <button
          [matMenuTriggerFor]="filemenu"
          (click)="$event.stopPropagation()"
          class="btn btn--sm btn--no-bg btn--icon"
        >
          <fa-icon class="social-item__edit-icon" [icon]="editIcon"></fa-icon>
        </button>
      </div>
    </div>
    <div class="social-item__body">
      <mec-social-publication-body
        [socialThread]="socialThread"
      ></mec-social-publication-body>

      <mec-social-content-card
        *ngIf="socialThread?.states.length"
        [searchItem]="socialThread?.states[0].content"
      ></mec-social-content-card>

      <mec-social-repost-body
        *ngIf="this.socialThread.repost"
        [socialThreadToRepost]="this.socialThread.repost"
      ></mec-social-repost-body>
    </div>
  </div>
  <div class="social-item__info-reactions">
    <mec-social-publication-reactions-info
      [socialThread]="socialThread"
    ></mec-social-publication-reactions-info>
  </div>
  <div class="social-item__actions">
    <mec-social-publication-reaction
      [socialThread]="socialThread"
    ></mec-social-publication-reaction>

    <button
      (click)="setCommentMode(); $event.stopPropagation()"
      class="btn btn--no-bg"
    >
      <fa-icon [icon]="commentIcon"></fa-icon>
      <span>{{ 'SOCIALNETWORK_COMMENT' | translate }}</span>
    </button>

    <button
      (click)="repostClick(); $event.stopPropagation()"
      class="btn btn--no-bg"
    >
      <fa-icon [icon]="repostIcon"></fa-icon>
      <span>{{ 'SOCIALNETWORK_REPOST' | translate }}</span>
    </button>
  </div>

  <div *ngIf="isCommentMode" class="card social-item__comment-create-container">
    <mec-social-publication-create-body
      [socialThread]="newComment"
      [socialNetWorkId]="socialThread?.instancesId"
      [isCommentMode]="true"
    ></mec-social-publication-create-body>
    <button (click)="saveComment()" [disabled]="isSaving" class="btn btn--sm">
      <span *ngIf="!isSaving"> {{ 'NEW_POST' | translate }}</span>
      <mat-spinner *ngIf="isSaving"></mat-spinner>
    </button>
  </div>

  <div class="social-item__coments">
    <mec-social-comment-list
      [socialThread]="socialThread"
    ></mec-social-comment-list>
  </div>
</div>
