<div
  class="card search-card"
  (click)="goToRoute(searchResult); $event.stopPropagation()"
>
  <div *ngIf="imageUrl" class="search-card__img-container">
    <div class="search-card__overlay"></div>

    <img
      [ngClass]="{ hidden: isLoadingImage || hiddenImage }"
      (error)="hiddenImage = true; isLoadingImage = false"
      (load)="isLoadingImage = false"
      class="search-card__cover"
      src="{{ imageUrl }}"
    />

    <!-- Skeleton image start -->
    <div
      [medSkeletonLoading]="true"
      *ngIf="isLoadingImage"
      class="search-card__cover"
    ></div>
    <!-- Skeleton image end -->
  </div>

  <div class="search-card__title-header">
    <div class="search-card__title-line">
      <h2 class="search-card__title">{{ searchResult?.content?.title }}</h2>
      <mec-dropdown-menu [searchResult]="searchResult"></mec-dropdown-menu>
    </div>
    <small class="search-card__date">
      <strong class="cards__label ng-binding">
        {{ 'PUBLISHED_ON' | translate }}
      </strong>
      {{ searchResult?.publishedAt | date : 'short' }}
    </small>
    <p
      class="search-card__description line-clamp-2"
      [innerHTML]="searchResult?.content?.description"
    ></p>
  </div>
  <div class="search-card__content">
    <mec-tags-list [tags]="searchResult?.tags"></mec-tags-list>
    <!-- Todo translate -->
    <button
      class="btn btn--sm"
      (click)="goToRoute(searchResult); $event.stopPropagation()"
    >
      + {{ 'READ_MORE' | translate }}
    </button>
  </div>
</div>
