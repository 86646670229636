<div class="header" [ngClass]="{ 'header--search-opened': mobileSearchOpened }">
  <!-- Desktop screen -->
  <ng-container *ngIf="!_commonService.isMobile">
    <div class="header__lef-side">
      <img
        class="logo header__logo"
        (click)="goToPage('home')"
        [ngClass]="{ hidden: isLoadingLogo || hiddenLogo }"
        (error)="imageLogoError()"
        (load)="imageLogoLoaded()"
        src="{{ logo }}"
      />
      <!-- Start Skeleton loader -->
      <div
        style="width: 80px; height: 60px"
        *ngIf="isLoadingLogo"
        [medSkeletonLoading]="true"
      ></div>
      <!-- End Skeleton loader -->
      <div class="header__menus" *ngIf="!isLoadingMenus">
        <h4
          *ngFor="let menu of menuData.menuItems"
          [ngClass]="{
            'header__menu-label--active': currentRoute === menu.targetSlug
          }"
          (click)="goToPage(menu.targetSlug)"
          class="header__menu-label"
        >
          {{ menu?.content?.title | translate }}
        </h4>
      </div>
      <!-- Start Skeleton loader -->
      <ng-container *ngIf="isLoadingMenus">
        <div class="header__menus">
          <h4
            *ngFor="let item of [1, 2, 3, 4]"
            [medSkeletonLoading]="true"
            class="header__menu-label"
          >
            Title Title
          </h4>
        </div>
      </ng-container>
      <!-- End Skeleton loader -->
    </div>

    <div class="header__right-side">
      <button
        (click)="toogleSearchMobile()"
        class="btn btn--sm btn--icon btn--no-bg"
        *ngIf="!mobileSearchOpened"
      >
        <fa-icon
          class="header__icon-search-input"
          [icon]="searchIcon"
        ></fa-icon>
      </button>
      <div
        class="header__search-container field__group field__group--icon-right"
      >
        <input
          [(ngModel)]="searchFilter"
          (keyup.enter)="search()"
          class="header__input field__input field__input--sm"
          placeholder="{{ 'SEARCH' | translate }}"
        />
        <fa-icon
          class="header__icon-search header__icon-search-input"
          (click)="search(); toogleSearchMobile()"
          [icon]="searchIcon"
        ></fa-icon>
      </div>

      <div class="header__actions" *ngIf="!isLogged">
        <button class="btn btn--sm" (click)="goToPage('sign-up')">
          {{ 'SIGN_UP' | translate }}
        </button>
        <button class="btn btn--sm btn--outline" (click)="goToPage('login')">
          {{ 'LOGIN' | translate }}
        </button>
      </div>

      <div
        class="header__profile"
        [matMenuTriggerFor]="profileWidget"
        (click)="$event.stopPropagation()"
        *ngIf="isLogged && !isLoadingAvatar"
      >
        <img
          class="header__avatar"
          (error)="imageAvatarError()"
          (load)="imageAvatarLoaded()"
          *ngIf="menuData?.imageAvatarUrl && !hiddenAvatar"
          src="{{ menuData.imageAvatarUrl }}"
        />
        <fa-icon
          *ngIf="!menuData?.imageAvatarUrl || hiddenAvatar"
          [icon]="userIcon"
        ></fa-icon>
      </div>
      <!-- Start Skeleton loader -->
      <div
        class="header__profile"
        *ngIf="isLogged && isLoadingAvatar"
        [medSkeletonLoading]="true"
      ></div>
      <!-- End Skeleton loader -->
    </div>
  </ng-container>

  <!-- profile-widget is the same for web and mobile -->
  <mat-menu #profileWidget="matMenu">
    <mec-profile-widget></mec-profile-widget>
  </mat-menu>

  <!-- Mobile screen -->
  <div
    class="header-mobile"
    [ngClass]="{ 'header-mobile--search-opened': mobileSearchOpened }"
    *ngIf="_commonService.isMobile"
  >
    <div class="header__lef-side header-mobile__lef-side">
      <img
        class="header__logo"
        (click)="goToPage('home')"
        [ngClass]="{ hidden: isLoadingLogo || hiddenLogo }"
        (error)="imageLogoError()"
        (load)="imageLogoLoaded()"
        src="{{ logo }}"
      />
      <!-- Start Skeleton loader -->
      <div
        style="width: 80px; height: 60px"
        *ngIf="isLoadingLogo"
        [medSkeletonLoading]="true"
      ></div>
      <!-- End Skeleton loader -->
    </div>

    <div class="header__right-side header-mobile__right-side">
      <button
        (click)="toogleSearchMobile()"
        class="btn btn--sm btn--icon btn--no-bg"
        *ngIf="!mobileSearchOpened"
      >
        <fa-icon [icon]="searchIcon"></fa-icon>
      </button>
      <div
        class="header__search-container header-mobile__search-container field__group field__group--icon-right"
      >
        <input
          [(ngModel)]="searchFilter"
          (keyup.enter)="search()"
          class="header-mobile__input field__input field__input--sm"
          placeholder="{{ 'SEARCH' | translate }}"
        />
        <fa-icon
          class="header-mobile__icon-search"
          (click)="search(); toogleSearchMobile()"
          [icon]="searchIcon"
        ></fa-icon>
      </div>

      <button (click)="toogleMobileMenu()" class="btn btn--no-bg btn--icon">
        <fa-icon class="header-mobile__ico-menu" [icon]="menuIcon"></fa-icon>
      </button>

      <div
        [matMenuTriggerFor]="profileWidget"
        class="header__profile header-mobile__profile"
        *ngIf="isLogged && !isLoadingAvatar"
      >
        <img
          class="header__avatar"
          (error)="imageAvatarError()"
          (load)="imageAvatarLoaded()"
          *ngIf="menuData?.imageAvatarUrl && !hiddenAvatar"
          src="{{ menuData.imageAvatarUrl }}"
        />
        <fa-icon
          *ngIf="!menuData?.imageAvatarUrl || hiddenAvatar"
          [icon]="userIcon"
        ></fa-icon>
      </div>
      <!-- Start Skeleton loader -->
      <div
        class="header__profile"
        *ngIf="isLogged && isLoadingAvatar"
        [medSkeletonLoading]="true"
      ></div>
      <!-- End Skeleton loader -->
    </div>

    <div
      class="mobile-menu__menu"
      *ngIf="_commonService.isMobile"
      [ngClass]="{ 'mobile-menu__menu--show': mobileMenuOpen }"
    >
      <div class="mobile-menu__scroll">
        <button class="mobile-menu__trigger" (click)="toogleMobileMenu()">
          <fa-icon [icon]="closeIcon"></fa-icon>
        </button>
        <ul class="mobile-menu__list">
          <li class="mobile-menu__item">
            <span class="mobile-menu__welcome">
              <strong>{{ 'Welcome' | translate }}!</strong>
            </span>
          </li>
          <ng-container>
            <mec-side-bar (itemClicked)="toogleMobileMenu()"></mec-side-bar>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>
<mec-breadcrumbs></mec-breadcrumbs>
