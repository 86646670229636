import { SocialNetworkReactions } from '../../enums/social-network/social-network-reactions';
import { SocialNetworkParticipant } from './social-network-participants';

export class SocialNetworkReaction {
  public reaction: SocialNetworkReactions;
  public count: number;
  public at?: Date;
  public participant?: SocialNetworkParticipant;
  public token?: string;
  public type?: SocialNetworkReactions;
}
