import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Tag } from '../tags/tag';
import { Article } from '../article/article';
import { MenusType } from '../../enums/common/menus-type';
import { ArticleMenu } from '../../enums/article/article-menu';
import { ArticleVisibility } from '../../enums/article/article-visibility';
import { ContentEntity } from '../common/content-entity';

export class MenuItem {
  public name?: string;
  public slug?: string;
  public count?: number;
  public externalLink?: string;
  public items?: MenuItem[];
  public icon?: IconProp;
  public showItem?: boolean;
  public tag?: Tag;
  public article?: Article;
  public order?: number;
  public savedItem?: boolean;
  public ownContentType?: string;
  public type?: MenusType;
  public targetName?: string;
  public targetSlug?: string;
  public menu?: ArticleMenu;
  public visibility?: ArticleVisibility;
  public content?: ContentEntity;
}
