import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { environment } from '../../environments';
import { CustomForm } from '../../common/models/forms/custom-form';
import { CustomFormType } from '../../common/enums/forms/custom-form-type';
import { CustomFormSubmission } from '../../common/models/forms/custom-form-submission';
import { ProfileCustomForm } from '../../common/models/forms/profile-custom-form';
import { Hub } from '../abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class ApiFormsService extends ApiBaseService {
  protected apiBaseUrl = environment.campusV3;
  protected routePath = `hubs/${this._hubService.hub()}/forms`; // : string;

  constructor(private _hubService: Hub, private _httpClient: HttpClient) {
    super(_httpClient);

    // this._hubService.onHubChange.subscribe(() => {
    //   this.routePath = `hubs/${this._hubService.hub()}/forms`;
    // });
  }

  public getForms(
    type: CustomFormType,
    notAnswered?: boolean
  ): Promise<CustomForm[]> {
    const path = `open/search`;
    const params = {};
    if (type) {
      Object.assign(params, { type });
    }
    if (notAnswered) {
      Object.assign(params, { notAnswered });
    }
    return super.get<CustomForm[]>(path, {
      params,
      headers: this._hubService.hubHeaders(),
    });
  }

  public getFormsByUser(type: CustomFormType): Promise<ProfileCustomForm[]> {
    const path = `answers/me?type=${type}`;
    return super.get<ProfileCustomForm[]>(path, {
      headers: this._hubService.hubHeaders(),
    });
  }

  public getFormsById(id: string): Promise<CustomForm> {
    const path = `${id}`;
    return super.get<CustomForm>(path, {
      headers: this._hubService.hubHeaders(),
    });
  }

  public edit(formSubmission: CustomFormSubmission[]): Promise<CustomForm> {
    const path = `answers`;
    return super.put(path, formSubmission, {
      headers: this._hubService.hubHeaders(),
    });
  }

  public save(formSubmission: CustomFormSubmission[]): Promise<void> {
    const path = `answers`;
    return super.post(path, formSubmission, {
      headers: this._hubService.hubHeaders(),
    });
  }
}
