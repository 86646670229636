import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments';
import { ApiBaseService } from './api-base.service';
import { PaginationOptions, SocialNetwork } from '../../common';
import { Hub } from '../abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class ApiSocialNetworksV2Service extends ApiBaseService {
  protected apiBaseUrl = environment.campusV2;
  protected routePath = `hubs/${this._hubService.hub()}/socialnetworks`;

  constructor(private _hubService: Hub, private _httpClient: HttpClient) {
    super(_httpClient);
  }

  public getSocialNetworks(limit = 100): Promise<SocialNetwork[]> {
    const path = ``;

    const params = {};
    Object.assign(params, { limit: limit });

    return super.get<SocialNetwork[]>(path, { params });
  }
}
