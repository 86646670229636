import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import {
  ActivatedRouteSnapshot,
  NavigationExtras,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard {
  constructor(
    private _authService: AuthenticationService,
    private _commonService: CommonService,
    private _router: Router
  ) {}

  public async canActivate(
    route: ActivatedRouteSnapshot
  ): Promise<
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree
  > {
    if (!this._authService.isLogged) {
      await this._authService.verifyIfIsLoggedIn();
    }

    if (this._authService.isLogged === true) {
      return true;
    }

    // let fullPath = `${Object.values(route.params).join('/')}/${
    //   this._router.url
    // }`;

    const extras: NavigationExtras = {
      queryParams: {
        routeUrlToRedirect: route.routeConfig.path,
        ...route.queryParams,
      },
    };
    this._commonService.navigate(['login'], extras);
    return false;
  }
}
