<div class="event-info">
  <h2 class="event-info__title">{{ title | translate }}</h2>
  <div class="event-info__content">
    <mec-dynamic-html
      [content]="backgroundContent?.description"
    ></mec-dynamic-html>
    <mat-accordion
      class="event-info__sessions"
      *ngIf="backgroundContent?.sessions?.length"
    >
      <mat-expansion-panel *ngFor="let session of backgroundContent?.sessions">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="event-info__session-header-container">
              <div class="event-info__session-title-container">
                <h4>{{ session?.title }}</h4>
                <span>{{ session?.description }}</span>
              </div>
              <button
                *ngIf="session.buttonText"
                [disabled]="session.buttonDisabled"
                (click)="
                  updateVideoPlayer(
                    session.buttonVideoHash,
                    session.buttonVideoId
                  );
                  $event.stopPropagation()
                "
                class="btn btn--sm btn--icon-text"
              >
                <fa-icon [icon]="session.buttonIconProp"></fa-icon>
                <span> {{ session.buttonText | translate }} </span>
              </button>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mec-dynamic-html [content]="session?.content"></mec-dynamic-html>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
