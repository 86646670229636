import { OwnContentType } from './../../enums/common/own-content-type';
import { DirectionOptions } from '../../enums/common/direction-options';
import { FileTypes } from '../../enums/file/file-types';
import { LibraryTypes } from '../../enums/library/library-types';
import { ContentEntity } from '../common/content-entity';
import { PaginationOptions } from '../common/pagination-options';
import { CpdConfigs } from '../cpd/cpd-configs';
import { CpdCredit } from '../cpd/cpd-credit';
import { Tag } from '../tags/tag';
import { UserRole } from '../user/user-role';
import { LibraryFileType } from '../../enums/library/library-file-types';

export class LibraryItem {
  public id?: number;
  public fileId?: number;
  public fileHash?: string;
  public title?: string;
  public type: LibraryTypes;
  public ownContentType?: OwnContentType;
  public fileType: FileTypes;
  public libraryFileType: LibraryFileType;
  public url?: string;
  public publishedAt?: Date;
  public coverFileHash?: string;
  public defaultImage?: string;
  public path?: string;
  public slug?: string;
  public tags?: Tag[];
  public content?: ContentEntity;
  public folder?: LibraryItem;
  public writeRoles?: UserRole[];
  public readRoles?: UserRole[];
  public cpdConfigs?: CpdConfigs[];
  public cpdCredit?: CpdCredit;
}

export class LibraryItemList {
  public libraryItems: LibraryItem[] = [];
  pagination: PaginationOptions = {
    direction: DirectionOptions.desc,
    limit: 5,
    page: 0,
  };
}
