export declare type Language =
  | 'en'
  | 'de'
  | 'es'
  | 'es-la'
  | 'fr'
  | 'it'
  | 'ja'
  | 'ko'
  | 'nl'
  | 'no'
  | 'pt'
  | 'pt-br'
  | 'ru'
  | 'sv'
  | 'zh';
