import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import {
  CoursesList,
  PaginationOptions,
  Step,
  StepExamRule,
} from '../../common';
import { environment } from '../../environments';
import { Hub } from '../abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class ApiCoursesV1Service extends ApiBaseService {
  protected apiBaseUrl = environment.campusV1;
  protected routePath = `course`;
  private headers = {
    headers: this._hubService.hubHeaders(),
  };

  constructor(private _hubService: Hub, private _httpClient: HttpClient) {
    super(_httpClient);
  }

  public saveSurvey<T>(
    courseId: number,
    stepId: number,
    submission?: T,
    questionId?: number
  ): Promise<void> {
    let path = `/${courseId}/step/${stepId}/survey`;
    if (questionId) {
      path += `/${questionId}`;
    }
    return super.post<void>(path, submission, this.headers);
  }

  public enrollCourse(courseId: number): Promise<void> {
    const path = `${courseId}/enroll`;
    return super.post<void>(path, null, this.headers);
  }

  public saveEvaluationScore<T>(
    courseId: number,
    stepId: number,
    instanceId: number,
    submission?: T
  ): Promise<void> {
    const path = `/${courseId}/step/${stepId}/exam/instance/${instanceId}/evaluation`;

    return super.post<void>(path, submission, this.headers);
  }

  public finishEvaluation<T>(
    courseId: number,
    stepId: number,
    instanceId: number,
    submission?: T
  ): Promise<void> {
    const path = `/${courseId}/step/${stepId}/exam/instance/${instanceId}/evaluation/finish`;

    return super.post<void>(path, submission, this.headers);
  }

  public saveExamInstance<T>(
    courseId: number,
    stepId: number,
    questionId?: number,
    submission?: T
  ): Promise<void> {
    let path = `/${courseId}/step/${stepId}/exam/instance/submission`;
    if (questionId) {
      path += `/${questionId}`;
    }
    return super.post<void>(path, submission, this.headers);
  }

  public getCourseSteps(courseId: number): Promise<Step[]> {
    const path = `/${courseId}/step`;
    return super.get<Step[]>(path, this.headers);
  }

  public getCourses(
    paginationOptions: PaginationOptions
  ): Promise<CoursesList> {
    const path = `?direction=${paginationOptions.direction}&limit=${paginationOptions.limit}&orderby=${paginationOptions.orderBy}&page=${paginationOptions.page}`;
    return super.get<CoursesList>(path, this.headers);
  }

  public getCourseStepById(courseId: number, stepId: number): Promise<Step> {
    const path = `${courseId}/step/${stepId}`;
    return super.get<Step>(path, this.headers);
  }

  public getExamInstance<T>(
    courseId: number,
    stepId: number,
    instanceId: number
  ): Promise<T> {
    const path = `/${courseId}/step/${stepId}/exam/instance/${instanceId}`;
    return super.get<T>(path, this.headers);
  }

  public getRules(courseId: number, stepId: number): Promise<StepExamRule[]> {
    const path = `${courseId}/step/${stepId}/unlock/rule`;
    return super.get<StepExamRule[]>(path, this.headers);
  }
}
