<div class="participants-list">
  <mec-social-participants-search
    (searchValueChanged)="getParticipants($event)"
  ></mec-social-participants-search>
  <div class="participants-list__list">
    <mec-social-participants-item
      *ngFor="let participant of participantsList.participants"
      [socialNetworkIds]="socialNetworkIds"
      [participant]="participant"
    ></mec-social-participants-item>
    <h4 *ngIf="!participantsList.participants?.length && !isLoading">
      {{ 'NO_RESULTS_FOUND' | translate }}
    </h4>

    <!-- Loader Skeleton start -->
    <ng-container *ngIf="isLoading">
      <mec-social-participants-item
        *ngFor="let item of [0, 1, 3, 4]"
        [medSkeletonLoading]="true"
        style="height: 120px; width: 100%"
      ></mec-social-participants-item>
    </ng-container>
    <!-- Loader Skeleton end -->
  </div>
</div>
