<div class="evaluation-preview">
  <div class="evaluation-preview__header" *ngIf="isScholarMode">
    <button
      (click)="clickBack.emit()"
      class="btn btn--sm btn--outline btm-right-icon"
    >
      <fa-icon [icon]="backIcon"></fa-icon>
      <span>{{ 'BACK' | translate }}</span>
    </button>
  </div>

  <mat-tab-group
    *ngIf="!isLoading"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    class="evaluation-preview__tabs"
  >
    <mat-tab *ngFor="let examInstance of examInstancesFiltered">
      <ng-template mat-tab-label>
        <span *ngIf="!examInstance?.startedAt">{{
          examInstance.evaluatedAt | date : 'short'
        }}</span>
        <span *ngIf="examInstance?.startedAt">{{
          examInstance.startedAt | date : 'short'
        }}</span>
      </ng-template>
      <div class="evaluation-preview__container">
        <ng-container *ngIf="isScholarMode">
          <div *ngIf="examInstance.score" class="evaluation-feedback card">
            <img
              class="evaluation-feedback__avatar"
              [src]="examInstance?.evaluator?.profile?.evaluatorAvatarUrl"
            />
            <div class="evaluation-feedback__info-container">
              <span class="evaluation-feedback__labels-key">
                {{ 'EVALUATOR' | translate }}
              </span>
              <h4>{{ examInstance.evaluator?.profile?.fullName }}</h4>
              <div
                *ngIf="examInstance?.feedback"
                class="evaluation-feedback__feedback-container"
              >
                <span class="evaluation-feedback__labels-key">
                  {{ 'EXAM_FEEDBACK' | translate }}
                </span>
                <div class="evaluation-feedback__message-container">
                  <p class="evaluation-feedback__message">
                    {{ examInstance?.feedback }}
                  </p>
                </div>
              </div>
              <div class="evaluation-feedback__feedback-container">
                <span class="evaluation-feedback__labels-key">
                  {{ 'AVERAGE' | translate }}
                </span>
                <h2>{{ examInstance.average }}</h2>
              </div>
              <mec-course-evaluation-score
                [examInstance]="examInstance"
                [step]="step"
                [hideFeedback]="true"
              ></mec-course-evaluation-score>
            </div>
          </div>
        </ng-container>

        <div
          class="card evaluation-preview__card"
          *ngFor="let submission of examInstance?.submissions; let i = index"
        >
          <div class="evaluation-preview__title-container">
            <div>
              <h4 class="evaluation-preview__title">
                {{ i + 1 + '. ' }} {{ submission.submission.content.title }}
              </h4>
              <span class="evaluation-preview__extension-info">
                {{ fileMimeTypes[submission.mimeType] }}
              </span>
            </div>
            <button
              (click)="
                downloadFile(
                  submission.fileUrl,
                  submission.filename,
                  submission.mimeType
                )
              "
              *ngIf="submission.type !== stepTypes.OPEN_TEXT"
              class="evaluation-preview__view-button btn btn--sm btn--icon-only btn--no-bg"
            >
              <fa-icon
                class="evaluation-preview__download-icon"
                *ngIf="!isDownloading"
                [icon]="downloadIcon"
              ></fa-icon>
              <mat-spinner *ngIf="isDownloading"></mat-spinner>
            </button>
          </div>

          <div
            *ngIf="submission.type !== stepTypes.OPEN_TEXT"
            class="evaluation-preview__file-container"
          >
            <img
              *ngIf="getFileType(submission.mimeType) === 'image'"
              class="evaluation-preview__img"
              [src]="submission.fileUrl"
            />

            <video
              *ngIf="getFileType(submission.mimeType) === 'video'"
              webkit-playsinline="true"
              [src]="submission.fileUrl"
              controls
            ></video>

            <fa-icon
              class="evaluation-preview__other-icon"
              *ngIf="getFileType(submission.mimeType) === 'other'"
              [icon]="fileIcon"
            ></fa-icon>
          </div>
          <div
            *ngIf="submission.type === stepTypes.OPEN_TEXT"
            class="evaluation-preview__text-container"
          >
            <p class="evaluation-preview__text-answer">{{ submission.text }}</p>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <ng-container *ngIf="isLoading">
    <div [medSkeletonLoading]="true" style="height: 90px"></div>
    <div [medSkeletonLoading]="true" style="height: 1200px"></div>
  </ng-container>
</div>
