import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicHtmlComponent } from './dynamic-html.component';
import { DynamicHTMLOptions } from '../../../common/models/common/dynamic-html/options';
import { DynamicHTMLRendererService } from '../../../services/dynamic-html-renderer.service';

@NgModule({
  declarations: [DynamicHtmlComponent],
  exports: [DynamicHtmlComponent],
  imports: [CommonModule],
})
export class DynamicHtmlModule {
  static forRoot(options: DynamicHTMLOptions): ModuleWithProviders<any> {
    return {
      ngModule: DynamicHtmlModule,
      providers: [
        DynamicHTMLRendererService,
        { provide: DynamicHTMLOptions, useValue: options },
        // { provide: ANALYZE_FOR_ENTRY_COMPONENTS, useValue: options.components, multi: true },
      ],
    };
  }
}
