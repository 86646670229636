import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { AlertData } from '../../../common/models/common/alert/alert-data';
import { CommonService } from '../../../services/common.service';
import {
  faCircleQuestion,
  faTriangleExclamation,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'mec-alert',
  standalone: true,
  imports: [CommonModule, MatDialogModule, FontAwesomeModule],
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  public confirmIcon = faCircleQuestion;
  public errorIcon = faTriangleExclamation;
  public helpIcon = faCircleInfo;

  constructor(
    public _commonService: CommonService,
    public _dialogRef: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertData
  ) {}
}
