import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import {
  ApiCoursesV1Service,
  CommonService,
  ToastService,
} from '../../../../services';
import { StepExamRule } from '../../../../common';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'mec-step-exam-rules',
  standalone: true,
  imports: [CommonModule, TranslateModule, FontAwesomeModule],
  templateUrl: './step-exam-rules.component.html',
  styleUrls: ['./step-exam-rules.component.scss'],
})
export class StepExamRulesComponent implements OnInit {
  @Input() courseId: number;
  @Input() stepId: number;

  @Output() hasRules = new EventEmitter<boolean>();

  public rules: StepExamRule[];

  public checkIcon = faCheck;

  constructor(
    public _commonService: CommonService,
    private _apiCoursesV1Service: ApiCoursesV1Service,
    private _toastService: ToastService
  ) {}

  public async ngOnInit(): Promise<void> {
    await this._loadRules();
  }

  private async _loadRules(): Promise<void> {
    try {
      this.rules = await this._apiCoursesV1Service.getRules(
        this.courseId,
        this.stepId
      );

      if (!this.rules || !this.rules?.length) {
        this.hasRules.emit(false);
      } else {
        this.hasRules.emit(true);
      }
    } catch (error) {
      this._toastService.showError(error);
    }
  }
}
