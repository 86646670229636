import { Injectable } from '@angular/core';
import { MepStorage } from './abstract/mep-storage.abstract';

// interface LocalStorage {
//   [name: string]: any;
//   length: number;
//   clear(): void
//   getItem(key: string): string
//   key(index: number): string
//   removeItem(key: string): void
//   setItem(key: string, value: string): void
// }

@Injectable({
  providedIn: 'root',
})
export class StorageWebService implements MepStorage {
  public get(key: string): string | null {
    return localStorage.getItem(key);
  }

  public set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public remove(key: string) {
    localStorage.removeItem(key);
  }

  public removeItems(keys: any[]): void {
    keys.forEach((key) => {
      this.remove(key);
    });
  }

  public setItems(items: { [key: string]: any }): void {
    Object.keys(items).forEach((key) => {
      const value = items[key].toString();
      this.set(key, value);
    });
  }
}
