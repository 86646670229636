import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { environment } from '../../environments';
import { Hub } from '../abstract/hub.abstract';
import { Tag } from '../../common/models/tags/tag';

@Injectable()
export class ApiTagsService extends ApiBaseService {
  protected apiBaseUrl = environment.campusV2;
  protected routePath = `hubs/${this._hubService.hub()}/tags`;

  constructor(private _hubService: Hub, private _httpClient: HttpClient) {
    super(_httpClient);
  }

  public getTagBySlug(webinarId: string): Promise<Tag> {
    const path = `/${webinarId}`;
    return super.get<Tag>(path);
  }

  public getTags(): Promise<Tag[]> {
    const path = ``;
    return super.get<Tag[]>(path);
  }
}
