<div class="banner" [ngClass]="{ 'banner--is-short': isShort }">
  <div class="banner__info">
    <div class="banner__info-texts" *ngIf="!isLoadingInstance">
      <h1 class="banner__title">{{ title }}</h1>
      <h3 class="banner__subtitle">{{ subtitle }}</h3>
    </div>
    <div *ngIf="!authService.isLogged" class="banner__actions">
      <button class="btn" (click)="goToPage('sign-up')">
        {{ 'GET_STARTED' | translate }}
      </button>
      <h4>
        {{ 'AUT.SIGN_UP.ACTIONS.SIGN_UP_1' | translate }}
        <span (click)="goToPage('login')" class="banner__actions-login">
          {{ 'LOGIN' | translate }}</span
        >
      </h4>
    </div>
  </div>

  <div class="banner__image-container">
    <img
      class="banner__img"
      [ngClass]="{ hidden: isLoadingImage || hiddenImage }"
      (error)="imageError()"
      (load)="imageLoaded()"
      src="{{ bannerImageUrl }}"
    />
    <!-- Skeleton image start -->
    <div
      [medSkeletonLoading]="true"
      *ngIf="isLoadingImage"
      style="width: 100%; height: 250px; margin-top: 24px"
      class="banner__img"
    ></div>
    <!-- Skeleton image end -->
  </div>
</div>
