import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SocialNetworkReactions } from '../../enums/social-network/social-network-reactions';
import {
  faThumbsUp,
  faHeart,
  faLightbulb,
  faHandHoldingHeart,
  faFaceLaughBeam,
} from '@fortawesome/free-solid-svg-icons';

export class SocialNetWorkReactionsInfo {
  public reactions: { [name: string]: { name: string; icon: IconProp } } = {
    [SocialNetworkReactions.LIKE]: {
      name: SocialNetworkReactions.LIKE,
      icon: faThumbsUp,
    },
    [SocialNetworkReactions.LOVE]: {
      name: SocialNetworkReactions.LOVE,
      icon: faHeart,
    },
    [SocialNetworkReactions.GENIUS]: {
      name: SocialNetworkReactions.GENIUS,
      icon: faLightbulb,
    },
    [SocialNetworkReactions.SUPPORT]: {
      name: SocialNetworkReactions.SUPPORT,
      icon: faHandHoldingHeart,
    },
    [SocialNetworkReactions.FUNNY]: {
      name: SocialNetworkReactions.FUNNY,
      icon: faFaceLaughBeam,
    },
  };
}
