import { ContentType } from '../../../enums/course/content-type';
import { StepType } from '../../../enums/course/step-type';
import { ContentEntity } from '../../common/content-entity';

export declare class StepSubmissionExam {
  public id: number;
  public order: number;
  public savedItem: boolean;
  public type: StepType;
  //UPLOAD TYPE
  public file?: number;
  public fileHash?: string;
  public filename?: string;
  public fileUrl?: string;
  public mimeType?: string;
  public allowedExtensions?: string;
  public contentType?: ContentType;
  public content?: ContentEntity;
  public submission?: StepSubmissionExam;
  public defaultValue?: StepSubmissionDefaultValue | string;
  //OPENT TEXT TYPE
  public maxLength?: number;
  public minLength?: number;
  public text?: string;
}

export class StepSubmissionDefaultValue {
  public name: string;
  public type: string;
  public content: string;
}
