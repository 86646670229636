<div class="progress-bar card">
  <fa-icon class="progress-bar__icon" [icon]="infoIcon"></fa-icon>
  <div class="progress-bar__bar-container">
    <div class="progress-bar__info">
      <h5 class="progress-bar__name">{{ fileName }}</h5>
      <h4 class="progress-bar__current-percentage">
        {{ progressValue + '%' }}
      </h4>
    </div>
    <mat-progress-bar
      class="progress-bar__bar"
      [color]="color"
      [mode]="'determinate'"
      [value]="progressValue"
    >
    </mat-progress-bar>
  </div>
</div>
