import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiSocialNetworksV1Service } from '../../../services/api/api-social-networks-V1.service';
import { SocialNetWorkThread } from '../../../common/models/social-network/social-network-thread';
import { ToastService } from '../../../services/toast.service';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SocialPublicationBodyComponent } from '../social-publication-body/social-publication-body.component';
import { SocialPublicationReactionComponent } from '../social-publication-reaction/social-publication-reaction.component';
import { SocialPublicationReactionsInfoComponent } from '../social-publication-reactions-info/social-publication-reactions-info.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../services/alert.service';
import { MepActionButton } from '../../../common/models/common/action-button/mep-action-button';
import { MatMenuModule } from '@angular/material/menu';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'mec-social-comment-item',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    SocialPublicationBodyComponent,
    SocialPublicationReactionComponent,
    SocialPublicationReactionsInfoComponent,
    MatMenuModule,
    TranslateModule,
  ],
  providers: [ApiSocialNetworksV1Service],
  templateUrl: './social-comment-item.component.html',
  styleUrls: ['./social-comment-item.component.scss'],
})
export class SocialCommentItemComponent implements OnChanges {
  @Input() comment: SocialNetWorkThread;
  @Output() socialThreadDeleteChange = new EventEmitter<void>();

  public editIcon = faEllipsis;
  public profileAvatarUrl: string;
  public hasAnyReaction: boolean;

  public deleteOptionsAlert: MepActionButton[] = [
    {
      text: 'Confirm delete',
      role: 'destructive',
      data: {
        action: 'delete',
      },
    },
    {
      text: 'Cancel',
      role: 'cancel',
      data: {
        action: 'cancel',
      },
    },
  ];

  constructor(
    private _translateService: TranslateService,
    private _alertService: AlertService
  ) {}

  public async deleteThread(): Promise<void> {
    const titleAlert = this._translateService.instant('CONFIRM_DELETE');
    const alertResult = await this._alertService.showConfirmation(
      titleAlert,
      '',
      'CORE.POPUP_CONFIRM.CONFIRM',
      'CANCEL'
    );

    if (alertResult) {
      this.socialThreadDeleteChange.emit();
    }
  }

  public ngOnChanges(): void {
    if (this.comment) {
      this.profileAvatarUrl = CommonHelper.getAvatarImage(
        this.comment.profile.id
      );

      this.hasAnyReaction = this.comment.countReactions.some(
        (reaction) => reaction.count > 0
      );
    }
  }
}
