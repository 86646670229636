import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { environment } from '../../environments';
import { Hub } from '../abstract/hub.abstract';
import { EventWebinar } from '../../common/models/event/event-webinar';
import { EventRegistrationData } from '../../common/models/event/event-registration-data';

@Injectable()
export class ApiWebinarsService extends ApiBaseService {
  protected apiBaseUrl = environment.socialV1;
  protected routePath = `webinars`;
  private headers = {
    headers: this._hubService.hubHeaders(),
  };

  constructor(private _hubService: Hub, private _httpClient: HttpClient) {
    super(_httpClient);
  }

  public saveWebinarForm(
    webinarId: string,
    registrationData: EventRegistrationData[]
  ): Promise<EventWebinar> {
    const path = `/${webinarId}/registrants`;
    return super.post<EventWebinar>(path, registrationData);
  }

  public getWebinarById(webinarId: string): Promise<EventWebinar> {
    const path = `/${webinarId}`;
    return super.get<EventWebinar>(path);
  }

  public saveMessageRoom(
    webinarId: string,
    roomId: string,
    message: string
  ): Promise<void> {
    const path = `/${webinarId}/rooms/${roomId}/messages`;
    return super.post<void>(path, [{ message }]);
  }
}
