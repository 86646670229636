import { ArticleMenu } from '../../enums/article/article-menu';
import { ArticleStatus } from '../../enums/article/article-status';
import { ArticleType } from '../../enums/article/article-type';
import { ArticleVisibility } from '../../enums/article/article-visibility';
import { ContentEntity } from '../common/content-entity';
import { Language } from '../common/language/language';
import { Tag } from '../tags/tag';

export declare class Page {
  public content: ContentEntity;
  public id: number;
  public languages: Language[];
  public menu: ArticleMenu;
  public publishedAt: Date;
  public savedItem: boolean;
  public searchable: boolean;
  public slug: string;
  public status: ArticleStatus;
  public tags: Tag[];
  public title: string;
  public type: ArticleType;
  public visibility: ArticleVisibility;
}
