import { Subject } from 'rxjs';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { environment } from '../environments/environment';
import { i18nService } from './i18n.service';
import { Hub } from './abstract/hub.abstract';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { CustomHubConfiguration } from '../common/models/hub/custom-hub-configuration';
import { CommonHelper } from '../common/helpers/common.helper';
import { HubInstance } from '../common/models/hub/hub-instance';
import { SEOService } from './seo.service';

@Injectable({
  providedIn: 'root',
})
export class HubWebService implements Hub {
  private _basePath: string;
  private _hub: string;
  private _hubHeaders: Object;
  private _hubConfiguration: CustomHubConfiguration;
  private _renderer2: Renderer2;
  public onHubChange = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private _document: Document,
    private _rendererFactory2: RendererFactory2,
    private _i18nService: i18nService
  ) {
    this._renderer2 = this._rendererFactory2.createRenderer(null, null);
  }

  public setFixedHub(hub: string): void {
    this._hub = hub;

    this.onHubChange.next();
  }

  public setHub(): void {
    if (this._hub) {
      return;
    }

    this._hub = this._getUrlHub() || 'hub-meplis';

    this.onHubChange.next();
  }

  public host(): string {
    return [
      'https://',
      environment.name === 'DEVELOPMENT' ? 'dev-' : '',
      this._hub,
      '.meplis.com',
    ].join('');
  }

  public hub(): string {
    return this._hub;
  }

  public getComponents() {
    return this._hubConfiguration?.components;
  }

  public setHubConfiguration(configuration: CustomHubConfiguration) {
    this._hubConfiguration = configuration;
  }

  public setHubStyles(configuration: CustomHubConfiguration): void {
    if (!configuration?.customStyle) {
      return;
    }
    const style = this._renderer2.createElement('link') as HTMLLinkElement;
    this._renderer2.appendChild(this._document.head, style);

    this._renderer2.setProperty(style, 'rel', 'stylesheet');
    this._renderer2.setProperty(style, 'href', `${configuration.slug}.css`);
  }

  public getHubConfiguration(): CustomHubConfiguration {
    return this._hubConfiguration;
  }

  public getHubConfigurationByProp(prop: any): any {
    return this._hubConfiguration
      ? this._hubConfiguration[prop as keyof CustomHubConfiguration]
      : null;
  }

  public basePath() {
    return this._basePath;
  }

  public setBasePath(basePath: string) {
    this._basePath = basePath;
  }

  public _getUrlHub(): string {
    if (!isPlatformBrowser(this.platformId)) {
      return '';
    }

    const basePathParts = this._basePath ? this._basePath.split('/') : [];
    const currentUrl = location.pathname.replace('/', '');
    const currentUrlArray = currentUrl.split('/');

    let hub = '';

    if (basePathParts.length === 3) {
      hub = currentUrlArray[0];
    } else {
      if (environment.name === 'PRODUCTION') {
        const hostName = location.hostname;
        const urlWithoutSeo = hostName.replace('seo-', '');
        const hubProArray = urlWithoutSeo.split('.');
        hub = hubProArray[0];
      } else if (environment.name === 'DEVELOPMENT') {
        const urlWithoutDev = location.hostname.replace('dev-', '');
        const urlWithoutSeo = urlWithoutDev.replace('seo-', '');
        const hubDevArray = urlWithoutSeo.split('.');
        hub = hubDevArray[0];
      }
    }

    return hub;
  }

  public hubHeaders(): any {
    if (!this._hubHeaders) {
      this.setHubHeaders();
    }
    return this._hubHeaders;
  }

  public setHubHeaders(): void {
    this._hubHeaders = {
      'x-mepl-campus': this._hub,
      'x-Mepl-Language': this._i18nService.language,
      'x-Mepl-Region-Slug': this._i18nService.region,
    };
  }

  public getSetting(type: string): string | null {
    return (
      this._hubConfiguration.settings.find((setting) => setting.type === type)
        ?.value || null
    );
  }
}
