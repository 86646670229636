export declare type SettingsType =
  | 'ALLOW_ENROLLMENT_WHEN_PREVIOUSLY_INVITED_IN_HUB'
  | 'ALLOW_INDEXATION'
  | 'CAREMONITOR_TENANT_INTEGRATION'
  | 'CUSTOM_TRANSLATION_KEY'
  | 'DEFAULT_LOGGED_STATE'
  | 'DISABLE_CHAT_REQUIREMENTS'
  | 'DISABLE_EVENTS'
  | 'DISABLE_LIBRARY'
  | 'DISABLE_NEWS'
  | 'DISABLE_SEARCH'
  | 'ENABLE_CHAT_FEATURE'
  | 'ENABLE_GAMEFICATION'
  | 'EVALUATOR_CAN_INVITE_PARTICIPANTS'
  | 'EVALUATOR_ONLY_SEE_PARTICIPANTS_INVITED_BY_HIM'
  | 'EXPLICIT_ENROLL'
  | 'GOOGLE_ANALYTICS_CODE'
  | 'GOOGLE_ANALYTICS_CODE_GA4'
  | 'HAS_EVENTS'
  | 'HAS_ITEMS_ON_LIBRARY'
  | 'HAS_LANGUAGE_DISAMBIGUATION_LANDING'
  | 'HAS_NEWS'
  | 'INVITE_ONLY'
  | 'NOTIFICATION_METHOD'
  | 'PERMISSION_TO_CREATE_PORTFOLIO'
  | 'SCORE_SYSTEM'
  | 'SEND_WELCOME_EMAIL'
  | 'SHOW_EVALUATORS_ON_DASHBOARD'
  | 'SHOW_FEATURES_ON_DASHBOARD'
  | 'SKIP_DASHBOARD'
  | 'TRACK_NAVIGATION'
  //course types
  | 'TIME_LIMIT_COUNTDOWN_TRIGGER'
  | 'INVITED_ONLY'
  | 'TIME_LIMIT_CHANGES_MUST_BE_CASCADED'
  | 'TIME_LIMIT_DURATION_IN_DAYS'
  | 'HAS_NEWS'
  | 'SUPPORTED_INTERFACE'
  | 'HAS_EVENTS'
  | 'ACCEPT_TIME_EXTENSION_REQUEST'
  | 'HAS_ITEMS_ON_LIBRARY'
  | 'COURSE_TYPE'
  | 'PRICE'
  | 'ISSUE_CERTIFICATE';
