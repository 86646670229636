import { CustomFormType } from '../../enums/forms/custom-form-type';
import { CustomForm } from './custom-form';
import { CustomFormAnswer } from './custom-form-answer';

export class ProfileCustomForm {
  public id: string;
  public profile: string;
  public origin: CustomFormType;
  public form: CustomForm;
  public createdAt: Date;
  public answers?: CustomFormAnswer[];
}
