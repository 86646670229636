<div
  class="event-banner"
  [ngClass]="{ 'event-banner--has-side-bar': isLogged }"
>
  <div class="event-banner__info">
    <div class="event-banner__info-texts">
      <h1 class="event-banner__title">{{ event?.content?.title }}</h1>
      <h3 class="event-banner__subtitle">{{ event?.content?.excerpt }}</h3>
    </div>
    <div *ngIf="!isLogged && !hideWatch" class="event-banner__actions">
      <button class="btn" (click)="login()">
        {{ 'WATCH_NOW' | translate }}
      </button>
    </div>
  </div>

  <div class="event-banner__image-container">
    <img
      class="event-banner__img"
      [ngClass]="{ hidden: isLoadingImage || hiddenImage }"
      (error)="imageError()"
      (load)="imageLoaded()"
      src="{{ defaultImageUrl }}"
    />
    <!-- Skeleton image start -->
    <div
      [medSkeletonLoading]="true"
      *ngIf="isLoadingImage"
      style="width: 100%; height: 100%"
      class="event-banner__img"
    ></div>
    <!-- Skeleton image end -->
  </div>
</div>
