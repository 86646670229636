<div class="repost-body">
  <div class="repost-body__profile">
    <div class="repost-body__container-avatar">
      <img class="repost-body__avatar" src="{{ avatarUrl }}" />
      <div class="repost-body__container-name">
        <h4 class="repost-body__name">
          {{ socialThreadToRepost?.profile?.name }}
        </h4>
        <small class="repost-body__date">{{
          socialThreadToRepost?.publishedAt | date : 'longDate'
        }}</small>
      </div>
    </div>
  </div>
  <div class="repost-body__body">
    <mec-social-publication-body
      [socialThread]="socialThreadToRepost"
    ></mec-social-publication-body>
  </div>
</div>
