import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments';
import { ApiBaseService } from './api-base.service';
import { PaginationOptions, SearchResult } from '../../common';
import { Hub } from '../abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class ApiCpdManagementService extends ApiBaseService {
  protected apiBaseUrl = environment.campusV2;
  protected routePath = `hubs/${this._hubService.hub()}/cpd-management`; // : string;

  constructor(private _hubService: Hub, private _httpClient: HttpClient) {
    super(_httpClient);

    // this._hubService.onHubChange.subscribe(() => {
    //   this.routePath = `hubs/${this._hubService.hub()}/cpd-management`;
    // });
  }

  public getCertifications(
    paginationOptions: PaginationOptions
  ): Promise<SearchResult[]> {
    const path = `?limit=${paginationOptions.limit}&orderby=${paginationOptions.orderBy}&page=${paginationOptions.page}`;

    return super.get<SearchResult[]>(path);
  }

  public getCertificatesMe(
    paginationOptions: PaginationOptions
  ): Promise<SearchResult[]> {
    const path = `?fields=cpd&limit=${paginationOptions.limit}&orderby=${paginationOptions.orderBy}&page=${paginationOptions.page}`;

    return super.get<SearchResult[]>(path);
  }
}
