export enum StepRuleOperator {
  GREATER_THAN = 'GREATER_THAN',
  LOWER_THAN = 'LOWER_THAN',
  EQUALS = 'EQUALS',
  GREATER_OR_EQUALS = 'GREATER_OR_EQUALS',
  LOWER_OR_EQUALS = 'LOWER_OR_EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  LOWER_THAN_OR_EQUALS = 'LOWER_THAN_OR_EQUALS',
  GREATER_THAN_OR_EQUALS = 'GREATER_THAN_OR_EQUALS',
}
