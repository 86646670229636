<div class="social-share">
  <share-button
    theme="circles-dark"
    [button]="'linkedin'"
    [showIcon]="true"
    [showText]="false"
    [url]="url"
    [description]="description"
    (opened)="sharedOpened('linkedin')"
    class="pt-3"
  >
  </share-button>
  <share-button
    theme="circles-dark"
    [button]="'facebook'"
    [showIcon]="true"
    [showText]="false"
    [url]="url"
    [description]="description"
    (opened)="sharedOpened('facebook')"
    class="pt-3"
  >
  </share-button>
  <share-button
    theme="circles-dark"
    [button]="'twitter'"
    [showIcon]="true"
    [showText]="false"
    [url]="url"
    [description]="description"
    (opened)="sharedOpened('twitter')"
    class="pt-3"
  >
  </share-button>
</div>
