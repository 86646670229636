import { ProgramLog } from './program-log';
import { ProgramValidation } from './program-validation';
import { ProgramValue } from './program-value';
import { ProgramVariable } from './program-variable';

export class ProgramNode {
  public category?: string;
  public copyright?: string;
  public description?: string;
  public labelText?: string;
  public message?: string;
  public from?: string[];
  public id: string;
  public key?: string;
  public loc?: string;
  public multiple: boolean;
  public required: boolean;
  public name?: string;
  public project?: string;
  public title?: string;
  public toId: string;
  public token: string;
  public type: string;
  public variable?: ProgramVariable;
  public variables?: ProgramVariable[];
  public values?: ProgramValue[];
  public searchInitialEmpty?: boolean;
  public isValid?: boolean;
  public validation?: ProgramValidation;
  public orientation?: string;
  public group?: string;
  public to?: string;
  public chains?: ProgramNode[];
  public log?: ProgramLog;
}
