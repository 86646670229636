import { OwnContentType } from './../../../common/enums/common/own-content-type';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faEllipsisVertical,
  faFile,
  faFolderOpen,
} from '@fortawesome/free-solid-svg-icons';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AlertService } from '../../../services/alert.service';
import { ApiLibraryService } from '../../../services/api/api-library.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { CommonService } from '../../../services/common.service';
import { CpdService } from '../../../services/cpd.service';
import { FileTypes } from '../../../common/enums/file/file-types';
import { InstanceService } from '../../../services/instance.service';
import { LibraryEditModalComponent } from '../library-edit-modal/library-edit-modal.component';
import { LibraryItem } from '../../../common/models/library/library-item';
import { LibraryTypes } from '../../../common/enums/library/library-types';
import { MepActionButton } from '../../../common/models/common/action-button/mep-action-button';
import { ModalService } from '../../../services/modal.service';
import { ToastService } from '../../../services/toast.service';
import { LibraryFileType } from '../../../common/enums/library/library-file-types';

@Component({
  selector: 'mec-library-item',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule, MatMenuModule],
  providers: [ApiLibraryService],
  templateUrl: './library-item.component.html',
  styleUrls: ['./library-item.component.scss'],
})
export class LibraryItemComponent implements OnChanges {
  @Output() public deleteEventEmmiter = new EventEmitter<number>();
  @Output() public editEventEmmiter = new EventEmitter<number>();

  @Input() public isRowList: boolean;
  @Input() public libraryItem: LibraryItem;

  public canEdit: boolean;
  public isLogged: boolean;
  public fileTypes = FileTypes;
  public imageUrl = '../../../../../common/src/lib/assets/image.png';
  public libraryTypes = LibraryTypes;

  public deleteOptionsAlert: MepActionButton[] = [
    {
      text: 'Confirm delete',
      role: 'destructive',
      data: {
        action: 'delete',
      },
    },
    {
      text: 'Cancel',
      role: 'cancel',
      data: {
        action: 'cancel',
      },
    },
  ];

  public fileIcon = faFile;
  public optionsIcon = faEllipsisVertical;
  public folderIcon = faFolderOpen;

  constructor(
    private _alertService: AlertService,
    private _apiLibraryService: ApiLibraryService,
    private _authService: AuthenticationService,
    private _commonService: CommonService,
    private _cpdService: CpdService,
    private _instanceService: InstanceService,
    private _modalService: ModalService,
    private _toastService: ToastService,
    private _translateService: TranslateService
  ) {
    this.isLogged = this._authService.isLogged;
  }

  public ngOnChanges(): void {
    if (this.libraryItem) {
      this._verifyCanEdit();
    }

    if (this.libraryItem?.coverFileHash || this.libraryItem?.fileHash) {
      const urlId =
        this.libraryItem?.coverFileHash || this.libraryItem?.fileHash;
      this.imageUrl = CommonHelper.getUrlFile(urlId);
    }
  }

  public goToFolder(folderPath: string): void {
    this._commonService.navigate(['library-folder', folderPath]);
  }

  public downloadItem(hash: string) {
    this._commonService.downloadFileFromUrl(
      CommonHelper.getFileUrl(hash, {
        access_token: this._authService.getToken(),
        download: true,
      }),
      this.libraryItem?.content?.title
    );

    this._createCpdSurvey();
  }

  public editLibraryFile(libraryItem: LibraryItem): void {
    if (libraryItem.libraryFileType === LibraryFileType.URL_BASED) {
      this.goToPage(libraryItem.url);
      return;
    }

    const modalRef = this._modalService.openModal(
      LibraryEditModalComponent,
      { id: libraryItem.id },
      null,
      800
    ) as any;

    modalRef.afterClosed().subscribe((modalResul: any) => {
      if (modalResul) {
        this.editEventEmmiter.emit();
      }
    });
  }

  public async deleteItem(id: number): Promise<void> {
    const titleAlert = this._translateService.instant('CONFIRM_DELETE');
    const alertResult = await this._alertService.showConfirmation(
      titleAlert,
      '',
      'CORE.POPUP_CONFIRM.CONFIRM',
      'CANCEL'
    );

    if (alertResult) {
      try {
        await this._apiLibraryService.deleteLibraryItem(id);
        this.deleteEventEmmiter.emit(id);
      } catch (error) {
        this._toastService.showError(error);
      }
    }
  }

  private async _verifyCanEdit(): Promise<void> {
    try {
      this.canEdit = await this._instanceService.canEditLibraryItem(
        this.libraryItem
      );
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  private _createCpdSurvey() {
    if (!this._commonService.isPlatformBrowser || !this.isLogged) return;
    const { cpdConfigs, ownContentType, id } = this.libraryItem;
    this._cpdService.createSurvey(cpdConfigs, ownContentType, id);
  }

  public goToPage(url: string): void {
    this._commonService.navigateByUrl(url, undefined, true);
  }
}
