<div class="cookie-bar" [@fadeInOut]>
  <div class="cookie-bar__container">
    <div class="cookie-bar__left">
      <fa-icon class="cookie-bar__icon" [icon]="cookieIcon"></fa-icon>
      <div class="cookie-bar__middle">
        <p class="cookie-bar__message">
          <span>
            {{ 'This site uses cookies as described in our' | translate }}
            &nbsp;</span
          >
          <a
            (click)="goToPage('cookie-policy')"
            target="_blank"
            class="cookie-bar__link"
          >
            {{ 'COOKIE_POLICY' | translate }} </a
          >.
          <span>
            &nbsp;
            {{ 'To see what cookies we serve, please check our' | translate }}
            &nbsp;</span
          >
          <a
            (click)="goToPage('cookie-policy#cookie-settings')"
            target="_blank"
            class="cookie-bar__link"
          >
            {{ 'Cookie Settings Panel' | translate }} </a
          >.
        </p>
      </div>
    </div>

    <div class="cookie-bar__right">
      <button
        (click)="acceptCookies()"
        class="btn btn--sm cookie-bar__confirm-btn"
      >
        {{ 'UNDERSTOOD' | translate }}
      </button>
    </div>
  </div>
</div>
