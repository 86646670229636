import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { Hub } from '@meplis/services';

@Injectable({
  providedIn: 'root',
})
export class HubConfigurationService {
  constructor(private _hubService: Hub) {}

  public loadComponent<T>(
    viewContainerRef: ViewContainerRef,
    defaultComponent: any,
    componentName?: string,
    customComponent?: any, //when pass customComponent you don't need componentName, it'll be ignored,
    data?: any
  ): void {
    let hubComponent;

    if (componentName) {
      componentName = componentName.replace(/-/g, '');

      hubComponent = this._hubService.getComponents()[componentName]?.component;
    }

    if (customComponent) {
      hubComponent = customComponent;
    }

    if (hubComponent) {
      defaultComponent = hubComponent;
    }

    if (!viewContainerRef) {
      return;
    }
    viewContainerRef.clear();

    const componentRef: ComponentRef<typeof customComponent> =
      viewContainerRef.createComponent(defaultComponent);

    if (data) {
      componentRef.instance.data = data;
    }
  }
}
