<div class="card participant-item">
  <img class="participant-item__avatar avatar" [src]="participant?.imageUrl" />
  <div class="participant-item__info-container">
    <h4 *ngIf="participant.role" class="participant-item__role">
      {{ participant.role }}
    </h4>
    <h2 *ngIf="participant.name" class="participant-item__name">
      {{ participant.name }}
    </h2>
    <span *ngIf="participant.country" class="participant-item__country">
      ({{ participant.country }} )</span
    >
    <p
      *ngIf="participant.biography"
      [title]="participant.biography"
      class="line-clamp-3 participant-item__biography"
    >
      {{ participant.biography }}
    </p>

    <div
      *ngIf="participant?.recordings?.length"
      class="participant-item__actions"
    >
      <button
        (click)="updateVideoPlayer(recording.videoUrl, recording.timecode)"
        *ngFor="let recording of participant.recordings"
        class="btn btn--sm btn--icon-text btn--no-bg"
      >
        <fa-icon [icon]="playIcon"></fa-icon>
        <span> {{ recording.description }} </span>
      </button>
    </div>
  </div>
</div>
