<div *ngIf="!showExam && !showResults" class="step-exam">
  <mec-step-exam-common-info
    [step]="step"
    [courseId]="courseId"
    [isLoading]="isLoading"
    [examInstances]="step?.instancies"
    (clickGoToExam)="goToExam()"
    (clickOpenResults)="openResults()"
  ></mec-step-exam-common-info>
</div>

<mec-progress-bar
  *ngIf="showProgressBar"
  [fileName]="fileName"
  [progressValue]="progressValue"
></mec-progress-bar>
<mec-course-evaluation-preview
  *ngIf="showResults"
  [isScholarMode]="true"
  (clickBack)="showResults = false"
  [step]="step"
  [examInstances]="step?.instancies"
></mec-course-evaluation-preview>
<mec-campus-survey
  *ngIf="!showResults"
  [surveyModel]="surveyModel"
  [hideSurvey]="!showExam"
  (closeSurvey)="showExam = false"
  (completeSurvey)="completeExam()"
  (surveyModelChange)="
    saveSubmissionExam($event.questionId, $event.entityValue)
  "
></mec-campus-survey>
