import {
  Injectable,
  Injector,
  ElementRef,
  ComponentFactoryResolver,
  ComponentFactory,
  ComponentRef,
} from '@angular/core';
import { DynamicHTMLOptions } from '../common/models/common/dynamic-html/options';
import { CommonService } from './common.service';
import { noop } from 'rxjs';

export interface DynamicHTMLRef {
  check: () => void;
  destroy: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class DynamicHTMLRendererService {
  private componentFactories = new Map<string, ComponentFactory<any>>();

  private componentRefs = new Map<any, Array<ComponentRef<any>>>();

  constructor(
    private options: DynamicHTMLOptions,
    private cfr: ComponentFactoryResolver,
    private injector: Injector,
    private _commonService: CommonService
  ) {
    this.options.components.forEach(({ selector, component }) => {
      const cf: ComponentFactory<any> =
        this.cfr.resolveComponentFactory(component);

      this.componentFactories.set(selector, cf);
    });
  }

  renderInnerHTML(elementRef: ElementRef, html: string): DynamicHTMLRef {
    if (this._commonService.isPlatformServer) {
      return {
        check: noop,
        destroy: noop,
      };
    }
    elementRef.nativeElement.innerHTML = html;

    const componentRefs: Array<ComponentRef<any>> = [];
    this.options.components.forEach(({ selector }) => {
      const elements = (elementRef.nativeElement as Element).querySelectorAll(
        selector
      );

      Array.prototype.forEach.call(elements, (el: Element) => {
        const content = el.innerHTML;
        const cmpRef = this.componentFactories
          .get(selector)
          .create(this.injector, [], el);

        el.removeAttribute('ng-version');

        //if (cmpRef.instance.dynamicOnMount) {
        let attrsMap: { [key: string]: string };
        if (el.hasAttributes()) {
          Array.prototype.forEach.call(el.attributes, (attr: Attr) => {
            //convert kebab case to camelcase
            const key = attr.name.replace(/-./g, (x) => x[1].toUpperCase());
            attrsMap = {
              ...attrsMap,
              [key]: attr.value,
            };
          });
        }
        //(cmpRef.instance as OnMount).dynamicOnMount(attrsMap, content, el);
        //}

        cmpRef.instance.data = attrsMap;
        componentRefs.push(cmpRef);
      });
    });
    this.componentRefs.set(elementRef, componentRefs);
    return {
      check: () =>
        componentRefs.forEach((ref) => ref.changeDetectorRef.detectChanges()),
      destroy: () => {
        componentRefs.forEach((ref) => ref.destroy());
        this.componentRefs.delete(elementRef);
      },
    };
  }
}
