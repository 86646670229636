import { EventBackgroundOutcome } from './event-background-outcome';
import { EventBackgroundSchedule } from './event-background-schedule';
import { EventBackgroundSession } from './event-background-session';

export class EventBackgroundContent {
  public title?: string;
  public description?: string;
  public schedule?: EventBackgroundSchedule[];
  public outcomes?: EventBackgroundOutcome[];
  public sessions?: EventBackgroundSession[];
}
