import { StepType } from "../../../enums/course/step-type";
import { ContentEntity } from "../../common/content-entity";

export declare class LockedStepRule {
  public content: ContentEntity;
  public id: number;
  public inviteeExclusivity: boolean;
  public isOnline: boolean;
  public order: number;
  public savedItem: boolean;
  public startsLocked: boolean;
  public type: StepType;
}