import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubInstance, Region, Theme } from '../../common';
import { environment } from '../../environments';
import { ApiBaseService } from './api-base.service';
import { Hub } from '../abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class ApiInstanceService extends ApiBaseService {
  protected apiBaseUrl = environment.campusV1;
  protected routePath = `instance`;

  constructor(private _hubService: Hub, private _httpClient: HttpClient) {
    super(_httpClient);
  }

  public getInstance(): Promise<HubInstance> {
    const path = ``;
    return super.get<HubInstance>(path, {
      headers: this._hubService.hubHeaders(),
    });
  }

  public getInstanceWithHeaders(headers: any): any {
    const path = ``;
    return super.get<HubInstance>(path, {
      headers,
      observe: 'response',
    });
  }

  public getTheme(): Promise<Theme> {
    const path = `theme`;
    return super.get<Theme>(path, { headers: this._hubService.hubHeaders() });
  }

  public getRegions(): Promise<Region[]> {
    const path = `region/choice/languages`;
    return super.get<Region[]>(path, {
      headers: this._hubService.hubHeaders(),
    });
  }
}
