import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SocialNetwork } from '../common/models/social-network/social-network';
import { ApiSocialNetworksV2Service } from './api/api-social-networks-V2.service';
import { SocialNetworkStatus } from '../common/enums/social-network/social-network-status';

@Injectable({
  providedIn: 'root',
})
export class SocialNetWorkService {
  public socialNetWorksSubject = new BehaviorSubject<SocialNetwork[]>([]);

  public socialPublicationsReloadSubject = new BehaviorSubject<boolean>(false);

  constructor(private _apiSocialNetworksV2: ApiSocialNetworksV2Service) {}

  public async setSocialNetworks(reset?: boolean): Promise<void> {
    if (reset) {
      this.socialNetWorksSubject.next([]);
    }

    const socialNetWorks = await this._apiSocialNetworksV2.getSocialNetworks();
    this.socialNetWorksSubject.next(socialNetWorks);
  }
  public async getSocialNetworksIds(): Promise<string> {
    const socialNetworks = await this.getSocialNetworks();
    const result = socialNetworks
      .map((socialNetWork) => socialNetWork.instanceId)
      .join(',');
    return result;
  }

  public async getSocialNetworkByInstanceId(
    instanceId: string
  ): Promise<SocialNetwork> {
    const networks = await this.getSocialNetworks();
    return networks.find((network) => network.instanceId === instanceId);
  }

  public getSocialNetworks(): Promise<SocialNetwork[]> {
    return new Promise(async (resolve) => {
      let socialNetworks = this.socialNetWorksSubject.getValue();

      if (Object.keys(socialNetworks).length === 0) {
        await this.setSocialNetworks();
        socialNetworks = this.socialNetWorksSubject.getValue();
      }

      socialNetworks = socialNetworks.filter(
        (social) =>
          social.status === SocialNetworkStatus.OPEN && social.instanceId
      );

      resolve(socialNetworks);
    });
  }
}
