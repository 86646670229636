import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  public platform: 'web' | 'mobile';

  public setPlatform(platform: 'web' | 'mobile'): void {
    this.platform = platform;
  }

  public async getAppInfo(): Promise<any> {
    //todo get version

    // this._platform.ready().then(async () => {
    //   const appInfo = await App.getInfo();
    //   return appInfo;
    // });

    return '1.0.0';
  }
}
