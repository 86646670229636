import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiInstanceService } from './api/api-instance.service';
import {
  HubInstance,
  LibraryItem,
  LibraryTypes,
  Region,
  Theme,
  UserRole,
} from '../common';
import { Hub } from './abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class InstanceService {
  private _instanceSubject = new BehaviorSubject<HubInstance>(
    new HubInstance()
  );
  private _regionsSubject = new BehaviorSubject<Region[]>([]);

  constructor(
    private _apiInstanceService: ApiInstanceService,
    private _hubService: Hub
  ) {}

  private async _setRegions(): Promise<void> {
    const regions = await this._apiInstanceService.getRegions();
    this._regionsSubject.next(regions);
  }

  public getRegions(): Promise<Region[]> {
    return new Promise<Region[]>(async (resolve) => {
      let regions = this._regionsSubject.getValue();

      if (regions?.length === 0) {
        await this._setRegions();
        regions = this._regionsSubject.getValue();
      }
      resolve(regions);
    });
  }

  public async setHubInstance(
    reset?: boolean,
    hubHeaders?: Object
  ): Promise<void> {
    if (reset) {
      this._instanceSubject.next(new HubInstance());
    } else {
      if (!hubHeaders) {
        hubHeaders = this._hubService.hubHeaders();
      }
      const instance = await this._apiInstanceService.getInstance();
      this._instanceSubject.next(instance);
    }
  }

  public async getInstanceWithHeaders(hubHeaders: Object): Promise<any> {
    return await this._apiInstanceService.getInstanceWithHeaders(hubHeaders);
  }

  public getInstance(role?: boolean): Promise<HubInstance> {
    return new Promise<HubInstance>(async (resolve) => {
      let instance = this._instanceSubject.getValue();

      if (Object.keys(instance).length === 0 || (role && !instance?.role)) {
        await this.setHubInstance(false);
        instance = this._instanceSubject.getValue();
      }
      resolve(instance);
    });
  }

  public async getTheme(): Promise<Theme> {
    return this._apiInstanceService.getTheme();
  }

  public async getRole(): Promise<UserRole> {
    const instance = await this.getInstance();
    return instance.role;
  }

  public async getPermissionByName(permissionName: string): Promise<string> {
    const role = await this.getRole();
    const permissionByName = role?.permissions.find(
      (permission) => permission === permissionName
    );
    return permissionByName;
  }

  public async getAllPermissions(): Promise<string[]> {
    const role = await this.getRole();
    const permissions = role.permissions;

    return permissions;
  }

  public async canEditLibraryItem(item?: LibraryItem): Promise<boolean> {
    if (item?.type === LibraryTypes.folder) {
      if (!item || !item.writeRoles || !item.writeRoles.length) {
        return false;
      }

      const role = await this.getRole();

      if (!role?.id) {
        return false;
      }

      return item.writeRoles.some(function (writeRole) {
        return writeRole.id === role.id;
      });
    }

    const permissionHubContent = await this.getPermissionByName('HUB_CONTENT');

    if (permissionHubContent && permissionHubContent.length) {
      return true;
    }

    return false;
  }
}
