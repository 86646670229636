import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import { CommonService } from '../services/common.service';

@Directive({
  selector: '[medRetryUrlFile]',
  standalone: true,
})
export class RetryUrlFileDirective implements OnDestroy {
  private _retrySrc: string;
  private _timer: any;

  constructor(
    private _commonService: CommonService,
    private element: ElementRef<any>
  ) {}

  @Input()
  set retrySrc(value: string) {
    this.clearTimer();
    this._retrySrc = value;
    this.element.nativeElement.src = value; //each set on src triggers a new request in google Chrome
  }

  ngOnDestroy() {
    this.clearTimer();
  }

  @HostListener('error')
  loadError() {
    this.clearTimer();

    if (this._commonService.isPlatformBrowser) {
      this._timer = setTimeout(() => {
        this.element.nativeElement.src = this._retrySrc;
      }, 2000);
    }
  }

  @HostListener('load')
  clearTimer() {
    if (this._timer) {
      clearTimeout(this._timer);
      delete this._timer;
    }
  }
}
