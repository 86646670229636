import { EventRecording } from '../event/event-recording';

export class EventParticipant {
  public country: string;
  public biography: string;
  public name: string;
  public imageUrl?: string;
  public recordings: EventRecording[];
  public role: string;
  public subject: string;
}
