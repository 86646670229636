<div
  class="programs-list"
  [loadMore]="programList?.pagination?.loadMore"
  [isLoadingMore]="programList.pagination.isLoadingMore"
  (medInfiniteScroll)="getProgramsList()"
>
  <mec-programs-item
    [program]="program"
    *ngFor="let program of programList.programs"
  ></mec-programs-item>
  <!-- Loader Skeleton start -->
  <ng-container *ngIf="isLoading || programList?.pagination?.isLoadingMore">
    <div
      *ngFor="let item of [0, 1, 2]"
      [medSkeletonLoading]="true"
      style="height: auto; width: 100%; min-height: 200px"
    ></div>
    <!-- Loader Skeleton end -->
  </ng-container>

  <h2 *ngIf="!isLoading && !programList.programs.length">
    {{ 'NO_RESULTS_FOUND' | translate }}
  </h2>
</div>
