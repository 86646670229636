<ng-container *ngIf="!disableItems">
  <div
    class="news-card-list__list-items"
    [loadMore]="searchResultsList?.pagination?.loadMore"
    [isLoadingMore]="searchResultsList.pagination.isLoadingMore"
    (medInfiniteScroll)="getNews()"
  >
    <mec-search-card
      [searchResult]="searchResult"
      *ngFor="let searchResult of searchResultsList.searchResult"
    ></mec-search-card>

    <h2 *ngIf="!isLoadingNews && searchResultsList?.searchResult?.length <= 0">
      {{ 'NO_RESULTS_FOUND' | translate }}
    </h2>

    <!-- Loader Skeleton start -->
    <ng-container *ngIf="searchResultsList?.pagination?.isLoadingMore">
      <div
        *ngFor="let item of [0, 1, 2]"
        [medSkeletonLoading]="true"
        style="height: auto; min-height: 400px; width: 100%"
      ></div>
    </ng-container>
    <!-- Loader Skeleton end -->
  </div>
</ng-container>
