import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApiSurveyService } from '../../../services/api/api-surveys.service';
import { CommonService } from '../../../services/common.service';
import { CustomForm } from '../../../common/models/forms/custom-form';
import { CustomFormComponent } from '../../common/custom-form/custom-form.component';
import { CustomFormType } from '../../../common/enums/forms/custom-form-type';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { SurveyEntity } from '../../../common/models/survey/survey-entity';
import { Routes } from '../../../common/constants/routes.constants';

@Component({
  selector: 'mec-survey-form',
  standalone: true,
  imports: [CommonModule, CustomFormComponent, NgxJsonViewerModule],
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.scss'],
})
export class SurveyFormComponent implements OnInit {
  @Input() surveyId: string;
  @Output() survey = new EventEmitter<SurveyEntity>();

  public defaultImageUrl: string;

  public hasCustomForms = true;
  public hiddenImage: boolean;
  public isLoading: boolean;
  public isLoadingImage: boolean;

  public surveyFormType: CustomFormType = CustomFormType.SURVEY_CPD;
  public surveyForms: CustomForm[] = [];
  public surveyItem: SurveyEntity;

  constructor(
    private _apiSurveyService: ApiSurveyService,
    private _commonService: CommonService
  ) {}

  public async ngOnInit() {
    await this.getSurvey();
  }

  public async getSurvey(): Promise<void> {
    this.isLoading = true;
    try {
      this.surveyItem = await this._apiSurveyService.getSurveyById(
        this.surveyId
      );

      this.surveyItem.form.originId = this.surveyItem.id;
      this.surveyForms.push(this.surveyItem.form);

      this.survey.emit(this.surveyItem);
    } catch (errorSurvey) {
      console.log(errorSurvey);
    }
    this.isLoading = false;
  }
}
