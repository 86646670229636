import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonService } from '../../../services/common.service';
import { Routes } from '../../../common/constants/routes.constants';

@Component({
  selector: 'mec-social-profile-link',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './social-profile-link.component.html',
  styleUrls: ['./social-profile-link.component.scss'],
})
export class SocialProfileLinkComponent {
  @Input() data: any;

  constructor(private _commonService: CommonService) {}

  public async ngOnInit(): Promise<void> {
    if (this.data) {
    }
  }

  public goToPage(profileId: string): void {
    this._commonService.navigate(['participants', 'profile', profileId]);
  }
}
