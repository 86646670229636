import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ApiNewsService } from '../../../services/api/api-news.service';
import { ApiSearchService } from '../../../services/api/api-search.service';
import { InfiniteScrollDirective } from '../../../directives/infinite-scroll.directive';
import { PaginationHelper } from '../../../common/helpers/pagination.helper';
import { SearchCardComponent } from '../../common/search/search-card/search-card.component';
import {
  SearchResult,
  SearchResultList,
} from '../../../common/models/common/search/search-result';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'mec-news-card-list',
  standalone: true,
  imports: [
    CommonModule,
    InfiniteScrollDirective,
    SearchCardComponent,
    SkeletonDirective,
    TranslateModule,
  ],
  providers: [ApiNewsService],
  templateUrl: './news-card-list.component.html',
  styleUrls: ['./news-card-list.component.scss'],
})
export class NewsCardListComponent implements OnInit {
  @Input() public limit: number;
  @Input() public excludes: number;
  @Input() public title = 'NEWS';
  @Input() public maxPage: number;
  @Input() public disableItems: boolean;

  public searchResultsList: SearchResultList = new SearchResultList();
  public isLoadingNews = true;

  constructor(
    private _apiSearchService: ApiSearchService,
    private _toastService: ToastService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.searchResultsList.pagination.types = 'blog';
    if (this.limit) {
      this.searchResultsList.pagination.limit = this.limit;
    }
    this.searchResultsList.pagination.maxPage = this.maxPage;

    //todo put it out of the component

    // this.enableItems = await this._settingsService._getPermissionValue(
    //   'HAS_NEWS'
    // );

    await this.getNews();
  }

  public async getNews(reset?: boolean): Promise<void> {
    this.isLoadingNews = true;
    this.searchResultsList.pagination.isLoadingMore = true;

    if (reset) {
      this.searchResultsList = new SearchResultList();
    }

    try {
      const itemsToAdd = await this._apiSearchService.getSearchResults(
        null,
        this.searchResultsList.pagination,
        null,
        null,
        null,
        'blog',
        this.excludes ? this.excludes.toString() : null
      );
      this.searchResultsList =
        PaginationHelper.newHandlePagination<SearchResult>(
          this.searchResultsList,
          'searchResult',
          itemsToAdd
        );
    } catch (error: any) {
      this._toastService.showError(error);
    }

    this.searchResultsList.pagination.isLoadingMore = false;
    this.isLoadingNews = false;
  }
}
