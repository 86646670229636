import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments';
import { ApiBaseService } from './api-base.service';
import { PaginationOptions, SurveyEntity } from '../../common';
import { Hub } from '../abstract/hub.abstract';

@Injectable({
  providedIn: 'root',
})
export class ApiSurveyService extends ApiBaseService {
  protected apiBaseUrl = environment.campusV2;
  protected routePath = `hubs/${this._hubService.hub()}/surveys`; // : string;

  constructor(private _hubService: Hub, private _httpClient: HttpClient) {
    super(_httpClient);

    // this._hubService.onHubChange.subscribe(() => {
    //   this.routePath = `hubs/${this._hubService.hub()}/survey`;
    // });
  }

  public getSurveyById(id: string): Promise<SurveyEntity> {
    const path = `${id}`;

    return super.get<SurveyEntity>(path);
  }

  public getSurveysCount(): Promise<number> {
    const path = `me/count`;

    return super.get<number>(path);
  }

  public getSurveysMe(): Promise<SurveyEntity[]> {
    const path = `me`;

    return super.get<SurveyEntity[]>(path);
  }
}
