<form [formGroup]="formGroup" class="program-checkbox">
  <h4>{{ programNode?.labelText }}</h4>
  <div formArrayName="options" class="program-checkbox__options">
    <ng-container *ngFor="let item of programNode?.values; let i = index">
      <mat-checkbox [formControlName]="i">
        {{ item.name }}
      </mat-checkbox>
    </ng-container>
  </div>
</form>
