import { OwnContentType } from './../../enums/common/own-content-type';
import { ArticleType } from '../../enums/article/article-type';
import { DirectionOptions } from '../../enums/common/direction-options';
import { ContentEntity } from '../common/content-entity';
import { PaginationOptions } from '../common/pagination-options';
import { CpdConfigs } from '../cpd/cpd-configs';
import { CpdCredit } from '../cpd/cpd-credit';
import { Tag } from '../tags/tag';

export class SummaryNews {
  public id: number;
  public defaultImageHash: string;
  public slug: string;
  public tags: Tag[];
  public languages: string[];
  public content: ContentEntity;
  public type: ArticleType;
  public ownContentType: OwnContentType;
  public cpdConfigs?: CpdConfigs[];
  public cpdCredit?: CpdCredit;
}

export class NewsList {
  public news: SummaryNews[] = [];
  public numberOfPages: number;
  public total: number;
  pagination: PaginationOptions = {
    direction: DirectionOptions.desc,
    limit: 3,
    page: 0,
  };
}
