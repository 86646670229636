<div class="score-info card">
  <fa-icon class="score-info__icon" [icon]="iconInfo"></fa-icon>
  <div class="score-info__container-info">
    <h4
      [innerHTML]="
        'YOU_SCORED_IN_YOUR_LAST_EXAM' | translate : { score: score }
      "
    ></h4>
    <button class="btn btn--sm btn--no-bg" (click)="clickLink()">
      {{ 'SEE_RESULTS' | translate }}
    </button>
  </div>
</div>
