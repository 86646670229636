import { StepType } from '../../enums/course/step-type';
import { ContentEntity } from '../common/content-entity';
import { Evaluation } from '../evaluation/evaluation';
import { StepExamMultiChoinceInstance } from '../evaluation/exam-multi-choice/step-exam-multi-choice-instance';
import { Course } from './courses';

export declare class Step {
  public id: number;
  public order?: number;
  public startsLocked?: boolean;
  public isOnline?: boolean;
  public inviteeExclusivity?: boolean;
  public answered?: boolean;
  public parent?: Step;
  public children?: Step[];
  public type?: StepType;
  public parentId?: number;
  public hasRead?: boolean;
  public hasAttachedLibraryFiles?: boolean;
  public unlocked?: boolean;
  public unlockedAt?: Date;
  public lastTakenStep?: boolean;
  public courseId?: boolean;
  public course?: Course;
  public content?: ContentEntity;
  public evaluations?: Evaluation[];
  public instancies?: StepExamMultiChoinceInstance[];
}
