<div class="content">
  <fa-icon
    class="icon"
    *ngIf="data.icon"
    [ngClass]="data.messageType"
    [icon]="icon"
  ></fa-icon>
  <div class="message" *ngIf="data?.message">
    <span>{{ data.message }}</span>
  </div>
  <button
    class="btn btn--no-bg action {{ data?.messageType }}"
    *ngIf="data?.action"
    (click)="dismiss()"
  >
    <span>{{ data.action }}</span>
  </button>
</div>
