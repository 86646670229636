<section class="certificate">
  <ng-container *ngIf="!certificate">
    <h2>{{ 'YOUR_CERTIFICATE_HAVE_NOT_BEEN_ISSUED' | translate }}</h2>

    <p>{{ 'YOUR_CERTIFICATE_HAVE_NOT_BEEN_ISSUED_DESCRIPTION' | translate }}</p>

    <button
      class="btn btn--lg btn--primary"
      (click)="issueCertificate()"
      [disabled]="isLoading"
      *ngIf="!isAdministrator && !isEvaluator"
      type="button"
    >
      <fa-icon
        *ngIf="isLoading"
        [animation]="'spin'"
        [icon]="iconCircleNotch"
      ></fa-icon
      ><fa-icon *ngIf="!isLoading" [icon]="iconCertificate"></fa-icon
      ><span>{{ 'ISSUE_YOUR_CERTIFICATE' | translate }}</span>
    </button>

    <ng-container *ngIf="isAdministrator || isEvaluator">
      <hr />
      <p>{{ 'CERTIFICATE_IS_ADMIN_EVALUATOR' | translate }}</p>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="certificate">
    <h2>{{ 'YOUR_CERTIFICATE_HAVE_ALYREAD_BEEN_ISSUED' | translate }}</h2>

    <p>
      <mec-dynamic-html
        [content]="
          'YOUR_CERTIFICATE_HAVE_ALYREAD_BEEN_ISSUED_DESCRIPTION'
            | translate
              : {
                  identifier: certificate.identifier,
                  issuedAt: (certificate.issuedAt | date : 'short')
                }
        "
      ></mec-dynamic-html>
    </p>

    <a
      class="btn btn--lg btn--primary"
      [href]="certificate.fileHash | cdnImages:{
        access_token: accessToken,
      }"
      rel="noreferrer noopener"
      target="_blank"
      type="button"
      ><fa-icon [icon]="iconDownload"></fa-icon
      >{{ 'DOWNLOAD_YOUR_CERTIFICATE' | translate }}</a
    >
  </ng-container>
</section>
