import { Route } from '@angular/router';
import { AuthenticationGuard } from '@meplis/services';
import { Routes } from '@meplis/common';

export const ROUTES: Route[] = [
  {
    path: Routes.home.fullPath,
    loadComponent: () =>
      import('./pages/home/home.base.component').then(
        (c) => c.HomeBaseComponent
      ),
  },
  {
    path: Routes.login.fullPath,
    loadComponent: () =>
      import('./pages/login/login.component').then((c) => c.LoginComponent),
  },
  {
    path: Routes.authentication.fullPath,
    redirectTo: Routes.login.fullPath,
  },
  {
    path: Routes.signUp.fullPath,
    loadComponent: () =>
      import('./pages/signup/signup.component').then((c) => c.SignupComponent),
  },
  {
    path: Routes.recoveryPassword.fullPath,
    loadComponent: () =>
      import('./pages/recovery-password/recovery-password.component').then(
        (c) => c.RecoveryPasswordComponent
      ),
  },
  {
    path: Routes.news.fullPath,
    loadComponent: () =>
      import('./pages/news/news.base.component').then(
        (c) => c.NewsBaseComponent
      ),
  },
  {
    path: Routes.newsDetail.fullPath,
    loadComponent: () =>
      import('./pages/news-detail/news-detail.base.component').then(
        (c) => c.NewsDetailBaseComponent
      ),
  },
  {
    path: Routes.events.fullPath,
    loadComponent: () =>
      import('./pages/events/events.base.component').then(
        (c) => c.EventsBaseComponent
      ),
  },
  {
    path: Routes.event.fullPath,
    loadComponent: () =>
      import('./pages/event/event.base.component').then(
        (c) => c.EventBaseComponent
      ),
  },
  {
    path: Routes.courses.fullPath,
    loadComponent: () =>
      import('./pages/courses/courses.component').then(
        (c) => c.CoursesComponent
      ),
  },
  {
    path: Routes.course.fullPath,
    loadComponent: () =>
      import('./pages/course/course.base.component').then(
        (c) => c.CourseBaseComponent
      ),
  },
  {
    path: Routes.cookiePolicy.fullPath,
    loadComponent: () =>
      import(
        './pages/policies/cookie-policy/cookie-policy.base.component'
      ).then((c) => c.CookiePolicyBaseComponent),
  },
  {
    path: Routes.disclaimer.fullPath,
    loadComponent: () =>
      import('./pages/policies/disclaimer/disclaimer.base.component').then(
        (c) => c.DisclaimerBaseComponent
      ),
  },
  {
    path: Routes.privacyPolicy.fullPath,
    loadComponent: () =>
      import(
        './pages/policies/privacy-policy/privacy-policy.base.component'
      ).then((c) => c.PrivacyPolicyBaseComponent),
  },
  {
    path: Routes.legalNotice.fullPath,
    loadComponent: () =>
      import('./pages/policies/legal-notice/legal-notice.base.component').then(
        (c) => c.LegalNoticeBaseComponent
      ),
  },
  {
    path: Routes.termsOfService.fullPath,
    loadComponent: () =>
      import(
        './pages/policies/terms-of-service/terms-of-service.base.component'
      ).then((c) => c.TermsOfServiceBaseComponent),
  },
  {
    path: Routes.search.fullPath,
    loadComponent: () =>
      import('./pages/search/search.base.component').then(
        (c) => c.SearchBaseComponent
      ),
  },
  {
    path: Routes.tags.fullPath,
    loadComponent: () =>
      import('./pages/tags/tags.base.component').then(
        (c) => c.TagsBaseComponent
      ),
  },
  {
    path: Routes.profile.fullPath,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuard],
    loadComponent: () =>
      import('./pages/profile/profile.component').then(
        (c) => c.ProfileComponent
      ),
  },
  {
    path: Routes.evaluations.fullPath,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuard],
    loadComponent: () =>
      import('./pages/evaluations/evaluations.component').then(
        (c) => c.EvaluationsComponent
      ),
  },
  {
    path: Routes.evaluation.fullPath,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuard],
    loadComponent: () =>
      import('./pages/evaluation/evaluation.component').then(
        (c) => c.EvaluationComponent
      ),
  },
  {
    path: Routes.classroom.fullPath,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuard],
    loadComponent: () =>
      import('./pages/classroom/classroom.component').then(
        (c) => c.ClassroomComponent
      ),
  },
  {
    path: Routes.library.fullPath,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuard],
    loadComponent: () =>
      import('./pages/library/library.component').then(
        (c) => c.LibraryComponent
      ),
  },
  {
    path: Routes.libraryFolder.fullPath,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuard],
    loadComponent: () =>
      import('./pages/library/library.component').then(
        (c) => c.LibraryComponent
      ),
  },
  {
    path: Routes.feed.fullPath,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuard],
    loadComponent: () =>
      import('./pages/feed/feed.component').then((c) => c.FeedComponent),
  },
  {
    path: Routes.dashboard.fullPath,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuard],
    loadComponent: () =>
      import('./pages/dashboard/dashboard.base.component').then(
        (c) => c.DashboardBaseComponent
      ),
  },
  {
    path: Routes.socialNetworkGroups.fullPath,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuard],
    loadComponent: () =>
      import('./pages/social/social-group/social-group.component').then(
        (c) => c.SocialGroupComponent
      ),
  },
  {
    path: Routes.participantsCommunity.fullPath,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuard],
    loadComponent: () =>
      import(
        './pages/social/social-participants/social-participants.component'
      ).then((c) => c.SocialParticipantsComponent),
  },
  {
    path: Routes.participantsProfile.fullPath,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuard],
    loadComponent: () =>
      import('./pages/social/social-profile/social-profile.component').then(
        (c) => c.SocialProfileComponent
      ),
  },
  {
    path: Routes.contact.fullPath,
    loadComponent: () =>
      import('./pages/contact/contact.component').then(
        (c) => c.ContactComponent
      ),
  },
  {
    path: Routes.about.fullPath,
    loadComponent: () =>
      import('./pages/about/about.base.component').then(
        (c) => c.AboutBaseComponent
      ),
  },
  {
    path: Routes.actions.fullPath,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuard],
    loadComponent: () =>
      import('./pages/actions/actions.base.component').then(
        (c) => c.ActionsBaseComponent
      ),
  },
  {
    path: Routes.actionsAnswer.fullPath,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuard],
    loadComponent: () =>
      import('./pages/actions-answer/actions-answer.component').then(
        (c) => c.ActionsAnswerComponent
      ),
  },
  {
    path: Routes.myCertificates.fullPath,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuard],
    loadComponent: () =>
      import('./pages/my-certificates/my-certificates.base.component').then(
        (c) => c.MyCertificatesBaseComponent
      ),
  },
  {
    path: Routes.mySavedItems.fullPath,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuard],
    loadComponent: () =>
      import('./pages/my-saved-items/my-saved-items.base.component').then(
        (c) => c.MySavedItemsBaseComponent
      ),
  },
  {
    path: Routes.programs.fullPath,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuard],
    loadComponent: () =>
      import('./pages/programs/programs.component').then(
        (c) => c.ProgramsComponent
      ),
  },
  {
    path: Routes.program.fullPath,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuard],
    loadComponent: () =>
      import('./pages/program/program.component').then(
        (c) => c.ProgramComponent
      ),
  },
  {
    path: Routes.notFound.fullPath,
    loadComponent: () =>
      import('./pages/not-found/not-found.component').then(
        (c) => c.NotFoundComponent
      ),
  },
  {
    path: '',
    redirectTo: Routes.home.fullPath,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: Routes.notFound.fullPath,
    pathMatch: 'full',
  },
];
