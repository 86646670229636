<!-- publication mode -->
<div *ngIf="!isCommentMode" class="publication-create-body">
  <div class="publication-create-body__body">
    <mec-social-textarea
      class="publication-create-body__textarea"
      [socialNetworkId]="socialNetWorkId"
      (socialPublicationBodyChange)="publicationBodyChange($event)"
    ></mec-social-textarea>
    <mec-file-upload-list
      [filesEvent]="filesEvent"
      (removeFileChange)="removeFile($event)"
      (confirmFileChange)="confirmFile($event)"
    ></mec-file-upload-list>
    <ng-container *ngIf="socialThread?.states?.length">
      <mec-social-content-card
        (removeContentChange)="removeContent()"
        [isEditable]="true"
        [searchItem]="socialThread?.states[0].content"
      ></mec-social-content-card>
    </ng-container>
  </div>

  <mec-social-repost-body
    *ngIf="socialThreadToRepost"
    [socialThreadToRepost]="socialThreadToRepost"
  ></mec-social-repost-body>

  <!-- web -->
  <div *ngIf="!isMobile" class="publication-create-body__actions">
    <button
      [disabled]="
        socialThread?.states?.length || socialThread.attachments?.length
      "
      (click)="openAppContentModal(socialNetWorkId)"
      class="publication-create-body__actions-btn btn btn--sm btn--no-bg btn--icon-text"
    >
      <fa-icon
        class="publication-create-body__actions-icon"
        [icon]="linkIcon"
      ></fa-icon>
      <span class="publication-create-body__actions-label">{{
        'APP_STATE' | translate
      }}</span>
    </button>

    <button
      [disabled]="socialThread?.states?.length"
      (click)="inputFileImageOrVideo.click()"
      class="publication-create-body__actions-btn btn btn--sm btn--no-bg btn--icon-text"
    >
      <input
        type="file"
        [accept]="imageOrVideoAcceptTypes"
        multiple
        hidden
        #inputFileImageOrVideo
        id="inputFileImageOrVideo"
        class="upload-modal__input-file"
        (change)="loadFileFromDevice($event)"
      />
      <fa-icon
        class="publication-create-body__actions-icon"
        [icon]="imagesIcon"
      ></fa-icon>
      <span class="publication-create-body__actions-label">{{
        'IMAGE_OR_VIDEO' | translate
      }}</span>
    </button>

    <button
      [disabled]="socialThread?.states?.length"
      (click)="inputFileDocument2.click()"
      class="publication-create-body__actions-btn btn btn--sm btn--no-bg btn--icon-text"
    >
      <input
        type="file"
        [accept]="documentAcceptTypes"
        multiple
        hidden
        #inputFileDocument2
        id="inputFileDocument"
        class="upload-modal__input-file"
        (change)="loadFileFromDevice($event)"
      />
      <fa-icon
        class="publication-create-body__actions-icon"
        [icon]="fileIcon"
      ></fa-icon>
      <span class="publication-create-body__actions-label">{{
        'DOCUMENT' | translate
      }}</span>
    </button>
  </div>

  <!-- mobile -->
  <div *ngIf="isMobile" class="publication-create-body__actions">
    <button
      [disabled]="
        socialThread?.states?.length || socialThread.attachments?.length
      "
      (click)="openAppContentModal(socialNetWorkId)"
      class="publication-create-body__actions-btn btn btn--sm btn--no-bg btn--icon-only"
    >
      <fa-icon
        class="publication-create-body__actions-icon"
        [icon]="linkIcon"
      ></fa-icon>
    </button>

    <button
      [disabled]="socialThread?.states?.length"
      (click)="inputFileImageOrVideo.click()"
      class="publication-create-body__actions-btn btn btn--sm btn--no-bg btn--icon-only"
    >
      <input
        type="file"
        [accept]="imageOrVideoAcceptTypes"
        multiple
        hidden
        #inputFileImageOrVideo
        id="inputFileImageOrVideo"
        class="upload-modal__input-file"
        (change)="loadFileFromDevice($event)"
      />
      <fa-icon
        class="publication-create-body__actions-icon"
        [icon]="imagesIcon"
      ></fa-icon>
    </button>

    <button
      [disabled]="socialThread?.states?.length"
      (click)="inputFileDocument2.click()"
      class="publication-create-body__actions-btn btn btn--sm btn--no-bg btn--icon-only"
    >
      <input
        type="file"
        [accept]="documentAcceptTypes"
        multiple
        hidden
        #inputFileDocument2
        id="inputFileDocument"
        class="upload-modal__input-file"
        (change)="loadFileFromDevice($event)"
      />
      <fa-icon
        class="publication-create-body__actions-icon"
        [icon]="fileIcon"
      ></fa-icon>
    </button>
  </div>
</div>

<!-- comment mode -->
<div
  *ngIf="isCommentMode"
  class="publication-create-body--comment-mode publication-create-body"
>
  <div class="publication-create-body__body">
    <mec-social-textarea
      class="publication-create-body__textarea"
      [socialNetworkId]="socialNetWorkId"
      (socialPublicationBodyChange)="publicationBodyChange($event)"
    ></mec-social-textarea>
    <mec-file-upload-list
      [filesEvent]="filesEvent"
      (removeFileChange)="removeFile($event)"
      (confirmFileChange)="confirmFile($event)"
    ></mec-file-upload-list>
    <ng-container *ngIf="socialThread?.states?.length">
      <mec-social-content-card
        (removeContentChange)="removeContent()"
        [isEditable]="true"
        [searchItem]="socialThread?.states[0].content"
      ></mec-social-content-card>
    </ng-container>

    <div class="publication-create-body__actions">
      <button
        [disabled]="
          socialThread?.states?.length || socialThread.attachments?.length
        "
        (click)="openAppContentModal(socialNetWorkId)"
        class="publication-create-body__actions-btn btn btn--sm btn--no-bg btn--icon-only"
      >
        <fa-icon
          class="publication-create-body__actions-icon"
          [icon]="linkIcon"
        ></fa-icon>
      </button>

      <button
        [disabled]="socialThread?.states?.length"
        (click)="inputFileImageOrVideo.click()"
        class="publication-create-body__actions-btn btn btn--sm btn--no-bg btn--icon-only"
      >
        <input
          type="file"
          [accept]="imageOrVideoAcceptTypes"
          multiple
          hidden
          #inputFileImageOrVideo
          id="inputFileImageOrVideo"
          class="upload-modal__input-file"
          (change)="loadFileFromDevice($event)"
        />
        <fa-icon
          class="publication-create-body__actions-icon"
          [icon]="imagesIcon"
        ></fa-icon>
      </button>

      <button
        [disabled]="socialThread?.states?.length"
        (click)="inputFileDocument2.click()"
        class="publication-create-body__actions-btn btn btn--sm btn--no-bg btn--icon-only"
      >
        <input
          type="file"
          [accept]="documentAcceptTypes"
          multiple
          hidden
          #inputFileDocument2
          id="inputFileDocument"
          class="upload-modal__input-file"
          (change)="loadFileFromDevice($event)"
        />
        <fa-icon
          class="publication-create-body__actions-icon"
          [icon]="fileIcon"
        ></fa-icon>
      </button>
    </div>
  </div>
</div>
