import { TagContent } from './tag-content';

export class Tag {
  public name: string;
  public tagType?: string;
  public id?: number;
  public slug?: string;
  public icon?: string;
  public relatedTags?: Tag[];
  public backgroundColor?: string;
  public fontColor?: string;
  public content?: TagContent;
  public subTitle?: string;
  public aboutTitle?: string;
  public isSingleMenu?: boolean;
  public order?: number;
  public showTags?: boolean;
  public hasCertificate?: boolean;
  public hasLanguageFilter?: boolean;
  public keyTranslation?: string;
}
