<ng-container *ngIf="examInstance">
  <form>
    <div
      class="evaluation-score"
      *ngFor="let evaluation of evaluations; let i = index"
    >
      <div class="evaluation-score__score-container">
        <div class="evaluation-score__title-container">
          <h2>{{ evaluation.title }}</h2>
          <p>{{ evaluation.description }}</p>
        </div>
        <div class="evaluation-score__score card">
          <span class="evaluation-score__score-title">
            {{ 'SCORE' | translate }}
          </span>
          <div [formGroup]="evaluation.form" class="field">
            <input
              type="number"
              [readonly]="evaluation.status === 'FINISHED'"
              (click)="clearInput(evaluation.form, 'score')"
              (input)="saveScore(evaluation)"
              formControlName="score"
              class="evaluation-score__score-input field__input"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="evaluation-score__feedback-container" *ngIf="!hideFeedback">
      <div class="field">
        <textarea
          name="feedback"
          [readonly]="examInstance.status === 'FINISHED'"
          [(ngModel)]="feedback"
          [placeholder]="'EVALUATION_FEEDBACK' | translate"
          class="evaluation-score__feedback-input field__input"
        ></textarea>
      </div>
      <button
        [disabled]="formArray.invalid"
        *ngIf="examInstance.status !== 'FINISHED'"
        (click)="finishEvaluation()"
        class="btn btn--sm"
      >
        {{ 'EVALUATION_FINISH' | translate }}
      </button>
    </div>
  </form>
</ng-container>
